import React from "react";
import { useState, useEffect, useSearchParams } from "react";
import PDFNewViewer from "./pdfNew.component";

const PdfNewFrame = () =>{
    const query = new URLSearchParams(window.location.search);
    const fileName = query.get('fileName')
    console.log(fileName)
    return(
        <div>
            <PDFNewViewer file={fileName} />
        </div>
    )
}
export default PdfNewFrame;