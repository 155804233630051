import React, { Component, useState, useEffect , useRef} from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {QrScanner} from '@yudiel/react-qr-scanner';
import AuthService from "../../services/auth.service";
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@mui/material/Slide';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '@mui/icons-material/Camera';
import { IconButton } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const steps = [
    'Select a start date',
    'Complete the van details',
    'Complete the driver details'
  ];
const modelOptions = [];

const classOptions = [
    {value: 'SWB', label: 'SWB'},
    {value: 'MWB', label: 'MWB'},
    {value: 'MWB-A', label: 'MWB-A'},
    {value: 'LWB', label: 'LWB'},
    {value: 'LWB-A', label: 'LWB-A'},
    {value: 'XLWB', label: 'XLWB'}
];

const supplierOptions = [];

const insuranceOptions = [
    {value: 'Business Compare Direct (25+)', label: 'Business Compare Direct (25+)'},
    {value: 'Gray Fleet', label: 'Gray Fleet'},
    {value: 'Insure Fleet', label: 'Insure Fleet'},
];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}
const marks = [
    {value: 0, label: '0'},
    {value: 25, label: '25%'},
    {value: 50, label:'50%'},
    {value: 75, label:'75%'},
    {value: 100, label:'100%'}
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        textAlign: 'center',
      },
      imgBox: {
        maxWidth: "80%",
        maxHeight: "80%",
        margin: "10px"
      },
      img: {
        height: "inherit",
        maxWidth: "inherit",
      },
      input: {
        display: "none"
      }
    }))

const driverOptions = [];
export default class NewHireForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          steps: 0,
          open: false,
          checkSubmit: '',
          startDate: "",
          endDate:"",
          vin: "",
          vehicleData: "",
          selectedOption: "",
          selectedOptionTwo:"",
          mileage: "",
          driversData: "",
          driverOption: "",
          phoneNo:"",
          email: "",
          address1: "",
          address2: "",
          dsp:"",
          county:"",
          postCode:"",
          town:"",
          accountNo:"",
          name:"",
          dlNo:"",
          dlEx:"",
          dlIs:"",
          dob:"",
          driverEntry: "",
          currentUser: "",
          notes: "",
          email:"",
          fuel: "",
          clean: [],
          rate: "",
          deposit: "",
          sigCanvas: React.createRef({}),
          sigCanvas1: React.createRef({}),
          sigCanvas2: React.createRef({}),
          open1: false,
          source: [],
          display: [],
          indexState: 0,
          files: [],
          fileCount: 0,
          qr: '',
          warning: [],
          warningData: [],
          clientDrop: "",
          renew: ""
        };
        
      }
    componentDidMount() {
        
      UserService.getDrivers()
      .then(response => {
         // this.setState({checkSubmit: true})
          this.setState({driversData: response.data}) 
      }).catch(err => {
          //this.setState({checkSubmit: false})
          console.log(err)
      })
      UserService.getClientDrop()
      .then(response => {
         // this.setState({checkSubmit: true})
          this.setState({clientDrop: response.data}) 
          let names = response.data;
          let namesLen = names.length;
          for(let i = 0; i < namesLen; i++){
            driverOptions.push(
              {value: names[i].name, label: names[i].name}
            )
          }
      }).catch(err => {
          //this.setState({checkSubmit: false})
          console.log(err)
      })
      UserService.getWarning()
      .then(response =>{
        console.log(response.data)
        const len = response.data.length
        for(let i = 0; i < len; i++){
            console.log(response.data[i].heading)
             this.state.warning.push(<FormControlLabel
                label={response.data[i].heading}
                control={<Checkbox onChange={e => handleWarning(i, e)} className="check"/>}
            />)
        }
        for(let i = 0; i < this.state.warning.length; i++ ){
            this.state.warningData.push('')
        }
        const handleWarning = (num, e) =>{
            console.log(e.target.checked)
            if(e.target.checked == true){
                this.state.warningData[num] = response.data[num].heading
            }else{
                this.state.warningData[num] = ''
            }
            console.log(this.state.warningData)
        }
      })
      
    }

    
    render() {
        

        const startDateChange = (e) =>{
          this.setState({startDate: e})
          this.setState({endDate: new Date(e.getTime()+ 365*86400000)})

        }
        const handleNext = () =>{
          this.setState({steps: this.state.steps +1})
        }
        const handlePrevious = () =>{
          this.setState({steps: this.state.steps-1})
        }
        const handleVin = (e) =>{
          let vin = e.target.value;
          if(this.state.qr){
            this.setState({vin: this.state.qr})
          }else{
            this.setState({vin: e.target.value.toUpperCase()})
          }
            if(vin.length == 17){
              let dataSend = {vin: vin}
              UserService.getVehicle(dataSend)
              .then(response => {
                 // this.setState({checkSubmit: true})
                  this.setState({vehicleData: response.data}) 
              }).catch(err => {
                  //this.setState({checkSubmit: false})
                  console.log(err)
              })
            }
        }
        const user = AuthService.getCurrentUser();
        const fleetUser = user.username;
        const handleOptionChange = (e) =>{
          this.setState({selectedOption: e.target.value})
  
        }
        const handleOptionChangeTwo = (e) =>{
          this.setState({selectedOptionTwo: e.target.value})

        }
        const handleMileage = (e) =>{
          this.setState({mileage: e.target.value})
        }
     
        const handleNewDriver = (e) =>{
          let len = this.state.driversData.length
          let data = this.state.driversData
          for(let i = 0; i < len; i++){
              if(e.value == data[i]?.name){
                this.setState(
                    { 
                      phoneNo: data[i].phone_no,
                      address1: data[i].address1,
                      address2: data[i].address2,
                      email: data[i].email,
                      dsp: data[i].assigned_location,
                      county: data[i].county,
                      town: data[i].town,
                      postCode: data[i].post_code,
                      accountNo: data[i].account_no,
                      name: data[i].name,
                      dlNo: data[i].dl_no,
                      dlIs: data[i].dl_issued,
                      dlEx: data[i].dl_expiry,
                      dob: data[i].dob,
                      entry: data[i].entry
                    }
                  )
              }
          }
          UserService.getEmails()
              .then(response => {
                    let data = response.data;
                    let len = data.length;
                    for(let i = 0; i < len; i++){
                        if(data[i].name == e.value){
                            this.setState({email: data[i].email})
                        }
                    }
              }).catch(err => {
                  //this.setState({checkSubmit: false})
                  console.log(err)
              })
          
        }
        
        const handleNotes = (e) =>{
            console.log(e.target.value)
        }
        const handleSubmit = () =>{
            const formData = new FormData();
            let dataSend = {
            vehicleEntry: this.state.vehicleData[0].entry, 
            status: 'Active',  
            reg:this.state.vehicleData[0].reg, 
            make: this.state.vehicleData[0].make, 
            model: this.state.vehicleData[0].model, 
            class: this.state.vehicleData[0].class, 
            colour: this.state.vehicleData[0].colour, 
            year: this.state.vehicleData[0].year, 
            vin: this.state.vehicleData[0].vin,
            rate: this.state.vehicleData[0].rate, 
            deposit: this.state.vehicleData[0].deposit, 
            assignment: 'On-Hire', 
            assigned_client: 'Skilled Solutions LTD', 
            assigned_to: this.state.name, 
            assigned_location: this.state.dsp, 
            assigned_by: user.username, 
            assigned_date: this.state.startDate, 
            end_date: this.state.endDate,
            notes: this.state.notes, 
            email: this.state.email
            }
            let unixToday = new Date().getTime();
            let dataActivities = {reg: this.state.vehicleData[0].reg, 
                heading: 'On Hire', 
                assigned_to: this.state.name, 
                assigned_date: this.state.startDate, 
                status: 'Active',
                file: this.state.vehicleData[0].reg+'_'+unixToday+'.pdf' ,
                fuel: this.state.fuel,
                warning_lights: this.state.warningData,
                mileage: this.state.mileage,
                clean: this.state.clean
            }

        UserService.insertAllocationLog(dataSend)
              .then(response => {
        
              }).catch(err => {
                  console.log(err)
              })
        let dataCheck = {assigned_to: this.state.name, assignment: 'Available'}      
        UserService.updateAssetsCheck(dataCheck).then(respones=> console.log(respones)).catch(err => console.log(err))      
           let dataSendAssets = {vehicleEntry: this.state.vehicleData[0].entry,status: 'Active', assignment:'On Rent', assigned_to: this.state.name, assigned_location: this.state.dsp, assigned_date: this.state.startDate, assigned_by: user.username} 
            
        UserService.updateAssets(dataSendAssets)
              .then(response => {
                 // this.setState({checkSubmit: true})
                 // this.setState({vehicleData: response.data}) 
        
              }).catch(err => {
                  //this.setState({checkSubmit: false})
                  console.log(err)
              })
              UserService.postActivityNew(dataActivities).then(response => {
                //setSubmit(true)
                }).catch(err => console.log(err))
                    formData.append('unixToday', unixToday)
                   formData.append('name', this.state.name) 
                   formData.append('email', this.state.email) 
                   formData.append( 'ref', new Date().getTime()) 
                   formData.append('startDate', this.state.startDate)
                   formData.append('endDate', this.state.endDate)
                   formData.append('accountNo', this.state.accountNo)
                   formData.append('address', this.state.address1+ ', '+this.state.address2)
                   formData.append('town', this.state.town)
                    formData.append('county', this.state.county)
                    formData.append('postCode', this.state.postCode)
                    formData.append('phoneNo', this.state.phoneNo)
                    formData.append('dlNo', this.state.dlNo)
                    formData.append('dlEx', this.state.dlEx)
                    formData.append('dlIs', this.state.dlIs)
                    formData.append('reg',this.state.vehicleData[0].reg) 
                    formData.append('make', this.state.vehicleData[0].make) 
                    formData.append('model', this.state.vehicleData[0].model) 
                    formData.append('class', this.state.vehicleData[0].class) 
                    formData.append('vin', this.state.vin)
                    formData.append('mileage', this.state.mileage)
                    formData.append('rate', this.state.vehicleData[0].rate) 
                    formData.append('deposit', this.state.vehicleData[0].deposit) 
                    formData.append('fuel', this.state.fuel)
                    formData.append('clean', this.state.clean)
                    formData.append('userSig', this.state.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
                    formData.append('clientSig1', this.state.sigCanvas1.current.getTrimmedCanvas().toDataURL("image/png"))
                    formData.append('clientSig2', this.state.sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png"))
                    formData.append('user', fleetUser)
                    formData.append('fileCount', this.state.fileCount)
                    formData.append('warningLights', this.state.warningData)
                    formData.append('renew', this.state.renew)
                //formData.append('dataPdf', dataPdf)
                for(let i = 0; i < this.state.files.length; i++){
                    formData.append('file'+i, this.state.files[i])
                }
                console.log(formData)
                UserService.postOnHire(formData).then(result => {
                    this.setState({checkSubmit: true})
                }).catch(err => {
                    this.setState({checkSubmit: false})
                    console.log(err)
                })
        }

        const handleClean = (ind) =>{
            if(this.state.clean[ind]== true){
                this.state.clean[ind] = false
            }else{
                this.state.clean[ind] = true
            }
        }
        const handleClickOpen = () =>{
            this.setState({open: true})
        }
        const handleClickClose = () =>{
            this.setState({open: false})
        }
        const clear1 = () => this.state.sigCanvas.current.clear();
        const clear2 = () => this.state.sigCanvas1.current.clear();
        const clear3 = () => this.state.sigCanvas2.current.clear();
        const handleClose = () => {
            this.setState({open1: false});
            //console.log(damageType)
            //console.log(files)
        }
        let index = 0;
        const handleOpen = () => {
            this.setState({source: []})
            this.setState({open1: true});
            this.setState({display: []})
            this.setState({indexState: 0})
            this.setState({fileCount: 0})
            //setDamageType([])
            //setFiles([])
            //setDamageDimentions([])
        };
        const handleBox = (index) =>{
            console.log(index)
            /*setDisplay(oldDisplay => {
                return oldDisplay.filter(element => element !== oldDisplay[index])
            })*/
            //delete damageType[index]
           
                let damageLet = [] 
                this.state.display.map((element, key) =>{
                    console.log(index)
                    if(index !== key){
                        damageLet.push(element)
                    }else{
                        damageLet.push('')
                    }
                })
                this.setState({display: damageLet})
            }
        
        
        const handleCapture = (target) => {
            //setFiles(oldFiles =>[...oldFiles, target.files[0]])
            this.setState({files: [...this.state.files, target.files[0]]})   
            index = this.state.indexState
            if (target.files) {
                if (target.files.length !== 0) {
                    const file = target.files[0];
                    console.log(file)
                    const newUrl = URL.createObjectURL(file);
                    console.log(newUrl)
                    //setSource(oldSource =>[...oldSource, newUrl]);
                    this.state.source.push(newUrl)
                        let element = <div className="flex"  key={index}>
                                            <div className="pictureFrame">
                                            <Box display="flex" justifyContent="center" border={1} marginLeft={300} >
                                                <img src={newUrl} alt={"snap"} className="picture"></img>
                                            </Box>
                                        </div>
                                            {/*<IconButton
                                                className="buttonDelete"
                                                onClick={e => handleBox(index)} ><DeleteForeverIcon  sx={{fontSize: 80, color: 'rgb(214, 109, 109)'}}/>
                                        </IconButton>*/}
                                        </div>
                        const data =  [...this.state.display, element]                        
                        this.setState({display: data})
                        this.setState({indexState: this.state.indexState+1})
                        this.setState({fileCount: this.state.fileCount+1})                    
                }
            }
           
        };

       
        return(
            <>
            {this.state.checkSubmit ? <h1 className="assetsOutcome">New hire done!!</h1> : this.state.checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Hew Hire</h1>
                    </header> 
                    <Box sx={{ width: '100%', fontSize: 'x-large' }} className="correctionFieldsMobile">
                        <Stepper activeStep={this.state.steps} alternativeLabel>
                            {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Box>   
                    <div>
                        <> 
                        {this.state.steps == 0?    
                          <h2 className="subttV">New Hire Date</h2>
                          : this.state.steps == 1 ?
                          <h2 className="subttV">Vehicle Details</h2>
                          : <h2 className="subttV">Driver Details</h2>
                        }
                        </>
                        <>
                        {this.state.steps == 0?
                        <div className="flex">
                        <p className="req">*The end date will be sellected automatically 90 days after the start date.</p>   
                            <div className="calendar inlineLnI">
                              <label for='calen' className="eCorrection">Start Date <span className="req">(Require)</span></label>
                              <Calendar  
                              onChange={e => startDateChange(e)} 
                              //value={new Date()} 
                              validRange = {{
                                  start: new Date(),
                                  end: null
                              }}
                              />
                            </div>
                            <div className="calendar inlineLnI">
                              <label for='calen' className="eCorrection">End Date <span className="req">(Require)</span></label>
                              <Calendar  
                              //onChange={e => dateChange(e)} 
                              value={this.state.endDate} 
                              validRange = {{
                                  start: new Date(),
                                  end: null
                              }}
                              />
                            </div>
                        </div>: this.state.steps == 1 ?
                        <>
                        <div className="scanner">
                            <QrScanner
                                onDecode={(result) => this.setState({qr: result})}
                                onError={(error) => console.log(error?.message)}
                            />
                        </div>
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Vin <span className="req">(Require)</span></label>
                              <input type="text" name='notes' className="twoIn2 correctionFieldsMobile" onChange={e => handleVin(e)} defaultValue={this.state.qr} />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Reg <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile3" value={this.state.vehicleData[0]?.reg} /*onChange={e => handleRate(e)}*/  />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Make <span className="req">(Require)</span></label>
                                <input type="text" name='deposit' className="twoIn2 correctionFieldsMobile3" value={this.state.vehicleData[0]?.make} /*onChange={e => handleDeposit(e)}*/  />
                            </div>
                        </div>
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Model <span className="req">(Require)</span></label>
                              <input type="text" name='notes' className="twoIn2 correctionFieldsMobile" /*onChange={e => handleVin(e)}*/ defaultValue={this.state.vehicleData[0]?.model} />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Rate <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile3" value={this.state.vehicleData[0]?.rate} /*onChange={e => handleRate(e)}*/  />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Deposit <span className="req">(Require)</span></label>
                                <input type="text" name='deposit' className="twoIn2 correctionFieldsMobile3" value={this.state.vehicleData[0]?.deposit} /*onChange={e => handleDeposit(e)}*/  />
                            </div>
                        </div> 
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Current Mileage <span className="req">(Require)</span></label>
                              <input type="number" name='notes' className="twoIn2 correctionFieldsMobile" onChange={e => handleMileage(e)}  />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI correctionFieldsMobile">
                                <label for='reg' className="eCorrection">Fuel Level <span className="req">(Require)</span></label>
                                <Slider
                                aria-label="Custom marks"
                                defaultValue={0}
                                //getAriaValueText={valuetext}
                                step={1}
                                //valueLabelDisplay={valuetext}
                                onChange={e => this.setState({fuel : e.target.value})}
                                marks={marks}
                                />
                            </div>
                            <div className="inlineLnI correctionFieldsMobile">
                
                                <label for='make' className="eCorrection">Vehicle Cleanliness <span className="req">(Require)</span></label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                <FormControlLabel
                                    label="Outside not clean"
                                    control={<Checkbox checked={this.state.clean[0]} onChange={e => handleClean(0)} className="check"/>}
                                />
                                <FormControlLabel
                                    label="Inside not clean"
                                    control={<Checkbox checked={this.state.clean[1]} onChange={e => handleClean(1)} className="check"/>}
                                />
                                <FormControlLabel
                                    label="Cargo area not clean"
                                    control={<Checkbox checked={this.state.clean[2]} onChange={e => handleClean(2)} className="check"/>}
                                />
                                </Box>
                            </div>
                        </div> 
                        <div className="flex">
                            <div className="inlineLnI correctionFieldsMobile correctionX">
                                    <label for='make' className="eCorrection">Warning Lights <span className="req">(Require)</span></label>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                        {this.state.warning}
                                    </Box>
                            </div>  
                            <div className="bitMarginY">    
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group"><h3 className="eCorrection">Is this a renew?<span className="req">(Require)</span></h3></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        //value={valueRadio3}
                                        onChange={e => this.setState({renew: e.target.value})}
                                    >
                                    <div className='flexForm'>
                                        <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                            '& .MuiSvgIcon-root': {
                                                                                                fontSize: 38,
                                                                                            }}} 
                                                                                            className="check"
                                                                                                />}
                                                                                    sx={{m: 2, fontSize: 22}}            
                                                                                    label="Yes"
                                                                                />
                                        <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                            '& .MuiSvgIcon-root': {
                                                                                                fontSize: 38,
                                                                                            }}} 
                                                                                            className="check"
                                                                                            />} 
                                                                                    sx={{m: 2, fontSize: 22}}         
                                                                                    label="No" />     
                                    </div>                                                                                   
                                    </RadioGroup>
                                </FormControl>
                            </div> 
                           
                        </div>
                       
                        </>: <>
                                <div className="inlineLnx">
                                        <label for='rate' className="eCorrection">New Driver <span className="req">(Require)</span></label>
                                        <Select 
                                            options={driverOptions}
                                            styles={colourStylesS}
                                            onChange={e => handleNewDriver(e)}
                                            
                                        /> 
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='phone' className="eCorrection">Phone No <span className="req">(Require)</span></label>
                                          <input type="text" name='reg' className="twoIn2 correctionFieldsMobile" defaultValue={this.state.phoneNo} onChange={e => this.setState({phoneNo: e.target.value})}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='email' className="eCorrection">Email <span className="req">(Require)</span></label>
                                          <input type="text" name='email' className="twoIn2 correctionFieldsMobile" defaultValue={this.state.email} onChange={e => this.setState({email: e.target.value})}  />
                                      </div>
                                </div>
                                <h4 className="subttt">Address</h4>
                                <div className="inlineLnx">
                                      <label for='address1' className="eCorrection">Name/Number and Street <span className="req">(Require)</span></label>
                                      <input type="text" name='address1' className="twoIn2 correctionFieldsMobile" onChange={e => this.setState({address1: e.target.value})} defaultValue={this.state.address1} />
                                </div>
                                <div className="inlineLnx">
                                      <label for='address2' className="eCorrection">Address Line 2 </label>
                                      <input type="text" name='address2' className="twoIn2 correctionFieldsMobile" onChange={e => this.setState({address2: e.target.value})} defaultValue={this.state.address2} />
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='town' className="eCorrection">Town/City <span className="req">(Require)</span></label>
                                          <input type="text" name='town' className="twoIn2 correctionFieldsMobile3" defaultValue={this.state.town} onChange={e => this.setState({town: e.target.value})}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='county' className="eCorrection">County/State <span className="req">(Require)</span></label>
                                          <input type="text" name='county' className="twoIn2 correctionFieldsMobile3" defaultValue={this.state.county} onChange={e => this.setState({county: e.target.value})}  />
                                      </div>
                                </div>
                                <div className="inlineLnA">
                                    <label for='postCode' className="eCorrection">Zip/Postal Code <span className="req">(Require)</span></label>
                                    <input type="text" name='postcode' className="twoIn2 correctionFieldsMobile3" defaultValue={this.state.postCode} onChange={e => this.setState({postCode: e.target.value})}  />
                                </div>
                                <h4 className="subttt">Additional Information</h4>
                                <div className="inlineLnx">
                                      <label for='dlNo' className="eCorrection">Driving Licence Number <span className="req">(Require)</span></label>
                                      <input type="text" name='dlNo' className="twoIn2 correctionFieldsMobile" onChange={e => this.setState({dlNo: e.target.value})} defaultValue={this.state.dlNo} />
                                </div>
                                <div className="flexAssets">
                                <div className="inlineLnI">
                                        <label for='dlIs' className="eCorrection">Driving Licence Issuing<span className="req">(Require)</span></label>
                                        <input type="text" name='dlIs' className="twoIn2 correctionFieldsMobile3" onChange={e => this.setState({dlIs: e.target.value})} defaultValue={this.state.dlIs} />
                                    </div>
                                    <div className="inlineLnI">
                                        <label for='dlExp' className="eCorrection">Driving Licence Expiry <span className="req">(Require)</span></label>
                                        <input type="text" name='dlExp' className="twoIn2 correctionFieldsMobile3" onChange={e => this.setState({dlEx: e.target.value})} defaultValue={this.state.dlEx} />
                                    </div>
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='none' className="eCorrection">Dob<span className="req">(Require)</span></label>
                                          <input type="text" name='none' className="twoIn2 correctionFieldsMobile3" defaultValue={this.state.dob} onChange={e => this.setState({dob: e.target.value})}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='station' className="eCorrection">Station <span className="req">(Require)</span></label>
                                          <input type="text" name='station' className="twoIn2 correctionFieldsMobile3" defaultValue={this.state.dsp} onChange={e => this.setState({dsp: e.target.value})}  />
                                      </div>
                                </div>
                                <h4 className="subttt">Notes</h4>
                                <div className="inlineLnx">
                                      <textarea rows="6" name='dlNo' className="multipleLines correctionFieldsMobile" onChange={e => this.setState({notes: e.target.value})} /*value={this.state.dlNo}*/ />
                                </div>
                            </>
                        }
                        </>        
                   
                        <>
                        {this.state.steps == 2?
                          <div className="flexo">
                            <div className="submitButtonAss">
                                    <button type="button" className="submitButtonnAss" onClick={handleClickOpen}>Declarations</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                    <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                {
                                    this.state.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' ||
                                    this.state.sigCanvas1.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' || 
                                    this.state.sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' ?
                                    <button type="button" className="submitButtonnAssX">Submit</button>
                                :<button type="button" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>}
                            </div>
                          </div>
                          : this.state.steps !== 0 && this.state.steps !== 2 ? 
                          <div className="flexo ">
                            <div className="submitButtonAss">
                                  <button type="button" className="submitButtonnAss" onClick={handleOpen}>Take Pictures</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                  <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                            </div>
                            <div className="spaceing"></div>
                            
                            <div className="submitButtonAss">
                            {this.state.fileCount < 4 ? <button type="button" className="submitButtonnAssL" >next&gt;</button>: <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>}
                            </div>
                            
                          </div>: this.state.endDate !== '' && this.state.startDate ?
                          <div className="submitButtonAss">
                              <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>
                          </div>:
                            <div className="submitButtonAss">
                                <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>next&gt;</button>
                            </div>
                          }
                        </>
                    </div> 
                </div>
            </div> 
     
            } 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div /*className={classes.root}*/>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className="declarations">
                                            <h3 className="titleZ">Fleet Representative Declaration</h3>
                                            <p className="dec">I have issued the vehicle {this.state.vehicleData[0]?.reg} to the hirer and it meets the requirements as set out our terms & conditions as seen by myself and I have explained all the requirements to the hirer.</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={this.state.sigCanvas}
                                                //onChange={console.log(this.state.sigCanvas)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear1}>Clear</button>
                                            <br></br><h3 className="titleZ">Client Declarations</h3>
                                            <h4 className="subtitle">Terms of Hire</h4>
                                            <p className="dec">I accept and agree to abide to all the terms & conditions as set out by the supplier which is published on the suppliers website and that these terms maybe updated with written notice (by email).</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={this.state.sigCanvas1}
                                                //onChange={console.log(this.state.sigCanvas1)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear2}>Clear</button>
                                            
                                            <p className="dec">{fleetUser} has explained or demonstrated to me the following points of which I agree to follow as part of the vehicle rental agreement.</p>
                                            <p className="dec">1, How to use the vehicle tracking system and that I must ensure the vehicle is locked securely at all times.</p>
                                            <p className="dec">2, Where the AdBlue system and the importance of keeping Ad-Blue topped up and not ignoring any messages on the vehicle display if the vehicle uses Ad-Blue.</p>
                                            <p className="dec">3, To follow road markings and not to ignore parking restrictions, this includes Rotherhithe Tunnel which commercial vehicles are not allowed to use, any charge notices that occur as a result of my behaviour will be recharged or redirected and I am liable for any costs incurred.</p>
                                            <p className="dec">4, The vehicle is listed on an account for the London Congestion Zone and the Dartford Crossing/A282 and the associated fee’s for entering these will be recharged to myself.</p>
                                            <p className="dec">5, In the event of an accident/emergency and/or damage I am to call the supplier unless it is better in my judgement to call 999 first.</p>
                                            <p className="dec">6, The fee for renting the vehicle is £{this.state.vehicleData[0]?.rate} and the vehicle’s deposit is £{this.state.vehicleData[0]?.deposit}. The weekly rate is chargeable from the point I collect the vehicle to the point it is returned back to the supplier and in no way discounted on how many working days for other companies I supply services to.</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={this.state.sigCanvas2}
                                                //onChange={console.log(this.state.sigCanvas2)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear3}>Clear</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>                          
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className="buttonPictures" onClick={handleClickClose}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={this.state.open1}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div >
                    <Grid container>
                        <Grid item xs={12}>

                            <div className="pictures">
                                <div className="pictureFrame">   
                                    {this.state.display.length > 0 ? this.state.display : ''}
                                </div> 
                                <input
                                    accept="image/*"
                                    //className={classes.input}
                                    style={{display: 'none'}}
                                    id="icon-button-file"
                                    type="file"
                                    capture="environment"
                                    onChange={(e) => handleCapture(e.target)}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    >
                                    <CameraIcon sx={{fontSize: 80}} color="primary" />
                                    </IconButton>
                                </label>
                        
                            </div>
                        </Grid>
                    </Grid>
                    </div>                          
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className="buttonPictures" onClick={handleClose}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            </>                   
        )
    }
}