import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';
import DeleteIcon from '@mui/icons-material/Delete';
import UseAnimations from "react-useanimations";
import trash2 from 'react-useanimations/lib/trash2';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const WaitingRentalBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [ openDeleteWaiting,  setOpenDeleteWaiting ] = useState(false)
  const [ name, setName ] = useState()
  const [ id, setId ] = useState()

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 20 },
    {
      field: 'timestamp',
      headerName: 'Date/Time Requested',
      headerClassName: 'table-header',
      width: 190,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
   {
      field: 'contact_no',
      headerName: 'Contact No',
      headerClassName: 'table-header',
      width: 150,
      editable: false,
    },
    {
       field: 'station',
       headerName: 'Station',
       headerClassName: 'table-header',
       width: 130,
       editable: false,
     },
     {
        field: 'class',
        headerName: 'Class',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
        
      },
      {
        field: 'date',
        headerName: 'Reservation Date',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'comments',
        headerName: 'Comments',
        headerClassName: 'table-header',
        width: 250,
        editable: true,
        
      },
      {
        field: '',
        headerName: '',
        headerClassName: 'table-header',
        width: 80,
        editable: false,
        renderCell: (param) =>(
                <>
                    <UseAnimations animation={trash2} size={40} style={{color: '#ff8080'}} onClick={e => handleDelete(param)}/>
                </>
        )
      },
      ,
     /*{
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            console.log(params)
            UserService.updateNotes(params.value, params.row.id)
            //handleOC(params.value, params.row.id)
            return params.value
          }
      },*/
    ]
const handleDelete = (params) =>{
  setName(params.row.name)
  setId(params.row.id)
  setOpenDeleteWaiting(true)
}
const handleYes = () =>{
  console.log(id)
  let data = {id: id}
  UserService.deleteWaiting(data).then(
    response =>{
      window.location.reload(true)
    }
  ).catch(err => console.log(err))
}
const handleNo = () =>{
  setOpenDeleteWaiting(false)
}
let displaiC = [];
if(data){
data?.map(dataRow => {
        displaiC.push(
          {id: dataRow.entry, timestamp: dataRow.timestamp?.split(' ')[0]?.split('-')[2]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[1]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[0]+'  '+dataRow.timestamp?.split(' ')[1]?.split(':')[0]+':'+dataRow.timestamp?.split(' ')[1]?.split(':')[1], name: dataRow.name , contact_no: dataRow.contact_no, station: dataRow.station, class: dataRow.class, date: dataRow.reservation ? dataRow.reservation?.split('T')[0]?.split('-')[2]+'-'+dataRow.reservation?.split('T')[0]?.split('-')[1]+'-'+dataRow.reservation?.split('T')[0]?.split('-')[0] : '' || null, comments: dataRow.comments}
        ) 

    })
  }

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput3').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {

        if(dataRow.name?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.station?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.class?.toUpperCase().match(searchInput.toUpperCase()) ){
            findC.push(
              {id: dataRow.entry, timestamp: dataRow.timestamp?.split(' ')[0]?.split('-')[2]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[1]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[0]+'  '+dataRow.timestamp?.split(' ')[1]?.split(':')[0]+':'+dataRow.timestamp?.split(' ')[1]?.split(':')[1], name: dataRow.name , contact_no: dataRow.contact_no, station: dataRow.station, class: dataRow.class, date: dataRow.reservation ? dataRow.reservation?.split('T')[0]?.split('-')[2]+'-'+dataRow.reservation?.split('T')[0]?.split('-')[1]+'-'+dataRow.reservation?.split('T')[0]?.split('-')[0] : ''|| null, comments: dataRow.comments}
            )
        }
        
});

}
let dataH = {};
const handleCellEdit = (params) =>{
  console.log(params);
  dataH = {entry: params.id, CO: params.date || null, comments: params.comments || null}
  console.log(dataH)
    UserService.updateWaiting(dataH)
    setTimeout(()=>{
      window.location.reload(true)
    }, 200)
   return params;
}

return( 
<div>
    
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput3'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
       <h3 className='availableOffTitle2'>Rental Waiting</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>: <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>}
    <React.Fragment >
        <Dialog
            //fullScreen
            open={openDeleteWaiting}
            TransitionComponent={Transition}
            keepMounted
            //onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            style={{width: '100%'}}
        >
            <DialogTitle >
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete {name} from the waiting list?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes} style={{color: 'red'}}>Yes</Button>
                <Button onClick={handleNo}>No</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
</div>
)

};

export default WaitingRentalBar;