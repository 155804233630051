import React, { Component } from "react";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import PcnBar from "./pcnDataList.component";
export default class PcnDataFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPcnData().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        console.log(this.state.content)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
  
    return (
      <div className="containerf">
    
        <PcnBar table_data={this.state.content}/>
        
      </div>
    );
  }
}
