import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';
import PDFViewer from './pdfView.component';




//blob test



//blbtest end


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const PcnBar = (props) => {





 const [searchInput, setSearchInput] = useState("");
 let url_f = '/allocation';
let data_f = 'url_f)';
 let url_D = '/DVLA';
 let data_D = 'url_D)';
 let url_R = '/RTW';
 let data_R = 'url_R)';
 let url = '/applicants';
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;
 
 useEffect(()=>{
    if(!data_f || !data_D || !data_R || !data){
        setLoading(true)
    }else{
        setLoading(false)
    }
 },[data_f, data_D, data_R, data])

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 50 },
    {
      field: 'timeStamp',
      headerName: 'Processed',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
    },
    {
      field: 'pcn_no',
      headerName: 'PCN Number',
      headerClassName: 'table-header',
      width: 180,
      editable: false,
    },
   {
      field: 'vehicle_reg',
      headerName: 'Vehicle Reg',
      headerClassName: 'table-header',
      width: 150,
      editable: false,
    },
    {
       field: 'driver',
       headerName: 'Driver',
       headerClassName: 'table-header',
       width: 200,
       editable: false,
       
      
     },
     {
        field: 'pcn_type',
        headerName: 'Notice',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
        
      },
      {
        field: 'pcn_date',
        headerName: 'Date of Contravention',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
        
      },
      {
        field: 'pcn_code',
        headerName: 'PCN Code',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
        
      },
      {
        field: 'pcn_description',
        headerName: 'PCN Description',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
        
      },
      {
        field: 'cost',
        headerName: 'Cost',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
        
      },
      {
        field: 'issued_by',
        headerName: 'Issued By',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
        
      },
      {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'file',
        headerName: 'Document',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        renderCell: (param) =>(
           <button>Open Document</button>
               
          
        ),
      }
  ];
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
   
    setOpen(false);
  };
 
 const handleClick =(params)=>{
    console.log(params)
    if(params.field == 'file'){
        handleClickOpen()
        setFile(params.row.file)
    }    

    
 }

let displaiC = [];
if(data){
data?.map(dataRow => {
  
        displaiC.push(
            {id: dataRow.Line, timeStamp: 'Date: '+dataRow.TimeStamp.split('T')[0].split('-')[2]+'-'+dataRow.TimeStamp.split('T')[0].split('-')[1]+'-'+dataRow.TimeStamp.split('T')[0].split('-')[0]+' Time:'+dataRow.TimeStamp.split('T')[1].split(':')[0]+':'+dataRow.TimeStamp.split('T')[1].split(':')[1] ,pcn_no: dataRow.PCN_No, vehicle_reg: dataRow.Vehicle_Reg , driver: dataRow.Driver, pcn_type: dataRow.PCN_Type, pcn_date: dataRow.PCN_Date.split('T')[0].split('-')[2]+'-'+dataRow.PCN_Date.split('T')[0].split('-')[1]+'-'+dataRow.PCN_Date.split('T')[0].split('-')[0], pcn_code: dataRow.PCN_Code, pcn_description: dataRow.PCN_Description, cost: dataRow.Cost, issued_by: dataRow.Issued_By, status: dataRow.Status, file: dataRow.File}
            ) 
        })  
}

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {
        let reg = '';
        /*data_f?.map(row => {
            if(dataRow.Name == row.Customer_Name){
                reg = row.Reg
            }
        })*/
                if(dataRow.PCN_no?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Vehicle_Reg?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Driver?.toUpperCase().match(searchInput.toUpperCase())){
                
                    
                    findC.push(
                        {id: dataRow.Line, timeStamp: 'Date: '+dataRow.TimeStamp.split('T')[0].split('-')[2]+'-'+dataRow.TimeStamp.split('T')[0].split('-')[1]+'-'+dataRow.TimeStamp.split('T')[0].split('-')[0]+' Time:'+dataRow.TimeStamp.split('T')[1].split(':')[0]+':'+dataRow.TimeStamp.split('T')[1].split(':')[1] ,pcn_no: dataRow.PCN_No, vehicle_reg: dataRow.Vehicle_Reg , driver: dataRow.Driver, pcn_type: dataRow.PCN_Type, pcn_date: dataRow.PCN_Date.split('T')[0].split('-')[2]+'-'+dataRow.PCN_Date.split('T')[0].split('-')[1]+'-'+dataRow.PCN_Date.split('T')[0].split('-')[0], pcn_code: dataRow.PCN_Code, pcn_description: dataRow.PCN_Description, cost: dataRow.Cost, issued_by: dataRow.Issued_By, status: dataRow.Status, file: dataRow.File}
                        )
                }
            
        
});

}

const handleDelete = () =>{
  console.log(selected)
  UserService.deleteCodes(selected)
  setTimeout(()=>{
    window.location.reload(true)
  }, 500)
}
let dataH = {};
const handleCellEdit = (params) =>{
    console.log(params);
   dataH = {id: params.id,Council: params.council, Parking_Advice_Page: params.parking_advice_page, Email: params.email, URL_Appeal: params.url_appeal, 'Address#1': params.address1, 'Address#2': params.address2, 'Address#Town': params.addressTown, 'Address#County': params.addressCounty, 'Address#Postcode': params.addressPostCode, Notes: params.notes}
   console.log(dataH)
      UserService.updateIssuing(dataH)
      setTimeout(()=>{
        window.location.reload(true)
      }, 200)
     return params;
  }
  


return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button><h3 className='availableOffTitleC'>PCN Database</h3>
        {/*<button type='button' className='buttonDel' onClick={handleDelete}
        >Delete selected</button>
        <button type='button' className='buttonDel' onClick={handleClickOpen}
>+ New Entry</button>*/}
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '0%' }}>
        <DataGrid
        
       // checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        onCellClick={handleClick}
       
        />
    </Box>: <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '1%' }}>
        <DataGrid
        //checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        onCellClick={handleClick}
        />
    </Box>}
    <React.Fragment>
      
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
             <PDFViewer link={file} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
</div>
)

};

export default PcnBar;