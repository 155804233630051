import React, { Component, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const steps = [
    'Select a date',
    'Complete the van details',
    'Complete payment details',
    'Select Suplier',
    'Select insurance'
  ];
const modelOptions = [];

const classOptions = [
    {value: 'SWB', label: 'SWB'},
    {value: 'MWB', label: 'MWB'},
    {value: 'MWB-A', label: 'MWB-A'},
    {value: 'LWB', label: 'LWB'},
    {value: 'LWB-A', label: 'LWB-A'},
    {value: 'XLWB', label: 'XLWB'}
];
const contactTypeOptions = [
    {value: 'Client', label: 'Client'},
    {value: 'Supplier', label: 'Supplier'}
]
const assignedOptions = [
    {value: 'Skilled Solutions Ltd', label: 'Skilled Solutions Ltd'}
]
const statusOptions = [
    {value:'Active', label: 'Active'}
]
const supplierOptions = [];

const insuranceOptions = [
    {value: 'Business Compare Direct (25+)', label: 'Business Compare Direct (25+)'},
    {value: 'Gray Fleet', label: 'Gray Fleet'},
    {value: 'Insure Fleet', label: 'Insure Fleet'},
];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

export default class AddContact extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          checkSubmit: "",
          contactType: "",
          assignedClient: "",
          accountNumber: "",
          terms: "",
          name: "",
          manager: "",
          phone: "",
          address1: "",
          address2: "",
          town: "",
          county: "",
          postCode: "",  
          notes:  "",
          email:"",
          status: "",
          dob: "",
          nino: "",
          dlno: "",
          expiry: "",
          points:""


        };
      }
    componentDidMount() {
       
    }
    
    render() {
           
        const handleContactType = (e) =>{
            this.setState({contactType: e.value})
        }
        const handleAssignedClient = (e) =>{
            this.setState({assignedClient: e.value})
        }
        const handleAccountNumber = (e)=>{
            this.setState({accountNumber: e.target.value.toUpperCase()})
        }
        const handleTerms = (e) =>{
            this.setState({terms: e.target.value})
        }
        const handleName = (e) =>{
            this.setState({name: e.target.value})
        }
        const handleManager = (e) =>{
            this.setState({manager: e.target.value})
        }
        const handlePhone = (e) =>{
            this.setState({phone: e.target.value})
        }
        const handleEmail = (e) =>{
            this.setState({email: e.target.value})
        }
        const handleAddress1 = (e) =>{
            this.setState({address1: e.target.value})
        }
        const handleAddress2 = (e) =>{
            this.setState({address2: e.target.value})
        }
        const handleTown = (e) =>{
            this.setState({town: e.target.value})
        }
        const handleCounty = (e) =>{
            this.setState({county: e.target.value})
        }
        const handlePostCode = (e) =>{
            this.setState({postCode: e.target.value.toUpperCase()})
        }
        const handleStatus = (e) =>{
            this.setState({status: e.value})
        }
        const handleNotes = (e)=>{
            this.setState({notes: e.target.value})
        }
        const handleSDate = (e) =>{
            console.log(e)
            this.setState({dob: e})
        }
        const handleNi = (e)=>{
            this.setState({nino: e.target.value.toUpperCase()})
        }
        const handleDl = (e) =>{
            this.setState({dlno: e.target.value.toUpperCase()})
        }
        const handleDLExpiry =(e)=>{
            this.setState({expiry: e})
        }
        const handlePoints = (e) =>{
            this.setState({points: e.target.value})
        }
        let dataSend = {}
        const handleSubmit = () =>{
            dataSend = {type: this.state.contactType, assigned_client: this.state.assignedClient, account_no: this.state.accountNumber?.toUpperCase(), terms: this.state.terms, name: this.state.name, account_manager: this.state.manager || null, phone_no: this.state.phone, email: this.state.email, address1: this.state.address1, address2: this.state.address2, town: this.state.town, county: this.state.county, post_code: this.state.postCode, status: this.state.status, notes: this.state.notes, dob: this.state.dob || null, nino: this.state.nino || null, dl_no: this.state.dlno || null, dl_expiry: this.state.expiry || null, dl_points: this.state.points || null}
            console.log(dataSend);
            UserService.insertSuppliers(dataSend)
            .then(response => {
                this.setState({checkSubmit: true}) 
                console.log(response)
            }).catch(err => {
                this.setState({checkSubmit: false})
                console.log(err)
            })
            
        }

        return(
            <>
            {this.state.checkSubmit == true ? <h1 className="assetsOutcome">Contact Added!!</h1> : this.state.checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Add Contact</h1>
                    </header> 
                    
                    <div>
                        <div className="inlineLnI">
                            <label for='model' className="eCorrection">Contact Type<span className="req">(Require)</span></label>
                                    <Select 
                                        options={contactTypeOptions}
                                        styles={colourStylesS}
                                        onChange={e => handleContactType(e)}
                                        value={this.state.defaultValueClass}
                                    />
                        </div> 
                        {this.state.contactType == 'Supplier' ? <><h2 className="subttV">Supplier</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Assigned Client <span className="req">(Require)</span></label>
                                <Select 
                                    options={assignedOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleAssignedClient(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                            <div className="inlineLnI">
                                <label for='accountNumber' className="eCorrection">Account Number<span className="req">(Require)</span></label>
                                <input type="text" name='accountNumber' className="twoIn2" onChange={e => handleAccountNumber(e)} /> 
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                            <label for='terms' className="eCorrection">Terms <span className="req">(Require)</span></label>
                            <input type="text" name='terms' className="twoIn2" onChange={e => handleTerms(e)}  /> 

                            </div>
                            <div className="inlineLnI">
                                <label for='name' className="eCorrection">Name <span className="req">(Require)</span></label>
                                <input type="text" name='name' className="twoIn2" onChange={e => handleName(e)}  />
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='manager' className="eCorrection">Account Manager <span className="req">(Require)</span></label>
                                <input type="text" name='manager' className="twoIn2" onChange={e => handleManager(e)}  />
                            </div>
                            <div className="inlineLnI">
                                <label for='phone' className="eCorrection">Phone Number <span className="req">(Require)</span></label>
                                <input type="text" name='phone' className="twoIn2" onChange={e => handlePhone(e)} />
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='email' className="eCorrection correctionFieldsMobile7">Email <span className="req">(Require)</span></label>
                                <input type="text" name='email' className="twoIn2 correctionFieldsMobile7" onChange={e => handleEmail(e)}  />
                            </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='address1' className="eCorrection">Address 1 <span className="req">(Require)</span></label>
                                <input type="text" name='address1' className="twoIn2" onChange={e => handleAddress1(e)} />
                            </div>
                            <div className="inlineLnI">
                                <label for='address2' className="eCorrection">Address 2 <span className="req">(Require)</span></label>
                                <input type="text" name='address2' className="twoIn2" onChange={e => handleAddress2(e)} />
                            </div>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='town' className="eCorrection">Town <span className="req">(Require)</span></label>
                                <input type="text" name='town' className="twoIn2" onChange={e => handleTown(e)} />
                            </div>
                            <div className="inlineLnI">
                                <label for='county' className="eCorrection">County <span className="req">(Require)</span></label>
                                <input type="text" name='county' className="twoIn2" onChange={e => handleCounty(e)} />
                            </div>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='postCode' className="eCorrection">Post Code <span className="req">(Require)</span></label>
                                <input type="text" name='postCode' className="twoIn2" onChange={e => handlePostCode(e)} value={this.state.postCode}/>
                            </div>
                            <div className="inlineLnI">
                                <label for='status' className="eCorrection">Status <span className="req">(Require)</span></label>
                                <Select 
                                    options={statusOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleStatus(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='notes' className="eCorrection correctionFieldsMobile5">Notes <span className="req">(Require)</span></label>
                                <input type="text" name='notes' className="twoIn2 correctionFieldsMobile5" onChange={e => handleNotes(e)}  />
                        </div>

                        <div className="submitButtonAss">
                            <button type="button" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>
                        </div> </>: 
                        this.state.contactType == 'Client' ?

                        <><h2 className="subttV">Client</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Assigned Client <span className="req">(Require)</span></label>
                                <Select 
                                    options={assignedOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleAssignedClient(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                            <div className="inlineLnI">
                                <label for='accountNumber' className="eCorrection">Account Number<span className="req">(Require)</span></label>
                                <input type="text" name='accountNumber' className="twoIn2" onChange={e => handleAccountNumber(e)} /> 
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                            <label for='terms' className="eCorrection">Terms <span className="req">(Require)</span></label>
                            <input type="text" name='terms' className="twoIn2" onChange={e => handleTerms(e)}  /> 

                            </div>
                            <div className="inlineLnI">
                                <label for='name' className="eCorrection">Name <span className="req">(Require)</span></label>
                                <input type="text" name='name' className="twoIn2" onChange={e => handleName(e)}  />
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='manager' className="eCorrection">Date of Birth <span className="req">(Require)</span></label>
                                {/*<DatePicker selected={this.state.dob} onChange={(date) => handleSDate(date)} />*/}
                                <Calendar  
                                    onChange={e => handleSDate(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    className={'calendarX'}
                                />
                            </div>
                            <div className="inlineLnI">
                                <label for='phone' className="eCorrection">Phone Number <span className="req">(Require)</span></label>
                                <input type="text" name='phone' className="twoIn2" onChange={e => handlePhone(e)} />
                            </div>
                        </div>


                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='niNumber' className="eCorrection">NI Number <span className="req">(Require)</span></label>
                                <input type="text" name='niNumber' className="twoIn2" onChange={e => handleNi(e)} value={this.state.nino}/>
                            </div>
                            <div className="inlineLnI">
                                <label for='dlNo' className="eCorrection">DL Number <span className="req">(Require)</span></label>
                                <input type="text" name='dlNo' className="twoIn2" onChange={e => handleDl(e)} value={this.state.dlno}/>
                            </div>
                        </div>

                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='dlExpiry' className="eCorrection">DL Expiry Date <span className="req">(Require)</span></label>
                                {/*<DatePicker selected={this.state.expiry} onChange={(date) => handleDLExpiry(date)} />*/}
                                <Calendar  
                                    onChange={e => handleDLExpiry(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    className={'calendarX'}
                                />
                            </div>
                            <div className="inlineLnI">
                                <label for='points' className="eCorrection">Points on the license <span className="req">(Require)</span></label>
                                <input type="Number" name='points' className="twoIn2" onChange={e => handlePoints(e)} />
                            </div>
                        </div>

                        <div className="inlineLnx">
                                <label for='email' className="eCorrection correctionFieldsMobile5">Email <span className="req">(Require)</span></label>
                                <input type="text" name='email' className="twoIn2 correctionFieldsMobile5" onChange={e => handleEmail(e)}  />
                            </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='address1' className="eCorrection">Address 1 <span className="req">(Require)</span></label>
                                <input type="text" name='address1' className="twoIn2" onChange={e => handleAddress1(e)} />
                            </div>
                            <div className="inlineLnI">
                                <label for='address2' className="eCorrection">Address 2 <span className="req">(Require)</span></label>
                                <input type="text" name='address2' className="twoIn2" onChange={e => handleAddress2(e)} />
                            </div>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='town' className="eCorrection">Town <span className="req">(Require)</span></label>
                                <input type="text" name='town' className="twoIn2" onChange={e => handleTown(e)} />
                            </div>
                            <div className="inlineLnI">
                                <label for='county' className="eCorrection">County <span className="req">(Require)</span></label>
                                <input type="text" name='county' className="twoIn2" onChange={e => handleCounty(e)} />
                            </div>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='postCode' className="eCorrection">Post Code <span className="req">(Require)</span></label>
                                <input type="text" name='postCode' className="twoIn2" onChange={e => handlePostCode(e)} value={this.state.postCode}/>
                            </div>
                            <div className="inlineLnI">
                                <label for='status' className="eCorrection">Status <span className="req">(Require)</span></label>
                                <Select 
                                    options={statusOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleStatus(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='notes' className="eCorrection correctionFieldsMobile5">Notes <span className="req">(Require)</span></label>
                                <input type="text" name='notes' className="twoIn2 correctionFieldsMobile5" onChange={e => handleNotes(e)}  />
                        </div>

                        <div className="submitButtonAss">
                            <button type="button" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>
                        </div> </> :''
                        }
                    </div> 
                </div>
            </div> 
     
            } 
            </>                   
        )
    }
}