import React from "react";

export default function Video(props){
  let videoId;
  let reg;
 
  //if(props.params.formattedValue.split('/')[1]){
    videoId = props.params?.formattedValue?.split('/')[1];
    reg = props.params?.formattedValue?.split('/')[0];
  //}
  console.log(reg)
  console.log(videoId)
  return( 
    <>
      {reg && videoId ? 
        <div className="video">
            <video width="1500" height="1500" controls >
                <source src={'https://node.tambor.uk:9000/videos/'+reg+'/'+videoId} type='video/webm'></source>
            </video>
        </div> :''
      }
    </>
    )
}