import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import ReactPDF from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';

const LiveInteriorViewer = (props) => {
   const [render, setRender] =useState();
   let interval;
   async function intervalCam (){
      await axios.post("https://node.tambor.uk:9000/getLiveFile"+props.camera ,{link: props.link},{responseType: 'arraybuffer'}).then(response => {
         let blob = new Blob([response.data], { type: "application/jpg" }); 
         console.log(response+'--------------------'+props.camera)
         setRender(blob) 
      })
   }
   useEffect(()=>{
    interval = setInterval(intervalCam ,5000)
   }, [])
   let url
   if(render){
      url = URL.createObjectURL(render)
   }
   /* useEffect(()=>{
        clearInterval(interval)
    },[])*/
 return (
   <>
   {url ?
    <img src={url} style={{minWidth: '1370px', minHeight: '1130px', marginLeft: '65px', paddingBottom: '0px'}} alt={"snap"} ></img>:
      <div className='circular'>
         <CircularProgress color='success' />w
      </div>
   }
   </>
 );
};
export default LiveInteriorViewer;