import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { IconButton } from '@material-ui/core';
import Slide from '@mui/material/Slide';
import Register from "./register.component";
import ModAccessTable from "./Access/modTable.component";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class BoardModerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      open: false,
      users: ''
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getUsers().then(response =>{
      console.log(response.data)
      this.setState({users: response.data})
    },
    error => {
      this.setState({
        content:
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      })
  }
  )
}
  render() {
    const handleClose = () => {
      this.setState({open: false});
      setTimeout(window.location.reload(false), 500)
    }
    const handleOpen = () =>{
      this.setState({open: true});
    }
    console.log(this.state.users)
    return (
      <div className="containerl">
        <button className="buttonPay" onClick={handleOpen}>+New User</button>
       <ModAccessTable users={this.state.users} />
        <React.Fragment>
            <Dialog
                fullScreen
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <div >
                <Grid container>
                    <Grid item xs={12}>

                        <div className="pictures">
                          <Register />
                        </div>
                    </Grid>
                </Grid>
                </div>                          
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <button className="buttonPictures" onClick={handleClose}>Close</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
      </div>
      
    );
  }
}

