import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SupplierBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let url_f = '/allocation';
let data_f = 'url_f)';
 let url_D = '/DVLA';
 let data_D = 'url_D)';
 let url_R = '/RTW';
 let data_R = 'url_R)';
 let url = '/applicants';
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;
 
 useEffect(()=>{
    if(!data_f || !data_D || !data_R || !data){
        setLoading(true)
    }else{
        setLoading(false)
    }
 },[data_f, data_D, data_R, data])

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 300,
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'table-header',
      width: 150,
      editable: true,
    },
   {
      field: 'account_no',
      headerName: 'Account Number',
      headerClassName: 'table-header',
      width: 200,
      editable: true,
    },
     {
        field: 'terms',
        headerName: 'Terms',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
        
      },
      {
        field: 'contact',
        headerName: 'Contact Name',
        headerClassName: 'table-header',
        width: 350,
        editable: true,
        
      },
      {
        field: 'phone_no',
        headerName: 'Phone Number',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
        
      },
      {
        field: 'email',
        headerName: 'Email',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
        
      },
      {
        field: 'tcs',
        headerName: 'TCS',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
        
      },
      {
        field: 'notes',
        headerName: 'Other',
        headerClassName: 'table-header',
        width: 400,
        editable: true,
        
      }
  ];

let displaiC = [];
if(data){
data?.map(dataRow => {

      
        displaiC.push(
                {id: dataRow.entry, name: dataRow.name, type: dataRow.type , account_no: dataRow.account_no, terms: dataRow.terms, contact: dataRow.account_manager, email: dataRow.email, phone_no: dataRow.phone_no, tcs: dataRow.tcs, notes: dataRow.notes}
        ) 
   

    })
  }

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {
        let reg = '';
                if(dataRow.name?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.account_no?.toUpperCase().match(searchInput.toUpperCase()) ){
                
                    
                    findC.push(
                        {id: dataRow.entry, name: dataRow.name, type: dataRow.type , account_no: dataRow.account_no, terms: dataRow.terms, contact: dataRow.account_manager, email: dataRow.email, phone_no: dataRow.phone_no, tcs: dataRow.tcs, notes: dataRow.notes}
                        )
                }
            
        
});

}

const handleDelete = () =>{
  console.log(selected)
  UserService.deleteSuppliers(selected)
  setTimeout(()=>{
    window.location.reload(true)
  }, 500)
}
let dataH = {};
const handleCellEdit = (params) =>{
    console.log(params);
   dataH = {entry: params.id, account_no: params.account_no, name: params.name, phone_no: params.phone_no || null, terms: params.terms, email: params.email || null, type: params.type, account_manager: params.contact || null, notes: params.notes || null}
   console.log(dataH)

      UserService.updateSupplier(dataH)
      setTimeout(()=>{
        window.location.reload(true)
      }, 200)
     return params;
  }

return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
        <button type='button' className='buttonDel' onClick={handleDelete}
        >Delete selected</button><h3 className='availableOffTitleC titleCorrection'>Suppliers</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
   
    }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>: <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
         
        }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>}
    
</div>
)

};

export default SupplierBar;