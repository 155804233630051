import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import { isMobile } from 'react-device-detect';

const PDFProfile = (props) => {
   const [render, setRender] =useState();
   
   useEffect(()=>{
      if(props.link?.split('/').length == 7){
         axios.post("https://node.tambor.uk:9000/getPdfCondition" ,{file: props.link},{responseType: 'arraybuffer'}).then(response => {
            let blob = new Blob([response.data], { type: "application/pdf" }); 
            setRender(blob) 
            if(isMobile){
               const fileURL = window.URL.createObjectURL(blob);
               let alink = document.createElement("a");
               alink.href = fileURL;
               alink.download ="file.pdf";
               alink.click();
            }
         }).catch(err => console.log(err))
      }else if(props.link?.split('/').length == 1){
         if(props.heading == 'On Hire'){
            axios.post("https://node.tambor.uk:9000/getPdfNew" ,{file: props.link},{responseType: 'arraybuffer'}).then(response => {
               let blob = new Blob([response.data], { type: "application/pdf" }); 
               setRender(blob) 
               if(isMobile){
                  const fileURL = window.URL.createObjectURL(blob);
                  let alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = "file.pdf";
                  alink.click();
               }
            }).catch(err => console.log(err))
         }else if(props.heading == 'Off Hire' || props.heading == 'Off Road-Condition Report Needed'){
            axios.post("https://node.tambor.uk:9000/getPdfOff" ,{file: props.link},{responseType: 'arraybuffer'}).then(response => {
               let blob = new Blob([response.data], { type: "application/pdf" }); 
               setRender(blob) 
               if(isMobile){
                  const fileURL = window.URL.createObjectURL(blob);
                  let alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = "file.pdf";
                  alink.click();
               }
            }).catch(err => console.log(err))
         }
      }else{
         setRender('')
      }
      
   }, [props.link])
   let url
   if(render){
      url = URL.createObjectURL(render)
   }
 return (
 <div className='pdfviewP'>

   {isMobile? '' : <iframe src={url} className='pdfview' />}
    {/*<ReactPDF
      file={{
         data: render
      }}
   />*/}
   
 </div>
 );
};
export default PDFProfile;