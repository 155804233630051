import React, { useState } from "react";
import { ureState, useEffect, useRef } from "react";
import Select from 'react-select';
import Calendar from 'react-calendar';
import UserService from "../../services/user.service";
import { useSyncExternalStore } from "react";
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SignatureCanvas from 'react-signature-canvas';
import DrawIcon from '@mui/icons-material/Draw';
import AuthService from "../../services/auth.service";
import {QrScanner} from '@yudiel/react-qr-scanner';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@mui/material/Slide';
import CameraIcon from '@mui/icons-material/Camera';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        textAlign: 'center',
      },
      imgBox: {
        maxWidth: "80%",
        maxHeight: "80%",
        margin: "10px"
      },
      img: {
        height: "inherit",
        maxWidth: "inherit",
      },
      input: {
        display: "none"
      }
    }))
const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const marks = [
    {value: 0, label: '0'},
    {value: 25, label: '25%'},
    {value: 50, label:'50%'},
    {value: 75, label:'75%'},
    {value: 100, label:'100%'}
  ];

const OffHire = (props) =>{
const classes = useStyles();
const [ step , setStep ] = useState(1);
const [ vin, setVin ] = useState();
const [ reg, setReg ] = useState();
const [ make, setMake ] = useState();
const [ model, setModel ] = useState();
const [ classY, setClassY] = useState();
const [ rate, setRate ] = useState();
const [ deposit, setDeposit ] = useState();
const [ assigned_to, setAssigned_to ] = useState();
const [ assigned_date, setAssigned_date ] = useState();
const [ checkSubmit, setCheckSubmit ] = useState();
const [ account_no, setAccount_no ] = useState();
const [ phone_no, setPhone_no ] = useState();
const [ email, setEmail ] = useState();
const [ address1, setAddress1 ] = useState();
const [ address2, setAddress2 ] = useState();
const [ town, setTown ] = useState();
const [ county, setCounty ] = useState();
const [ dl_no, setDl_no ] = useState();
const [ dl_expiry, setDl_expiry ] = useState();
const [ dl_issued, setDl_issued ] = useState();
const [ post_code, setPost_code ] = useState();
const [ name, setName ] = useState();
const [ stepSig, setStepSig ] = useState(1);
const [ mileage, setMileage ] =useState();
const [ source, setSource ] = useState([]);
const [ display, setDisplay ] = useState([]);
const [ files, setFiles ] = useState([]);
const [ fileCount, setFileCount ] = useState(0);
const [ drivers, setDrivers ] = useState([]);
const [ driverList, setDriverList ] = useState([]);
const [ fuel, setFuel ] = useState();
const [ location, setLocation ] = useState()
const [ dateValue, setDateValue ] = useState()
const [valueRadio1, setValueRadio1] = useState('');
const [valueRadio2, setValueRadio2] = useState('');
const [valueRadio3, setValueRadio3] = useState('');
const [valueRadio4, setValueRadio4] = useState('');
const [valueRadio5, setValueRadio5] = useState('');
const [clientSigUrl1, setClientSigUrl1] = useState('');
const [clientSigUrl2, setClientSigUrl2] = useState('');
const [openPictures, setOpenPictures ] = useState(false);
const [ warning, setWarning ] = useState([]);
const [ warningData, setWarningData ] = useState([])
const [ notes, setNotes ] = useState()
const [ sub, setSub ] = useState(false)
const user = AuthService.getCurrentUser();
let fleetUser = ''
if(user){
    fleetUser = user?.username;
}

const sigCanvas = useRef({});
const sigCanvas2 = useRef({});

const handleRadio1 = (event) => {
    event.preventDefault()
  setValueRadio1(event.target.value);
};
const handleRadio2 = (event) => {
    event.preventDefault()
    setValueRadio2(event.target.value);
};
const handleRadio3 = (event) => {
    event.preventDefault()
    setValueRadio3(event.target.value);
};
const handleRadio4 = (event) => {
    event.preventDefault()
    setValueRadio4(event.target.value);
};
const handleRadio5 = (event) => {
    event.preventDefault()
    setValueRadio5(event.target.value);
};

function valuetext(value) {
    return `${value}%`;
  }
const handleNext = () =>{
    setStep(step+1)
}
const handlePrevious = () =>{
    setStep(step-1)
}
const handleVin = (e) =>{
    e.preventDefault()
    if(e.target.value.length <= 17){
        setVin(e.target.value.toUpperCase())
    }
}
useEffect(()=>{
    if(vin?.length == 17){
        UserService.conditionAsset({vin: vin}).then(response => {
            console.log(response.data)
            setReg(response.data.reg)
            setMake(response.data.make)
            setModel(response.data.model)
            setClassY(response.data.class)
            setRate(response.data.rate)
            setDeposit(response.data.deposit)
            setAssigned_to(response.data.assigned_to)
            setAssigned_date(response.data.assigned_date)
            setNotes(response.data.notes)
            setLocation(response.data.assigned_location)
        }).catch(err =>console.log(err))
    }
}, [vin])
useEffect(()=>{
    UserService.getContacts().then(response =>{
        
        console.log(response.data)
        setDrivers(response.data)
        
    }).catch(err =>console.log(err))
    UserService.getAllAssets().then(response =>{
        let data = response.data;
        console.log(response)
        data.map(driver => {
            if(driver.assigned_to && driver.assigned_to !== 'Skilled Solutions Ltd'){
                setDriverList(oldList => [...oldList, {value: driver.assigned_to, label: driver.assigned_to}])
            }
        })
    }).catch(err =>console.log(err))


    UserService.getWarning()
      .then(response =>{
        console.log(response.data)
        const len = response.data.length
        for(let i = 0; i < len; i++){
            console.log(response.data[i].heading)
             setWarning(oldWarning => [...oldWarning, <FormControlLabel
                label={response.data[i].heading}
                control={<Checkbox onChange={e => handleWarning(i, e)} className="check"/>}
            />])
        }
        for(let i = 0; i < warning.length; i++ ){
            warningData.push('')
        }
        const handleWarning = (num, e) =>{
            console.log(e.target.checked)
            if(e.target.checked == true){
                warningData[num] = response.data[num]?.heading
            }else{
                warningData[num] = ''
            }
            console.log(warningData)
        }
      })
},[])

const handleDriver = (e) =>{
    if(drivers){
        drivers.map(driver => {
            if(driver.name == e.value){
                setAccount_no(driver.account_no)
                setPhone_no(driver.phone_no)
                setEmail(driver.email)
                setAddress1(driver.address1)
                setAddress2(driver.address2)
                setTown(driver.town)
                setCounty(driver.county)
                setDl_no(driver.dl_no)
                setDl_expiry(driver.dl_expiry)
                setDl_issued(driver.dl_issued)
                setPost_code(driver.post_code)
                setName(driver.name)
            }
        })
    }
}
let startDate = new Date().getTime()-259200*1000;
let endDate = new Date().getTime()+3000*86400;
const clear1 = () => sigCanvas.current.clear();
const clear2 = () => sigCanvas2.current.clear();
const handleFail = () =>{
    
    setStepSig(stepSig+1)
    console.log(sigCanvas)
    console.log(sigCanvas2)
}
const handleBackSig = () =>{
   
    setStepSig(stepSig-1);
}
const handleDay = (e) =>{
  
    setDateValue(e)
}

const handleSubmit = () =>{
    //console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    const unixToday = new Date().getTime();
   /* let data = {
                    vin: vin, 
                    reg: reg, 
                    make: make, 
                    model: model,
                    class: classY, 
                    rate: rate, 
                    deposit: deposit, 
                    assigned_to: assigned_to, 
                    assigned_date: assigned_date, 
                    driver: name, 
                    account_no: account_no, 
                    phone_no: phone_no,
                    email: email,
                    address: address1+ (address2? ', '+address2:''),
                    town: town,
                    county: county,
                    post_code: post_code,
                    dl_no: dl_no,
                    dl_expiry: dl_expiry,
                    dl_issued: dl_issued,
                    off_date: dateValue,
                    mileage: mileage,
                    fuel: fuel, 
                    windscreen: valueRadio2,
                    interior: valueRadio3,
                    exterior: valueRadio4,
                    cleanliness: valueRadio1,
                    driverFail: valueRadio5,
                    fleetUser: fleetUser,
                    unixToday: unixToday,
                    driverSig: valueRadio5 ? '' : sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
                    userSig: sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png")
                }*/
    
    const formData = new FormData();            
    formData.append('vin', vin)
    formData.append('reg', reg) 
    formData.append('make', make) 
    formData.append('model', model)
    formData.append('class', classY) 
    formData.append('rate', rate) 
    formData.append('deposit', deposit) 
    formData.append('assigned_to', assigned_to) 
    formData.append('assigned_date', assigned_date) 
    formData.append('driver', name )
    formData.append('account_no', account_no) 
    formData.append('phone_no', phone_no)
    formData.append('email', email)
    formData.append('address', address1+ (address2?  ', '+address2 :''))
    formData.append('town', town)
    formData.append('county', county)
    formData.append('post_code', post_code)
    formData.append('dl_no', dl_no)
    formData.append('dl_expiry', dl_expiry)
    formData.append('dl_issued', dl_issued)
    formData.append('off_date', dateValue)
    formData.append('mileage', mileage)
    formData.append('fuel', fuel)
    formData.append('windscreen', valueRadio2)
    formData.append('interior', valueRadio3)
    formData.append('exterior', valueRadio4)
    formData.append('cleanliness', valueRadio1)
    formData.append('warning', warningData)
    formData.append('driverFail', valueRadio5)
    formData.append('fleetUser', fleetUser)
    formData.append('unixToday', unixToday)
    formData.append('fileCount', fileCount)
    formData.append('notes', notes)
    formData.append('driverSig', valueRadio5 ? '' : sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    formData.append('userSig', sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png"))
    formData.append('location', location)
    for(let i = 0; i < files.length; i++){
        formData.append('file'+i, files[i])
    }
    setSub(true)
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    UserService.postOffhire(formData).then(response =>{
        setCheckSubmit(true)
        sleep(2000).then(()=>{
            window.location.reload(false)
        })
        setSub(false)
    }).catch(err=>{
        console.log(err)
        setCheckSubmit(false)
    }) 
    let dataActivities = {reg: reg, 
        heading: 'Off Road-Condition Report Needed', 
        assigned_to: name, 
        assigned_date: dateValue, 
        file: reg+'_'+unixToday+'.pdf',
        damages_windscreen: valueRadio1,
        damages_interior: valueRadio2,
        damages_exterior: valueRadio3,
        cleanliness: valueRadio4,
        mileage: mileage,
        fuel: fuel,
        warning_lights: warningData,
        vin: vin
    }
    UserService.postActivityNew(dataActivities).then(response => {
        //setSubmit(true)
        }).catch(err => console.log(err))
    }
    const handleCapture = (target) => {
        setFiles(oldFiles =>[...oldFiles, target.files[0]])
        setFileCount(fileCount+1);
        if (target.files) {
            if (target.files.length !== 0) {
            const file = target.files[0];
            const newUrl = URL.createObjectURL(file);
            console.log(target.files[0])
            setSource(oldSource =>[...oldSource, newUrl]);
            }
        }
    };
    const handleBox = (index) =>{
        console.log(index)
       setSource(source.filter((ele, indexSource)=>{
        return index !== indexSource
       }))
    }
    const handleClickOpen = () => {
        setSource([])
        setOpenPictures(true);
        setDisplay([])
        setFileCount(0)
    };
    const handleClickClose = () =>{
        setOpenPictures(false)
    }
    useEffect(()=>{
        setDisplay([])
        //setDamageType([]);
        source.forEach((link, index) => {
          
            let element = <div className="flex" id={index}><div><Box display="flex" justifyContent="center" border={1} marginLeft={300} className={classes.imgBox}>
                                <img src={link} alt={"snap"} className={classes.img}></img>
                                
                                </Box>
                                </div>
                            </div>
            setDisplay(oldDisplay =>[...oldDisplay, element])
                
         
        })
        
    },[source !== null, source])
    return (
        <>
        {checkSubmit ? <h1 className="assetsOutcome">Off Hire done!!</h1> : checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
        <div>
            
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">OffHire</h1>
                    </header> 
                    {step == 1 ?
                        <>
                        <h2 className="subttV">Vehicle Details</h2>
                        <div className="scanner">
                            <QrScanner
                                onDecode={(result) => setVin(result)}
                                onError={(error) => console.log(error?.message)}
                            />
                        </div>
                        <div className="inlineLnx">
                            <label for='notes' className="eCorrection">Vin <span className="req">(Require)</span></label>
                            <input type="text" name='notes' className="twoIn3 correctionFieldsMobile" onChange={e => handleVin(e)} value={vin} />
                        </div> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Vehicle Registration<span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile3" onChange={e => setReg(e.target.value.toUpperCase())} defaultValue={reg} />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Make<span className="req">(Require)</span></label>
                                <input type="text" name='make' className="twoIn2 correctionFieldsMobile3" onChange={e => setMake(e.target.value.toUpperCase())} defaultValue={make} />
                            </div>
                        </div> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='model' className="eCorrection">Model<span className="req">(Require)</span></label>
                                <input type="text" name='model' className="twoIn2 correctionFieldsMobile3" onChange={e => setModel(e.target.value.toUpperCase())} defaultValue={model} />
                            </div>
                            <div className="inlineLnI">
                                <label for='class' className="eCorrection">Class<span className="req">(Require)</span></label>
                                <input type="text" name='class' className="twoIn2 correctionFieldsMobile3" onChange={e => setClassY(e.target.value.toUpperCase())} defaultValue={classY} />
                            </div>
                        </div> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='rate' className="eCorrection">Rate<span className="req">(Require)</span></label>
                                <input type="text" name='rate' className="twoIn2 correctionFieldsMobile3" onChange={e => setRate(e.target.value.toUpperCase())} defaultValue={rate} />
                            </div>
                            <div className="inlineLnI">
                                <label for='deposit' className="eCorrection">Deposit<span className="req">(Require)</span></label>
                                <input type="text" name='deposit' className="twoIn2 correctionFieldsMobile3" onChange={e => setDeposit(e.target.value.toUpperCase())} defaultValue={deposit} />
                            </div>
                        </div> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='assigndTo' className="eCorrection">Assigned To<span className="req">(Require)</span></label>
                                <input type="text" name='assigndTo' className="twoIn2 correctionFieldsMobile3" onChange={e => setAssigned_to(e.target.value.toUpperCase())} defaultValue={assigned_to} />
                            </div>
                            <div className="inlineLnI">
                                <label for='assignedDate' className="eCorrection">Assigned Date<span className="req">(Require)</span></label>
                                <input type="text" name='assignedDate' className="twoIn2 correctionFieldsMobile3" onChange={e => setAssigned_date(e.target.value.toUpperCase())} defaultValue={assigned_date} />
                            </div>
                        </div> 
                        <div className="inlineLnx correctionFieldsMobile correctionX">
                                <label for='make' className="eCorrection">Warning Lights <span className="req">(Require)</span></label>
                            
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                

                                    {warning}


                                </Box>
                            </div>
                            <div className="inlineLnx">
                                <label for='notes' className="eCorrection">Notes </label>
                                <input type="text" name='notes' className="twoIn3 correctionFieldsMobile" onChange={e => setNotes(e.target.value)} value={notes} />
                            </div> 
                        </>: 
                        step == 2 ?
                        <>
                        <h2 className="subttV">Driver</h2>
                        <div className="inlineLnx">
                                <label for='rate' className="eCorrection">Driver <span className="req">(Require)</span></label>
                                <Select 
                                    options={driverList}
                                    styles={colourStylesS}
                                    onChange={e => handleDriver(e)}
                                    
                                /> 
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='account' className="eCorrection">Account Number<span className="req">(Require)</span></label>
                                <input type="text" name='account' className="twoIn2 correctionFieldsMobile3" onChange={e => setAccount_no(e.target.value.toUpperCase())} defaultValue={account_no} />
                            </div>
                            <div className="inlineLnI">
                                <label for='phone' className="eCorrection">Phone Number<span className="req">(Require)</span></label>
                                <input type="text" name='phone' className="twoIn2 correctionFieldsMobile3" onChange={e => setPhone_no(e.target.value.toUpperCase())} defaultValue={phone_no} />
                            </div>
                        </div>
                        <div className="inlineLnx">
                            <label for='email' className="eCorrection">Email <span className="req">(Require)</span></label>
                            <input type="text" name='email' className="twoIn3 correctionFieldsMobile" onChange={e => setEmail(e.target.value.toUpperCase())} defaultValue={email} />
                        </div> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='address1' className="eCorrection">Address 1<span className="req">(Require)</span></label>
                                <input type="text" name='address1' className="twoIn2 correctionFieldsMobile3" onChange={e => setAddress1(e.target.value.toUpperCase())} defaultValue={address1} />
                            </div>
                            <div className="inlineLnI">
                                <label for='address2' className="eCorrection">Address 2<span className="req">(Require)</span></label>
                                <input type="text" name='address2' className="twoIn2 correctionFieldsMobile3" onChange={e => setAddress2(e.target.value.toUpperCase())} defaultValue={address2} />
                            </div>
                        </div>
                        <div className="inlineLnx">
                            <label for='town' className="eCorrection">Town <span className="req">(Require)</span></label>
                            <input type="text" name='town' className="twoIn3 correctionFieldsMobile" onChange={e => setTown(e.target.value.toUpperCase())} defaultValue={town}/>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='county' className="eCorrection">County<span className="req">(Require)</span></label>
                                <input type="text" name='county' className="twoIn2 correctionFieldsMobile3" onChange={e => setCounty(e.target.value.toUpperCase())} defaultValue={county} />
                            </div>
                            <div className="inlineLnI">
                                <label for='postCode' className="eCorrection">Post Code<span className="req">(Require)</span></label>
                                <input type="text" name='postCode' className="twoIn2 correctionFieldsMobile3" onChange={e => setPost_code(e.target.value.toUpperCase())} defaultValue={post_code} />
                            </div>
                        </div>
                        <div className="inlineLnx">
                            <label for='dl' className="eCorrection">Driving License Number <span className="req">(Require)</span></label>
                            <input type="text" name='dl' className="twoIn3 correctionFieldsMobile" onChange={e => setDl_no(e.target.value.toUpperCase())} defaultValue={dl_no} />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='exp' className="eCorrection">License Expiry <span className="req">(Require)</span></label>
                                <input type="text" name='exp' className="twoIn2 correctionFieldsMobile3" onChange={e => setDl_expiry(e.target.value.toUpperCase())} defaultValue={dl_expiry} />
                            </div>
                            <div className="inlineLnI">
                                <label for='iss' className="eCorrection">License Issued <span className="req">(Require)</span></label>
                                <input type="text" name='iss' className="twoIn2 correctionFieldsMobile3" onChange={e => setDl_issued(e.target.value.toUpperCase())} defaultValue={dl_issued} />
                            </div>
                        </div>
                        </>
                        : step== 3 ?
                        <>
                        <h2 className="subttV">Details of OffHire</h2>
                        <div className="inlineLnx">
                            <label for='notes' className="eCorrection">Select the date of the offhire <span className="req">(Require)</span></label>
                            <div className="calendarOff">
                                <Calendar 
                                    onChange={handleDay}
                                    minDate={new Date(startDate)} 
                                    maxDate={new Date(endDate)} 
                                    
                                />
                            </div>
                        </div> 
                        <div className="inlineLnx">
                            <label for='mileage' className="eCorrection">Mileage <span className="req">(Require)</span></label>
                            <input type="number" name='dl' className="twoIn3 correctionFieldsMobile2" onChange={e => setMileage(e.target.value)} /*defaultValue={dl_no}*/ />
                        </div>
                        
                            <div className="inlineLnx">
                                <label for='fuel' className="eCorrection">Fuel Level <span className="req">(Require)</span></label>
                                <div className="radio"><Slider
                                    aria-label="Custom marks"
                                    defaultValue={0}
                                    getAriaValueText={valuetext}
                                    step={1}
                                    valueLabelDisplay={valuetext}
                                    onChange={e => setFuel(e.target.value)}
                                    marks={marks}
                                    className="correctionFieldsMobile4"
                                /></div>
                            
                            
                        </div>
                        <div className="flexAssets flexAssetsC">
                            <div className="inlineLnI">
                                <label for='wind' className="eCorrection">Windscreen <span className="req">(Require)</span></label>
                                <div className="radio correctionFieldsMobile">
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Is the windscreen damaged?</h3></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={valueRadio2}
                                                onChange={handleRadio2}
                                            >
                                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                                                '& .MuiSvgIcon-root': {
                                                                                                                    fontSize: 38,
                                                                                                                }}} 
                                                                                                    className="check"
                                                                                                        />}
                                                                                            sx={{m: 3}}            
                                                                                            label="Yes"
                                                                                        />
                                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                                                '& .MuiSvgIcon-root': {
                                                                                                                    fontSize: 38,
                                                                                                                    
                                                                                                                }}} 
                                                                                                    className="check"
                                                                                                    />} 
                                                                                            sx={{m: 3}}         
                                                                                            label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                </div>                            </div>
                            <div className="inlineLnI">
                                <label for='iss' className="eCorrection">Interior <span className="req">(Require)</span></label>
                                <div className="radio correctionFieldsMobile">
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Does the vehicle has any interior damages?</h3></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={valueRadio3}
                                                onChange={handleRadio3}
                                            >
                                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                        />}
                                                                                            sx={{m: 3}}            
                                                                                            label="Yes"
                                                                                        />
                                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                    />} 
                                                                                            sx={{m: 3}}         
                                                                                            label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                </div>                           
                             </div>
                        </div>
                        <div className="flexAssets flexAssetsC">
                        <div className="inlineLnI">
                                <label for='iss' className="eCorrection">Exterior <span className="req">(Require)</span></label>
                                <div className="radio correctionFieldsMobile">
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Does the vehicle has any exterior damages?</h3></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={valueRadio4}
                                                onChange={handleRadio4}
                                            >
                                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                        />}
                                                                                            sx={{m: 3}}            
                                                                                            label="Yes"
                                                                                        />
                                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                    />} 
                                                                                            sx={{m: 3}}         
                                                                                            label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                </div>                           
                             </div>
                             <div className="inlineLnI">
                                <label for='clean' className="eCorrection">Cleanliness <span className="req">(Require)</span></label>
                                    <div className="radio correctionFieldsMobile">
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Is the vehicle clean?</h3></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={valueRadio1}
                                                onChange={handleRadio1}
                                            >
                                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                        />}
                                                                                            sx={{m: 3}}            
                                                                                            label="Yes"
                                                                                        />
                                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                        fontSize: 38,
                                                                                                    }}} 
                                                                                                    className="check"
                                                                                                    />} 
                                                                                            sx={{m: 3}}         
                                                                                            label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                </div>
                            </div>
                            </div>
                            <div className="flexAssets flexAssetsC">
                                <div className="inlineLnI" >
                                    {stepSig == 1 ?
                                    <>
                                    <label for='exp' className="eCorrection">Drivers Signature<span className="req">(Require)</span></label>
                                    <SignatureCanvas 
                                        penColor='black'
                                        canvasProps={{className: 'sigCanvas'}}
                                        ref={sigCanvas}
                                        className="correctionFieldsMobile"
                                    />
                                    <br></br>
                                    <div className="flex">
                                        <button className='buttonOff' onClick={clear1}>Clear</button>
                                        <button className='buttonOff' onClick={handleFail}>Failed to sign&gt;</button>
                                    </div>  
                                    </>:
                                    stepSig == 2?
                                    <>
                                        <label for='exp' className="eCorrection">Reason for failing the signature<span className="req">(Require)</span></label>
                                        <div className="radio">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={valueRadio5}
                                                    onChange={handleRadio5}
                                                >
                                                    <FormControlLabel disableTypography value="Vehicle Stolen" control={<Radio sx={{
                                                                                                        '& .MuiSvgIcon-root': {
                                                                                                            fontSize: 38,
                                                                                                        }}} 
                                                                                                        className="check"
                                                                                                            />}
                                                                                                sx={{m: 3}}            
                                                                                                label="Vehicle Stolen"
                                                                                            />
                                                    <FormControlLabel disableTypography value="Vehicle Breakdown" control={<Radio sx={{
                                                                                                        '& .MuiSvgIcon-root': {
                                                                                                            fontSize: 38,
                                                                                                        }}} 
                                                                                                        className="check"
                                                                                                        />} 
                                                                                                sx={{m: 3}}         
                                                                                                label="Vehicle Breakdown" />
                                                    <FormControlLabel disableTypography value="Vehicle Recovered" control={<Radio sx={{
                                                                                                        '& .MuiSvgIcon-root': {
                                                                                                            fontSize: 38,
                                                                                                        }}}
                                                                                                        className="check"
                                                                                                        />} 
                                                                                                sx={{m: 3}}         
                                                                                                label="Vehicle Recovered" />
                                                    <FormControlLabel disableTypography value="Client Refused" control={<Radio sx={{
                                                                                                        '& .MuiSvgIcon-root': {
                                                                                                            fontSize: 38,
                                                                                                        }}} 
                                                                                                        className="check"
                                                                                                        />} 
                                                                                                sx={{m: 3}}         
                                                                                                label="Client Refused" />                                            
                                                </RadioGroup>
                                            </FormControl>
                                        </div> 
                                        <button className='buttonOff' onClick={handleBackSig}>&#60;Back to signature</button> 
                                    </>:''
                                    }
                                </div>
                                <div className="inlineLnI">
                                    <label for='iss' className="eCorrection">Fleet representative: {user.username} <span className="req">(Require)</span></label>
                                    <SignatureCanvas 
                                        penColor='black'
                                        canvasProps={{className: 'sigCanvas'}}
                                        ref={sigCanvas2}
                                       //onChange={console.log(sigCanvas2)}
                                    />
                                    <br></br><button className='buttonOff' onClick={clear2}>Clear</button>
                                                
                                             
                                </div>
                                
                            </div>
                            
                        </>:''
                        
                    }
                </div> 
            
            {step == 1 ? vin ?
                <div className="submitButtonAss">
                    <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>
                </div> :
                 <div className="submitButtonAss">
                    <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>next&gt;</button>
                </div> : 
             step == 2 ?
                <div className="flexo">
                    
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" onClick={handleClickOpen}>Take Pictures</button>
                    </div>
                    <div className="spaceing"></div>
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                    </div>
                    <div className="spaceing"></div>
            {name && fileCount >= 4 ?      
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>
                    </div>:
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>next&gt;</button>
                    </div>
            }       
                </div>: 
             step == 3 ?
                <div className="flexo">
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                    </div>
                    <div className="spaceing"></div>
            { dateValue && mileage && fuel && valueRadio1 && valueRadio2 && valueRadio3 && valueRadio4 && sigCanvas  && sigCanvas2 ?
                    <div className="submitButtonAss">
                        {!sub ?<button type="button" className="submitButtonnAss" onClick={handleSubmit}>submit</button> : <button type="button" className="submitButtonnAss" ><CircularProgress/>Please Wait</button>}
                    </div>:
                    <div className="submitButtonAss">
                        <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>submit</button>
                    </div>
            }
                </div>  :'' 
            }
        </div>
            }
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openPictures}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div className={classes.root}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="pictures">
                            {source &&
                                    display}
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="icon-button-file"
                                    type="file"
                                    capture="environment"
                                    onChange={(e) => handleCapture(e.target)}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    >
                                    <CameraIcon sx={{fontSize: 80}} color="primary" />
                                    </IconButton>
                                </label>
                        
                            </div>
                        </Grid>
                    </Grid>
                    </div>                          
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="buttonPictures" onClick={handleClickClose}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
}
export default OffHire;
