import React, { Component, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const steps = [
    'Select a date',
    'Complete the van details',
    'Complete payment details',
    'Select Suplier',
    'Select insurance'
  ];
let applicantsOptions = [];

const typeOptions = [
    {value: '1st Notice', label: '1st Notice'},
    {value: '2nd Notice', label: '2nd Notice'},
    {value: 'Enforcement Notice', label: 'Enforcement Notice'},
    {value: 'Letter from Authority', label: 'Letter from Authority'},
    {value: 'Notice to Owner', label: 'Notice to Owner'},
    {value: 'Notice from Supplier', label: 'Notice from Supplier'}
];

let assetsOptions = [];
const codesOptions =[];
const authorityOptions = [];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

export default class AddPcn extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          date: "",
          pcnNo: "",
          reg: "",
          name: "",
          type: "",
          cost: "",
          assets: "" ,
          models: "" ,
          checkSubmit: "",
          applicants:"",
          codes: "",
          code:"",
          issuing: "",
          selectedOption:"",
          selectedFile: null,
          fileName: "",
          description: "",
          authority: ""
        };
      }
    componentDidMount() {
        
        UserService.getOffRoadAssets().then(
            response => {
              this.setState({
                assets: response.data,
              });
              let assets = response.data;
                let assetsLen = assets.length;
                for(let i = 0; i< assetsLen; i++){
                    assetsOptions.push({value: assets[i].reg, label: assets[i].reg})
                }
            },
            error => {
              this.setState({
                assets:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getAllApplicants().then(
            response => {
              this.setState({
                applicants: response.data,
              });
              let applicants = response.data;
                let applicantsLen = applicants.length;
                for(let i =0; i < applicantsLen; i++){
                    applicantsOptions.push({value: applicants[i].name, label: applicants[i].name})
                }
            },
            error => {
              this.setState({
                applicants:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getCodes().then(
            response => {
              this.setState({
                codes: response.data,
              });
              let codes = response.data;
              let codesLen = codes.length;
              for(let i =0; i < codesLen; i++){
                  codesOptions.push({value: codes[i].Code, label: codes[i].Code})
              }
            },
            error => {
              this.setState({
                codes:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getIssuing().then(
            response => {
              this.setState({
                issuing: response.data,
              });
              let issuing = response.data;
                let isssuingLen = issuing.length;
                for(let i =0; i < isssuingLen; i++){
                    authorityOptions.push({value: issuing[i].Council, label: issuing[i].Council})
                }
            },
            error => {
              this.setState({
                issuing:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );    
    }
    
    render() {
     
        const handlePcnNo = (e) =>{
            this.setState({pcnNo: e.target.value})
        }

        const handleReg = (e) =>{
            this.setState({reg: e.value})
        }

        const handleName = (e) =>{
            this.setState({name: e.value})
        }

        const handleType = (e) =>{
                this.setState({type: e.value})
        }
        const handleCode = (e) =>{
                this.setState({code: e.value})
        }

        const handleSDate = (date) =>{
            this.setState({date: date})
        }
     
        const handleAuth = (e) =>{
                    this.setState({authority: e.value})
        }

        const handleDescription = (e) =>{
            this.setState({description: e.target.value})
        }
        const handleCost= (e) =>{
            this.setState({cost: e.target.value})
          
        }

        const handleOptionChange = (e) =>{
            this.setState({selectedOption: e.target.value})
    
        }
        
        let dataSend ={};
        const handleSubmit = () =>{
            
            dataSend = {PCN_No: this.state.pcnNo, Vehicle_Reg: this.state.reg, Driver: this.state.name, PCN_Type: this.state.type, Cost: this.state.cost, PCN_Date: this.state.date, PCN_Code: this.state.code, PCN_Description: this.state.description, Issued_By: this.state.authority, Status: this.state.selectedOption, File: 'http://localhost:8080/public/uploads/'+this.state.fileName+'.pdf'}
            console.log(dataSend);
            UserService.insertPCN(dataSend)
            .then(response => {
                this.setState({checkSubmit: true}) 
                console.log(response)
            }).catch(err => {
                this.setState({checkSubmit: false})
                console.log(err)
            })
            onFileUpload();
        }
        const onFileChange = (event) => {
            // Update the state
            this.setState({
                selectedFile: event.target.files[0],
            });
        };
        const onFileUpload = () => {
            
            this.setState({fileName: new Date().getTime()})
            const formData = new FormData();
            formData.append(
                "myFile",
                this.state.selectedFile,
                this.state.fileName
            );
            console.log(this.state.selectedFile);
            axios.post("https://node.tambor.uk:9000/uploadFile", formData)
                    .then(response => {
                        //this.setState({checkSubmit: true})
                        console.log(response)})
                    .catch(err =>{
                        console.log(err)
                        //this.setState({checkSubmit: false})
                    })    
        };
        const fileData = () => {
            if (this.state.selectedFile) {
                
                return (
                    <div>
                        <h2>File Details:</h2>
                        <p>
                            File Name:{" "}
                            {this.state.fileName}
                        </p>
     
                        <p>
                            File Type:{" "}
                            {this.state.selectedFile.type}
                        </p>
     
                        <p>
                            Last Modified:{" "}
                            {this.state.selectedFile.lastModifiedDate.toDateString()}
                        </p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <br />
                        <h4>
                            Choose before Pressing the Upload
                            button
                        </h4>
                    </div>
                );
            }
        };
        return(
            <>
            {this.state.checkSubmit == true ? <h1 className="assetsOutcome">PCN Added!!</h1> : this.state.checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Add PCN</h1>
                    </header> 
                    
                    <div>
                        
                        <h2 className="subttV"></h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='pcnNo' className="eCorrection correctionFieldsMobile5">PCN No <span className="req">(Require)</span></label>
                                <input type="text" name='pcnNo' className="twoIn2 correctionFieldsMobile5" onChange={e => handlePcnNo(e)} value={this.state.pcnNo.toUpperCase()} />
                            </div>
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection correctionFieldsMobile5">Vehicle Registration <span className="req">(Require)</span></label>
                                <Select 
                                    options={assetsOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleReg(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                        </div>  
                        <div className="inlineLnx">
                            <label for='class' className="eCorrection correctionFieldsMobile6">Name <span className="req">(Require)</span></label>
                                <Select 
                                    options={applicantsOptions}
                                    styles={colourStylesS}
                                    onChange={e => this.setState({name: e.value})}
                                    className="correctionFieldsMobile3"
                                /> 
                            </div>
                        <h2 className="subttV">Contravention</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='type' className="eCorrection correctionFieldsMobile5">Type <span className="req">(Require)</span></label>
                                <Select 
                                    options={typeOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleType(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                            <div className="inlineLnI">
                                <label for='currentCost' className="eCorrection correctionFieldsMobile5">Current Cost <span className="req">(Require)</span></label>
                                <input type="number" name='currentCost' className="twoIn2 correctionFieldsMobile5" value={this.state.deposit} onChange={e => handleCost(e)}  />
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='d' className="eCorrection correctionFieldsMobile5">Date <span className="req">(Require)</span></label>
                                {/*<DatePicker selected={this.state.date} onChange={(date) => handleSDate(date)} className="correctionFieldsMobile3"/>*/}
                                <Calendar  
                                    onChange={e => handleSDate(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    className={'calendarX'}
                                />
                            </div>
                            
                            <div className="inlineLnI">
                                <label for='mileage' className="eCorrection correctionFieldsMobile5">Code <span className="req">(Require)</span></label>
                                <Select 
                                    options={codesOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleCode(e)}
                                    className="correctionFieldsMobile4"
                                /> 
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='mileage' className="eCorrection correctionFieldsMobile6">Description <span className="req">(Require)</span></label>
                                <input type="textarea" name='mileage' className="twoIn2 texta correctionFieldsMobile6"  value={this.state.description} onChange={e => handleDescription(e)}  />
                            </div>
                        <h2 className="subttV">Issuing Authority</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnx">
                                <label for='rate' className="eCorrection">Authority <span className="req">(Require)</span></label>
                                <Select 
                                    options={authorityOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleAuth(e)}
                                    
                                /> 
                            </div>
                          
                        </div>
                        <h2 className="subttV">Status</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='supplier' className="eCorrection">Outcome of PCN <span className="req">(Require)</span></label>
                                <form>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appeal Successful - Authority Cancelled" 
                                                    checked={this.state.selectedOption === 'Appeal Successful - Authority Cancelled'} 
                                                    onChange={handleOptionChange} />
                                            <> Appeal Successful - Authority Cancelled</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appeal Successful - Redirected to Driver" 
                                                    checked={this.state.selectedOption === 'Appeal Successful - Redirected to Driver'} 
                                                    onChange={handleOptionChange} />
                                         <> Appeal Successful - Redirected to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appealed - Direct to Authority" 
                                                    checked={this.state.selectedOption === 'Appealed - Direct to Authority'} 
                                                    onChange={handleOptionChange} />
                                        <> Appealed - Direct to Authority</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Appealed - Redirected to Driver" 
                                                    checked={this.state.selectedOption === 'Appealed - Redirected to Driver'} 
                                                    onChange={handleOptionChange} />
                                        <> Appealed - Redirected to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Paid - No Recharge" 
                                                    checked={this.state.selectedOption === 'Paid - No Recharge'} 
                                                    onChange={handleOptionChange} />
                                        <> Paid - No Recharge</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Paid - Recharged to Driver" 
                                                    checked={this.state.selectedOption === 'Paid - Recharged to Driver'} 
                                                    onChange={handleOptionChange} />
                                        <> Paid - Recharged to Driver</>
                                    </label>
                                    </div>
                                    <div className="radio">
                                    <label>
                                        <input type="radio" value="Waiting on Authority Notice" 
                                                    checked={this.state.selectedOption === 'Waiting on Authority Notice'} 
                                                    onChange={handleOptionChange} />
                                        <> Waiting on Authority Notice</>
                                    </label>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                        <h2 className="subttV">Upload</h2>
                        <div className="flexAssets">
                            <div className="inlineLnx">
                                <label for='rate' className="eCorrection">Attach PCN <span className="req">(Require)</span></label>
                                <div className="attach">
                                    
                                    <div>
                                        <input
                                            type="file"
                                            onChange={onFileChange}
                                        />
                                        <button className="button" onClick={onFileUpload}>
                                            Upload!
                                        </button>
                                    </div>
                                    {fileData}
                                </div>
                                
                            </div>
                          
                        </div>
                        <div className="submitButtonAss">
                            <button type="submit" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div> 
                </div>
            </div> 
     
            } 
            </>                   
        )
    }
}