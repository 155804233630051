import React, { Component, useState, useEffect , useRef} from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {QrScanner} from '@yudiel/react-qr-scanner';
import AuthService from "../../services/auth.service";
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@mui/material/Slide';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '@mui/icons-material/Camera';
import { IconButton } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const steps1 = [
    'Select a start date',
    'Complete the van details',
    'Complete the driver details'
  ];
const modelOptions = [];

const classOptions = [
    {value: 'SWB', label: 'SWB'},
    {value: 'MWB', label: 'MWB'},
    {value: 'MWB-A', label: 'MWB-A'},
    {value: 'LWB', label: 'LWB'},
    {value: 'LWB-A', label: 'LWB-A'},
    {value: 'XLWB', label: 'XLWB'}
];

const supplierOptions = [];

const insuranceOptions = [
    {value: 'Business Compare Direct (25+)', label: 'Business Compare Direct (25+)'},
    {value: 'Gray Fleet', label: 'Gray Fleet'},
    {value: 'Insure Fleet', label: 'Insure Fleet'},
];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}
const marks = [
    {value: 0, label: '0'},
    {value: 25, label: '25%'},
    {value: 50, label:'50%'},
    {value: 75, label:'75%'},
    {value: 100, label:'100%'}
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        textAlign: 'center',
      },
      imgBox: {
        maxWidth: "80%",
        maxHeight: "80%",
        margin: "10px"
      },
      img: {
        height: "inherit",
        maxWidth: "inherit",
      },
      input: {
        display: "none"
      }
    }))

const driverOptions = [];
const NewNewHireForm = () => {
    const [ steps, setSteps ] = useState(0)
    const [ open, setOpen ] = useState(false)
    const [ checkSubmit, setCheckSubmit ] = useState('')
    const [ startDate, setStartDate ] = useState('')
    const [ endDate, setEndDate ] = useState("")
    const [ vin, setVin ] = useState("")
    const [ vehicleData, setVehicleData ] = useState("")
    const [ selectedOption, setSelectedOption ] = useState("")
    const [ selectedOptionTwo, setSelectedOptionTwo ] = useState("")
    const [ mileage, setMileage ] = useState("")
    const [ driversData, setDriversData ] = useState("")
    const [ driverOption, setDriverOption ] = useState("")
    const [ phoneNo, setPhoneNo ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ address1, setAddress1 ] = useState("")
    const [ address2, setAddress2 ] = useState("")
    const [ dsp, setDsp ] = useState("")
    const [ county, setCounty ] = useState("")
    const [ postCode, setPostCode ] = useState("")
    const [ town, setTown ] = useState("")
    const [ accountNo, setAccountNo ] = useState("")
    const [ name, setName ] = useState("")
    const [ dlNo, setDlNo ] = useState("")
    const [ dlEx, setDlEx ] = useState("")
    const [ dlIs, setDlIs ] = useState("")
    const [ dob, setDob ] = useState("")
    const [ driverEntry, setDriverEntry ] = useState("")
    const [ currentUser, setCurrentUser ] = useState("")
    const [ notes, setNotes ] = useState("")
    const [ fuel, setFuel ] = useState("")
    const [ clean, setClean ] = useState([])
    const [ reg, setReg ] =useState()
    const [ make, setMake ] = useState()
    const [ model, setModel ] = useState()
    const [ rate, setRate ] = useState("")
    const [ deposit, setDeposit ] = useState("")
    let sigCanvas = useRef({}) // React.createRef({}),
    let sigCanvas1 = useRef({}) //React.createRef({}),
    let sigCanvas2 = useRef({}) //React.createRef({}),
    const [ open1, setOpen1 ] = useState(false)
    const [ source, setSource ] = useState([])
    const [ display, setDisplay ] = useState([])
    const [ indexState, setIndexState ] = useState(0)
    const [ files, setFiles ] = useState([])
    const [ fileCount, setFileCount ] = useState(0)
    const [ qr, setQr ] = useState("")
    const [ warning, setWarning ] = useState([])
    const [ warningData, setWarningData ] = useState([])
    const [ clientDrop, setClientDrop ] = useState("")
    const [ renew, setRenew ] = useState("")
    const [ entry, setEntry ] = useState()
    const [ sig1, setSig1 ] = useState("")
    const [ sig2, setSig2 ] = useState("")
    const [ sig3, setSig3 ] = useState("")
    const [ subBut, setSubBut ] = useState(false);
    const refToTop = useRef(null);
       
     
    useEffect(() =>{
      UserService.getDrivers()
      .then(response => {
         // this.setState({checkSubmit: true})
          setDriversData(response.data) 
      }).catch(err => {
          //this.setState({checkSubmit: false})
          console.log(err)
      })
      UserService.getClientDrop()
      .then(response => {
         // this.setState({checkSubmit: true})
          setClientDrop(response.data) 
          let names = response.data;
          let namesLen = names.length;
          for(let i = 0; i < namesLen; i++){
            driverOptions.push(
              {value: names[i].name, label: names[i].name}
            )
          }
      }).catch(err => {
          //this.setState({checkSubmit: false})
          console.log(err)
      })
      UserService.getWarning()
      .then(response =>{
        console.log(response.data)
        const len = response.data.length
        for(let i = 0; i < len; i++){
            console.log(response.data[i].heading)
            warning.push(<FormControlLabel
                label={response.data[i].heading}
                control={<Checkbox onChange={e => handleWarning(i, e)} className="check"/>}
            />)
        }
        for(let i = 0; i < warning.length; i++ ){
            warningData.push('')
        }
        const handleWarning = (num, e) =>{
            console.log(e.target.checked)
            if(e.target.checked == true){
                warningData[num] = response.data[num].heading
            }else{
                warningData[num] = ''
            }
            console.log(warningData)
        }
      })
    },[])  
        

        const startDateChange = (e) =>{
            
          setStartDate(e)
          setEndDate(new Date(e.getTime()+ 365*86400000))

        }
        const handleNext = () =>{
          setSteps(steps +1)
        }
        const handlePrevious = () =>{
          setSteps(steps-1)
        }
        const handleVin = (e) =>{
            e.preventDefault()
            if(e.target.value.length <= 17){
                console.log(e.target.value)
                setVin(e.target.value.toUpperCase())
            }
        }
        useEffect(() => {
            if(vin.length == 17){
              let dataSend = {vin: vin}
              UserService.getVehicle(dataSend)
              .then(response => {
                    console.log(response.data[0])
                    setReg(response.data[0]?.reg)
                    setMake(response.data[0]?.make)
                    setModel(response.data[0]?.model)
                    setDeposit(response.data[0]?.deposit)
                    setRate(response.data[0]?.rate)
                    setVehicleData(response.data) 
              }).catch(err => {
                    console.log(err)
              })
            }
        }, [vin])
        const user = AuthService.getCurrentUser();
        const fleetUser = user.username;
        const handleOptionChange = (e) =>{
          e.preventDefault()
          setSelectedOption(e.target.value)
  
        }
        const handleOptionChangeTwo = (e) =>{
          e.preventDefault()
          setSelectedOptionTwo(e.target.value)

        }
        const handleMileage = (e) =>{
            e.preventDefault()
          setMileage(e.target.value)
        }
     
        const handleNewDriver = (e) =>{
          let len = driversData.length
          let data = driversData
          for(let i = 0; i < len; i++){
              if(e.value == data[i]?.name){
                    setPhoneNo(data[i].phone_no)
                    setAddress1(data[i].address1)
                    setAddress2(data[i].address2)
                    setEmail(data[i].email)
                    setDsp(data[i].assigned_location)
                    setCounty(data[i].county)
                    setTown(data[i].town)
                    setPostCode(data[i].post_code)
                    setAccountNo(data[i].account_no)
                    setName(data[i].name)
                    setDlNo(data[i].dl_no)
                    setDlIs(data[i].dl_issued)
                    setDlEx(data[i].dl_expiry)
                    setDob(data[i].dob)
                    setEntry(data[i].entry)
                  
              }
          }
          UserService.getEmails()
              .then(response => {
                    let data = response.data;
                    let len = data.length;
                    for(let i = 0; i < len; i++){
                        if(data[i].name == e.value){
                            setEmail(data[i].email)
                        }
                    }
              }).catch(err => {
                  //this.setState({checkSubmit: false})
                  console.log(err)
              })
         
        }
        
        const handleNotes = (e) =>{
            e.preventDefault()
            console.log(e.target.value)
        }
        const handleSubmit = () =>{
            const formData = new FormData();
            let unixToday = new Date().getTime();
            let dataSend = {
            vehicleEntry: vehicleData[0].entry, 
            status: 'Active',  
            reg: vehicleData[0].reg, 
            make: vehicleData[0].make, 
            model: vehicleData[0].model, 
            class: vehicleData[0].class, 
            colour: vehicleData[0].colour, 
            year: vehicleData[0].year, 
            vin: vehicleData[0].vin,
            rate: vehicleData[0].rate, 
            deposit: vehicleData[0].deposit, 
            assignment: 'On-Hire', 
            assigned_client: 'Skilled Solutions LTD', 
            assigned_to: name, 
            assigned_location: dsp, 
            assigned_by: user.username, 
            assigned_date: startDate, 
            end_date: endDate,
            notes: notes, 
            email: email
            }
            setSubBut(true)
            console.log(dataSend)
            
            let dataActivities = {reg: vehicleData[0].reg, 
                heading: 'On Hire', 
                assigned_to: name, 
                assigned_date: startDate, 
                status: 'Active',
                file: vehicleData[0].reg+'_'+unixToday+'.pdf' ,
                fuel: fuel,
                warning_lights: warningData,
                mileage: mileage,
                clean: clean
            }
            console.log(new Date(startDate).toLocaleDateString('en-US'))

            UserService.insertAllocationLog(dataSend)
              .then(response => {
        
              }).catch(err => {
                  console.log(err)
            })
            let dataCheck = {assigned_to: name, assignment: 'Available'}      
            UserService.updateAssetsCheck(dataCheck).then(respones=> console.log(respones)).catch(err => console.log(err))      
            let dataSendAssets = {vehicleEntry: vehicleData[0].entry,status: 'Active', assignment:'On Rent', assigned_to: name, assigned_location: dsp, assigned_date: new Date(startDate).getFullYear()+'-'+(new Date(startDate).getMonth()+1)?.toString().padStart(2, '0')+'-'+new Date(startDate).getDate()?.toString().padStart(2, '0'), assigned_by: user.username} 
            
            UserService.updateAssets(dataSendAssets)
                .then(response => {
                    // this.setState({checkSubmit: true})
                    // this.setState({vehicleData: response.data}) 
            
                }).catch(err => {
                    //this.setState({checkSubmit: false})
                    console.log(err)
            })
            UserService.postActivityNew(dataActivities).then(response => {
            //setSubmit(true)
            }).catch(err => console.log(err))
                formData.append('unixToday', unixToday)
                formData.append('name', name) 
                formData.append('email', email) 
                formData.append( 'ref', new Date().getTime()) 
                formData.append('startDate', startDate)
                formData.append('endDate', endDate)
                formData.append('accountNo', accountNo)
                formData.append('address', address1+ ', '+address2)
                formData.append('town', town)
                formData.append('county', county)
                formData.append('postCode', postCode)
                formData.append('phoneNo', phoneNo)
                formData.append('dlNo', dlNo)
                formData.append('dlEx', dlEx)
                formData.append('dlIs', dlIs)
                formData.append('reg',vehicleData[0].reg) 
                formData.append('make', vehicleData[0].make) 
                formData.append('model', vehicleData[0].model) 
                formData.append('class', vehicleData[0].class) 
                formData.append('vin', vin)
                formData.append('mileage', mileage)
                formData.append('rate', vehicleData[0].rate) 
                formData.append('deposit', vehicleData[0].deposit) 
                formData.append('fuel', fuel)
                formData.append('clean', clean)
                formData.append('userSig', sig1)
                formData.append('clientSig1', sig2)
                formData.append('clientSig2', sig3)
                formData.append('user', fleetUser)
                formData.append('fileCount', fileCount)
                formData.append('warningLights', warningData)
                formData.append('renew', renew)
                formData.append('location', dsp)
            //formData.append('dataPdf', dataPdf)
            for(let i = 0; i < files.length; i++){
                formData.append('file'+i, files[i])
            }
            console.log(formData)
            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            UserService.postOnHire(formData).then(result => {
                setCheckSubmit(true)
                sleep(2000).then(()=>{
                    window.location.reload(false)
                })
            }).catch(err => {
                setCheckSubmit(false)
                console.log(err)
            })
        }

        const handleClean = (ind) =>{
            if(clean[ind]== true){
                clean[ind] = false
            }else{
                clean[ind] = true
            }
        }
        const handleClickOpen = () =>{
            
            setOpen(true)
        }
        useEffect(()=>{
            document.getElementById('scroll')?.scrollIntoView({top: -1, behavior: 'smooth' });
        },[steps == 2])
        const handleClickClose = () =>{
            setSig1(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
            setSig2(sigCanvas1.current.getTrimmedCanvas().toDataURL("image/png"))
            setSig3(sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png"))
            setOpen(false)
        }
        
        const clear1 = () => sigCanvas.current.clear();
        const clear2 = () => sigCanvas1.current.clear();
        const clear3 = () => sigCanvas2.current.clear();
        const handleClose = () => {
            setOpen1(false);
            //console.log(damageType)
            //console.log(files)
        }
        let index = 0;
        const handleOpen = () => {
            setSource([])
            setOpen1(true);
            setDisplay([])
            setIndexState(0)
            setFileCount(0)
            //setDamageType([])
            //setFiles([])
            //setDamageDimentions([])
        };
        const handleBox = (index) =>{
            console.log(index)
            /*setDisplay(oldDisplay => {
                return oldDisplay.filter(element => element !== oldDisplay[index])
            })*/
            //delete damageType[index]
           
                let damageLet = [] 
                display.map((element, key) =>{
                    console.log(index)
                    if(index !== key){
                        damageLet.push(element)
                    }else{
                        damageLet.push('')
                    }
                })
                setDisplay(damageLet)
            }
        
        
        const handleCapture = (target) => {
            //setFiles(oldFiles =>[...oldFiles, target.files[0]])
            setFiles(oldFiles => [...oldFiles, target.files[0]])   
            index = indexState
            if (target.files) {
                if (target.files.length !== 0) {
                    const file = target.files[0];
                    console.log(file)
                    const newUrl = URL.createObjectURL(file);
                    console.log(newUrl)
                    //setSource(oldSource =>[...oldSource, newUrl]);
                    source.push(newUrl)
                        let element = <div className="flex"  key={index}>
                                            <div className="pictureFrame">
                                            <Box display="flex" justifyContent="center" border={1} marginLeft={300} >
                                                <img src={newUrl} alt={"snap"} className="picture"></img>
                                            </Box>
                                        </div>
                                            {/*<IconButton
                                                className="buttonDelete"
                                                onClick={e => handleBox(index)} ><DeleteForeverIcon  sx={{fontSize: 80, color: 'rgb(214, 109, 109)'}}/>
                                        </IconButton>*/}
                                        </div>
                        const data =  [...display, element]                        
                        setDisplay(data)
                        setIndexState(indexState+1)
                        setFileCount(fileCount+1)                    
                }
            }
           
        };

        
       
        return(
            <>
            <div id="scroll"></div>
            {checkSubmit ? <h1 className="assetsOutcome">New hire done!!</h1> : checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div ref={refToTop}></div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Hew Hire</h1>
                    </header> 
                    <Box sx={{ width: '100%', fontSize: 'x-large' }} className="correctionFieldsMobile">
                        <Stepper activeStep={steps} alternativeLabel>
                            {steps1?.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Box>   
                    <div>
                        <> 
                        {steps == 0?    
                          <h2 className="subttV">New Hire Date</h2>
                          : steps == 1 ?
                          <h2 className="subttV">Vehicle Details</h2>
                          : <h2 className="subttV">Driver Details</h2>
                        }
                        </>
                        <>
                        {steps == 0?
                        <div className="flex">
                        <p className="req">*The end date will be sellected automatically 90 days after the start date.</p>   
                            <div className="calendar inlineLnI">
                              <label for='calen' className="eCorrection">Start Date <span className="req">(Require)</span></label>
                              <Calendar  
                              onChange={e => startDateChange(e)} 
                              //value={new Date()} 
                              validRange = {{
                                  start: new Date(),
                                  end: null
                              }}
                              />
                            </div>
                            <div className="calendar inlineLnI">
                              <label for='calen' className="eCorrection">End Date <span className="req">(Require)</span></label>
                              <Calendar  
                              //onChange={e => dateChange(e)} 
                              value={endDate} 
                              validRange = {{
                                  start: new Date(),
                                  end: null
                              }}
                              />
                            </div>
                        </div>: steps == 1 ?
                        <>
                        <div className="scanner">
                            <QrScanner
                                onDecode={(result) => setVin(result)}
                                onError={(error) => console.log(error?.message)}
                            />
                        </div>
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Vin <span className="req">(Require)</span></label>
                              <input type="text" name='notes' className="twoIn2 correctionFieldsMobile" onChange={e => handleVin(e)} value={vin} />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Reg <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile3" value={reg} /*onChange={e => handleRate(e)}*/  />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Make <span className="req">(Require)</span></label>
                                <input type="text" name='deposit' className="twoIn2 correctionFieldsMobile3" value={make} /*onChange={e => handleDeposit(e)}*/  />
                            </div>
                        </div>
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Model <span className="req">(Require)</span></label>
                              <input type="text" name='notes' className="twoIn2 correctionFieldsMobile" /*onChange={e => handleVin(e)}*/ defaultValue={model} />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Rate <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile3" value={rate} /*onChange={e => handleRate(e)}*/  />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Deposit <span className="req">(Require)</span></label>
                                <input type="text" name='deposit' className="twoIn2 correctionFieldsMobile3" value={deposit} /*onChange={e => handleDeposit(e)}*/  />
                            </div>
                        </div> 
                        <div className="inlineLnx">
                              <label for='notes' className="eCorrection">Current Mileage <span className="req">(Require)</span></label>
                              <input type="number" name='notes' className="twoIn2 correctionFieldsMobile" onChange={e => handleMileage(e)}  />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI correctionFieldsMobile">
                                <label for='reg' className="eCorrection">Fuel Level <span className="req">(Require)</span></label>
                                <Slider
                                aria-label="Custom marks"
                                defaultValue={0}
                                //getAriaValueText={valuetext}
                                step={1}
                                //valueLabelDisplay={valuetext}
                                onChange={e => setFuel(e.target.value)}
                                marks={marks}
                                />
                            </div>
                            <div className="inlineLnI correctionFieldsMobile">
                
                                <label for='make' className="eCorrection">Vehicle Cleanliness <span className="req">(Require)</span></label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                <FormControlLabel
                                    label="Outside not clean"
                                    control={<Checkbox checked={clean[0]} onChange={e => handleClean(0)} className="check"/>}
                                />
                                <FormControlLabel
                                    label="Inside not clean"
                                    control={<Checkbox checked={clean[1]} onChange={e => handleClean(1)} className="check"/>}
                                />
                                <FormControlLabel
                                    label="Cargo area not clean"
                                    control={<Checkbox checked={clean[2]} onChange={e => handleClean(2)} className="check"/>}
                                />
                                </Box>
                            </div>
                        </div> 
                        <div className="flex">
                            <div className="inlineLnI correctionFieldsMobile correctionX">
                                    <label for='make' className="eCorrection">Warning Lights <span className="req">(Require)</span></label>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                        {warning}
                                    </Box>
                            </div>  
                            <div className="bitMarginY">    
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group"><h3 className="eCorrection">Is this a renew?<span className="req">(Require)</span></h3></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        //value={valueRadio3}
                                        onChange={e => setRenew(e.target.value)}
                                    >
                                    <div className='flexForm'>
                                        <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                            '& .MuiSvgIcon-root': {
                                                                                                fontSize: 38,
                                                                                            }}} 
                                                                                            className="check"
                                                                                                />}
                                                                                    sx={{m: 2, fontSize: 22}}            
                                                                                    label="Yes"
                                                                                />
                                        <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                            '& .MuiSvgIcon-root': {
                                                                                                fontSize: 38,
                                                                                            }}} 
                                                                                            className="check"
                                                                                            />} 
                                                                                    sx={{m: 2, fontSize: 22}}         
                                                                                    label="No" />     
                                    </div>                                                                                   
                                    </RadioGroup>
                                </FormControl>
                            </div> 
                           
                        </div>
                       
                        </>: <>
                                
                                <div className="inlineLnx">
                                        <label for='rate' className="eCorrection">New Driver <span className="req">(Require)</span></label>
                                        <Select 
                                            options={driverOptions}
                                            styles={colourStylesS}
                                            onChange={e => handleNewDriver(e)}
                                            
                                        /> 
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='phone' className="eCorrection">Phone No <span className="req">(Require)</span></label>
                                          <input type="text" name='reg' className="twoIn2 correctionFieldsMobile" defaultValue={phoneNo} onChange={e => setPhoneNo(e.target.value)}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='email' className="eCorrection">Email <span className="req">(Require)</span></label>
                                          <input type="text" name='email' className="twoIn2 correctionFieldsMobile" defaultValue={email} onChange={e => setEmail(e.target.value)}  />
                                      </div>
                                </div>
                                <h4 className="subttt">Address</h4>
                                <div className="inlineLnx">
                                      <label for='address1' className="eCorrection">Name/Number and Street <span className="req">(Require)</span></label>
                                      <input type="text" name='address1' className="twoIn2 correctionFieldsMobile" onChange={e => setAddress1(e.target.value)} defaultValue={address1} />
                                </div>
                                <div className="inlineLnx">
                                      <label for='address2' className="eCorrection">Address Line 2 </label>
                                      <input type="text" name='address2' className="twoIn2 correctionFieldsMobile" onChange={e => setAddress2(e.target.value)} defaultValue={address2} />
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='town' className="eCorrection">Town/City <span className="req">(Require)</span></label>
                                          <input type="text" name='town' className="twoIn2 correctionFieldsMobile3" defaultValue={town} onChange={e => setTown(e.target.value)}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='county' className="eCorrection">County/State <span className="req">(Require)</span></label>
                                          <input type="text" name='county' className="twoIn2 correctionFieldsMobile3" defaultValue={county} onChange={e => setCounty(e.target.value)}  />
                                      </div>
                                </div>
                                <div className="inlineLnA">
                                    <label for='postCode' className="eCorrection">Zip/Postal Code <span className="req">(Require)</span></label>
                                    <input type="text" name='postcode' className="twoIn2 correctionFieldsMobile3" defaultValue={postCode} onChange={e => setPostCode(e.target.value)}  />
                                </div>
                                <h4 className="subttt">Additional Information</h4>
                                <div className="inlineLnx">
                                      <label for='dlNo' className="eCorrection">Driving Licence Number <span className="req">(Require)</span></label>
                                      <input type="text" name='dlNo' className="twoIn2 correctionFieldsMobile" onChange={e => setDlNo(e.target.value)} defaultValue={dlNo} />
                                </div>
                                <div className="flexAssets">
                                <div className="inlineLnI">
                                        <label for='dlIs' className="eCorrection">Driving Licence Issuing<span className="req">(Require)</span></label>
                                        <input type="text" name='dlIs' className="twoIn2 correctionFieldsMobile3" onChange={e => setDlIs(e.target.value)} defaultValue={dlIs} />
                                    </div>
                                    <div className="inlineLnI">
                                        <label for='dlExp' className="eCorrection">Driving Licence Expiry <span className="req">(Require)</span></label>
                                        <input type="text" name='dlExp' className="twoIn2 correctionFieldsMobile3" onChange={e => setDlEx(e.target.value)} defaultValue={dlEx} />
                                    </div>
                                </div>
                                <div className="flexAssets">
                                      <div className="inlineLnI">
                                          <label for='none' className="eCorrection">Dob<span className="req">(Require)</span></label>
                                          <input type="text" name='none' className="twoIn2 correctionFieldsMobile3" defaultValue={dob} onChange={e => setDob( e.target.value)}  />
                                      </div>
                                      <div className="inlineLnI">
                                          <label for='station' className="eCorrection">Station <span className="req">(Require)</span></label>
                                          <input type="text" name='station' className="twoIn2 correctionFieldsMobile3" defaultValue={dsp} onChange={e => setDsp(e.target.value)}  />
                                      </div>
                                </div>
                                <h4 className="subttt">Notes</h4>
                                <div className="inlineLnx">
                                      <textarea rows="6" name='dlNo' className="multipleLines correctionFieldsMobile" onChange={e => setNotes(e.target.value)} /*value={dlNo}*/ />
                                </div>
                            </>
                        }
                        </>        
                   
                        <>
                        {steps == 2?
                          <div className="flexo flexoCorrection">
                            <div className="submitButtonAss">
                                    <button type="button" className="submitButtonnAss" onClick={handleClickOpen}>Declarations</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                    <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                {
                                    sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' ||
                                    sigCanvas1.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' || 
                                    sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png") == 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC' ?
                                    <button type="button" className="submitButtonnAssX">Submit</button>
                                :subBut == false ? <button type="button" className="submitButtonnAss" onClick={handleSubmit}>Submit</button> : <button type="button" className="submitButtonnAss"><CircularProgress/></button>}
                            </div>
                          </div>
                          : steps !== 0 && steps !== 2 ? 
                          <div className="flexo flexoCorrection">
                            <div className="submitButtonAss">
                                  <button type="button" className="submitButtonnAss" onClick={handleOpen}>Take Pictures</button>
                            </div>
                            <div className="spaceing"></div>
                            <div className="submitButtonAss">
                                  <button type="button" className="submitButtonnAss" onClick={handlePrevious}>&#60;previous</button>
                            </div>
                            <div className="spaceing"></div>
                            
                            <div className="submitButtonAss">
                            {fileCount < 4 ? <button type="button" className="submitButtonnAssL" >next&gt;</button>: <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>}
                            </div>
                            
                          </div>: endDate !== '' && startDate ?
                          <div className="submitButtonAss">
                              <button type="button" className="submitButtonnAss" onClick={handleNext}>next&gt;</button>
                          </div>:
                            <div className="submitButtonAss">
                                <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>next&gt;</button>
                            </div>
                          }
                        </>
                    </div> 
                </div>
            </div> 
     
            } 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div /*className={classes.root}*/>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className="declarations">
                                            <h3 className="titleZ">Fleet Representative Declaration</h3>
                                            <p className="dec">I have issued the vehicle {vehicleData[0]?.reg} to the hirer and it meets the requirements as set out our terms & conditions as seen by myself and I have explained all the requirements to the hirer.</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={sigCanvas}
                                                //onChange={console.log(sigCanvas)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear1}>Clear</button>
                                            <br></br><h3 className="titleZ">Client Declarations</h3>
                                            <h4 className="subtitle">Terms of Hire</h4>
                                            <p className="dec">I accept and agree to abide to all the terms & conditions as set out by the supplier which is published on the suppliers website and that these terms maybe updated with written notice (by email).</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={sigCanvas1}
                                                //onChange={console.log(sigCanvas1)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear2}>Clear</button>
                                            
                                            <p className="dec">{fleetUser} has explained or demonstrated to me the following points of which I agree to follow as part of the vehicle rental agreement.</p>
                                            <p className="dec">1, How to use the vehicle tracking system and that I must ensure the vehicle is locked securely at all times.</p>
                                            <p className="dec">2, Where the AdBlue system and the importance of keeping Ad-Blue topped up and not ignoring any messages on the vehicle display if the vehicle uses Ad-Blue.</p>
                                            <p className="dec">3, To follow road markings and not to ignore parking restrictions, this includes Rotherhithe Tunnel which commercial vehicles are not allowed to use, any charge notices that occur as a result of my behaviour will be recharged or redirected and I am liable for any costs incurred.</p>
                                            <p className="dec">4, The vehicle is listed on an account for the London Congestion Zone and the Dartford Crossing/A282 and the associated fee’s for entering these will be recharged to myself.</p>
                                            <p className="dec">5, In the event of an accident/emergency and/or damage I am to call the supplier unless it is better in my judgement to call 999 first.</p>
                                            <p className="dec">6, The fee for renting the vehicle is £{vehicleData[0]?.rate} and the vehicle’s deposit is £{vehicleData[0]?.deposit}. The weekly rate is chargeable from the point I collect the vehicle to the point it is returned back to the supplier and in no way discounted on how many working days for other companies I supply services to.</p>
                                            <h5>Signature</h5>
                                            <SignatureCanvas 
                                                penColor='black'
                                                canvasProps={{className: 'sigCanvas'}}
                                                ref={sigCanvas2}
                                                //onChange={console.log(sigCanvas2)}
                                                className="correctionFieldsMobile"
                                            />
                                            <br></br><button className='buttonOff' onClick={clear3}>Clear</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>                          
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className="buttonPictures" onClick={handleClickClose}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={open1}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div >
                    <Grid container>
                        <Grid item xs={12}>

                            <div className="pictures">
                                <div className="pictureFrame">   
                                    {display.length > 0 ? display : ''}
                                </div> 
                                <input
                                    accept="image/*"
                                    //className={classes.input}
                                    style={{display: 'none'}}
                                    id="icon-button-file"
                                    type="file"
                                    capture="environment"
                                    onChange={(e) => handleCapture(e.target)}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    >
                                    <CameraIcon sx={{fontSize: 80}} color="primary" />
                                    </IconButton>
                                </label>
                        
                            </div>
                        </Grid>
                    </Grid>
                    </div>                          
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className="buttonPictures" onClick={handleClose}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            </>                   
        )
    
}
export default NewNewHireForm;