import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import UserService from '../../services/user.service';


const OffTrack = () => {
 const [searchInput, setSearchInput] = useState("");
 const [ data, setData ] =useState();
 //let url = '/videoDataToTable';
 //let data =  ''//useFetch(url);
 useEffect(()=>{
    UserService.getOffTrack().then(
        response => {
            setData(response.data)
        }
    )
 },[])

 console.log(data);
 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      headerClassName: 'table-header',
      width: 220,
      editable: false,
    },
    {
      field: 'agreement_no',
      headerName: 'Agreement No',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
    },
   {
      field: 'return_date',
      headerName: 'Return Date',
      headerClassName: 'table-header',
      width: 150,
      editable: false,
    },
    {
       field: 'name',
       headerName: 'Name',
       headerClassName: 'table-header',
       width: 300,
       editable: false,
     },
     {
        field: 'reg',
        headerName: 'Registration',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
      },
      {
         field: 'make',
         headerName: 'Vehicle make',
         headerClassName: 'table-header',
         width: 180,
         editable: false,
       },
       {
          field: 'model',
          headerName: 'Vehicle model',
          headerClassName: 'table-header',
          width: 180,
          editable: false,
        },
        {
           field: 'fuel_level',
           headerName: 'Fuel level',
           headerClassName: 'table-header',
           width: 180,
           editable: false,
         },
         {
            field: 'cleanliness',
            headerName: 'Cleanliness',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'dash_lights',
            headerName: 'Warning lights',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'damages_windscreen',
            headerName: 'Damages windscreen',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
          {
            field: 'damages_interior',
            headerName: 'Damages interior',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'damages_exterior',
            headerName: 'Damages exterior',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'hirer_present',
            headerName: 'Hirer present',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'off_hired_by',
            headerName: 'Off-hired by',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'at_fault',
            headerName: 'At fault',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'amount',
            headerName: 'Amount',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'invoice_raised',
            headerName: 'Invoice raised',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'driver_aware',
            headerName: 'Driver aware',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'paiment_plan',
            headerName: 'Payment plan',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'accounts_aware',
            headerName: 'Accounts aware',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          },
          {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 180,
            editable: false,
          }

  ];
 

let displaiC = [];
if(data){
  console.log(data);
data?.map(dataRow => {
    
    displaiC.push(
        {id: dataRow.line, timestamp: dataRow.timestamp.split('T')[0].split('-')[2]+'-'+dataRow.timestamp.split('T')[0].split('-')[1]+'-'+dataRow.timestamp.split('T')[0].split('-')[0]+' - '+dataRow.timestamp.split('T')[1].split('.')[0], agreement_no: dataRow.agreement_no , return_date: dataRow.return_date, name: dataRow.name, reg: dataRow.reg, make: dataRow.make, model: dataRow.model, fuel_level: dataRow.fuel_level, cleanliness: dataRow.cleanliness, dash_lights: dataRow.dash_lights, damages_windscreen: dataRow.damages_windscreen, damages_interior: dataRow.damages_interior, damages_exterior: dataRow.damages_exterior, hirer_present: dataRow.hirer_present, off_hired_by: dataRow.off_hired_by, at_fault: dataRow.at_fault, amount: dataRow.amount, invoice_raised: dataRow.invoice_raised, driver_aware: dataRow.driver_aware, payment_plan: dataRow.payment_plan, accounts_aware: dataRow.accounts_aware, status: dataRow.status }
    )   

    })
  }

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(e.target.value);
};
console.log(searchInput);
let findC = [];
if (searchInput.length > 0) {
    data.filter(dataRow => {
        if(dataRow.reg?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.name?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.agreement_no?.toUpperCase().match(searchInput.toUpperCase())){
            console.log(findC)
            findC.push(
                {id: dataRow.line, timestamp: dataRow.timestamp.split('T')[0].split('-')[2]+'-'+dataRow.timestamp.split('T')[0].split('-')[1]+'-'+dataRow.timestamp.split('T')[0].split('-')[0]+' - '+dataRow.timestamp.split('T')[1].split('.')[0], agreement_no: dataRow.agreement_no , return_date: dataRow.return_date, name: dataRow.name, reg: dataRow.reg, make: dataRow.make, model: dataRow.model, fuel_level: dataRow.fuel_level, cleanliness: dataRow.cleanliness, dash_lights: dataRow.dash_lights, damages_windscreen: dataRow.damages_windscreen, damages_interior: dataRow.damages_interior, damages_exterior: dataRow.damages_exterior, hirer_present: dataRow.hirer_present, off_hired_by: dataRow.off_hired_by, at_fault: dataRow.at_fault, amount: dataRow.amount, invoice_raised: dataRow.invoice_raised, driver_aware: dataRow.driver_aware, payment_plan: dataRow.payment_plan, accounts_aware: dataRow.accounts_aware, status: dataRow.status }
            )
        }
});

}

window.onscroll = function (){
  console.log(document.body.scrollLeft)
  document.getElementById('availableOffTitleB').style.marginLeft = 1 + (document.documentElement.scrollLeft + document.body.scrollLeft)/50+"%";
}


return( 
  <div className='conatainerB'>
    <div className='flexB'>
      <input
        type="search"
        placeholder="Search here"
        onChange={handleChange}
        value={searchInput}
        className='searchInputB' 
        /><h3 className='availableOffTitleB' id='availableOffTitleB'>Off Hire Tracker</h3>
    </div>

  {findC.length > 0 || searchInput ? 
      <Box sx={{ height: 1300, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
        bgcolor: (theme) =>
          theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
      }}}>
          <DataGrid
          rows={findC}
          columns={columns}
          initialState={{
              pagination: {
              paginationModel: {
                  pageSize: 100,
              },
              },
          }}
          pageSizeOptions={[15]}
          sx={{fontSize: 'larger'}}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        //loading
          //{...data}
          />
      </Box> : <Box sx={{height: 1300, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
          }}}>
          <DataGrid
          rows={displaiC}
          columns={columns}
          initialState={{
              pagination: {
              paginationModel: {
                  pageSize: 100,
              },
              },
          }}
          pageSizeOptions={[15]}
            sx={{fontSize: 'larger'}}
          //{...data}
          />
      </Box>}
  </div>
)

};

export default OffTrack;