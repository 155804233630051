import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const UnavailableBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let data =  props.table_data_un;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;

console.log(data)
 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'reg',
      headerName: 'Reg',
      headerClassName: 'table-header',
      width: 110,
      editable: false,
    },
    {
      field: 'make',
      headerName: 'Make',
      headerClassName: 'table-header',
      width: 120,
      editable: false,
    },
   {
      field: 'model',
      headerName: 'Model',
      headerClassName: 'table-header',
      width: 120,
      editable: false,
    },
    {
       field: 'class',
       headerName: 'Class',
       headerClassName: 'table-header',
       width: 105,
       editable: false,
     },
     {
        field: 'supplier_name',
        headerName: 'Supplier Name',
        headerClassName: 'table-header',
        width: 180,
        editable: false,
      },
     {
        field: 'tax_due',
        headerName: 'Tax Due',
        headerClassName: 'table-header',
        width: 110,
        editable: false,
        
      },
      {
        field: 'mot_due',
        headerName: 'MOT Due',
        headerClassName: 'table-header',
        width: 110,
        editable: false,
        
      },
      {
        field: 'assignment',
        headerName: 'Assignment',
        headerClassName: 'table-header',
        width: 110,
        editable: false,
        
      },
      {
        field: 'notes',
        headerName: 'Comments',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
        
      },
      ,
     /*{
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            console.log(params)
            UserService.updateNotes(params.value, params.row.id)
            //handleOC(params.value, params.row.id)
            return params.value
          }
      },*/
    ]
let displaiC = [];
if(data){
data?.map(dataRow => {
  
        displaiC.push(
                {id: dataRow.entry, reg: dataRow.reg, make: dataRow.make , model: dataRow.model, class: dataRow.class, supplier_name:dataRow.supplier_name, tax_due: dataRow?.tax_due?.split('-')[2] ? dataRow.tax_due?.split('-')[2]+'-'+dataRow.tax_due?.split('-')[1]+'-'+dataRow.tax_due?.split('-')[0] : '', mot_due: dataRow?.mot_due?.split('-')[1] ? dataRow.mot_due?.split('-')[1]+'-'+dataRow.mot_due?.split('-')[0] : '', assignment: dataRow.assignment, notes: dataRow.notes}
        ) 

    })
  }

const handleChange = (e) => {
  
  e.preventDefault();
  setSearchInput(document.getElementById('searchInputUn').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {

        if(dataRow.reg?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.make?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.class?.toUpperCase().match(searchInput.toUpperCase() )|| dataRow.supplier_name?.toUpperCase().match(searchInput.toUpperCase()) ){
            findC.push(
                {id: dataRow.entry, reg: dataRow.reg, make: dataRow.make , model: dataRow.model, class: dataRow.class, supplier_name:dataRow.supplier_name, tax_due: dataRow?.tax_due?.split('-')[2] ? dataRow.tax_due?.split('-')[2]+'-'+dataRow.tax_due?.split('-')[1]+'-'+dataRow.tax_due?.split('-')[0] : '', mot_due: dataRow?.mot_due?.split('-')[1] ? dataRow.mot_due?.split('-')[1]+'-'+dataRow.mot_due?.split('-')[0] : '', notes: dataRow.notes}
            )
        }
            
        
});

}
let dataH = {};
const handleCellEdit = (params) =>{
  console.log(params);
  dataH = {entry: params.id, notes: params.notes || null}
  console.log(dataH)
    UserService.updateUnavailable(dataH)
    setTimeout(()=>{
      window.location.reload(true)
    }, 200)
   return params;
}
return( 
<div>
    
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInputUn'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
       <h3 className='availableOffTitle'>Unavailable</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>: <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>}
    
</div>
)

};

export default UnavailableBar;