import React, { Component } from "react";
import UserService from "../services/user.service";
import main from './img/frontMain.jpg';
import pic1 from './img/pic1.png'; 
import pic2 from './img/pic2.png';
import pic3 from './img/pic3.png';
import pic4 from './img/pic4.png';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Home =()=> {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  
    return (
      <>
        <div className="col-md-12 containerhCorrection" style={{backgroundImage: `linear-gradient(to left, rgba(255, 74, 0, 1),transparent), url(${main})`, height: '600px', width: '100%', left: '0 !important', textAlign:'right', backgroundSize: 'cover'}}>
          <h1 className="white">Commercial Vehicle</h1>
          <h1 className="white">Solutions</h1>
          <p className="white" style={{marginLeft: '66%'}}>We provide a variety of solutions including commercial vehicle hire (up to 3.5t), Fleet Management Platforms, Vehicle Tracking and more.</p>

        </div>
        <div className="center">
          <h2 className="mainTit">Explore Our Services</h2>
          <div className="correcting">
            <div className="mobileCor line">
              <img
                src={pic1}
                alt="profile-img"
                className="pics"
              />
            </div>
            <div className="left mobileCor">
              <h3>Fleet Management Solutions</h3>
              <p>Running a fleet of vehicles can be a stressful task, with our dedicated platforms you can easily take control of the day to day operations.</p>
              <br></br>
              <p>&gt; Daily Vehicle Checks</p>
              <p>&gt; Detailed Vehicle information verified by DVLA</p>
              <p>&gt; Driver Behaviour & Coaching</p>
            </div>
          </div>
          <div className="correcting">
            <div className="left correcting1">
              <h3>Vehicle Telematics</h3>
              <p>We have a wide range of tracking systems that show live data
              for the vehicle. The data seen can show journey data, driver
              behaviour, fuel efficiency and vehicle information. This aids
              loss prevention as well depending on the service level – you
              can identify risks such as idle history, towing events and in
              the unfortunate case a vehicle is stolen there are
              immobilisation options and preventative measures possible </p>
            </div>
            <div>
              <img
                src={pic2}
                alt="profile-img"
                className="pics2"
              />
            </div>
          </div>
          <div className="correcting">
            <div className="mobileCor line">
              <img
                src={pic3}
                alt="profile-img"
                className="pics"
              />
            </div>
            <div className="left mobileCor">
              <h3>Vehicle Supply</h3>
              <p>Whether short term or long term we have fantastic rates
              available for all commercial vehicles up to 3.5t</p>
            </div>
          </div>
          <div className="correcting">
            <div className="left correcting1">
              <h3>Vehicle Service and Repair</h3>
              <p>Using our trusted network of mechanics and body work
              specialists we are able to provide high quality work at fair
              prices</p>
            </div>
            <div>
              <img
                src={pic4}
                alt="profile-img"
                className="pics2"
              />
            </div>
          </div>
        </div>
        <div className="faq">
          <h3>Frequently Asked Questions</h3>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{width: '100%'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              
            >
              <Typography sx={{ width: '90%', flexShrink: 0, fontWeight: 700 }}>
                What is the shortest duration I can rent a van for?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Most Rentals are 28 day rolling contracts right up to 6month and 1 year contracts – these include some aspect of maintenance included (see t&c’s)
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 , fontWeight: 700}}>Can I track my vehicles easily?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, once logged in to the portal you will be able to easily see a live location of your vehicles.  You can even set up location alerts so that if vehicles pass a certain area you will receive a notification.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 , fontWeight: 700}}>
                Do you out source your vehicle repairs?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We use trusted companies that we have a working history with that we know provide high quality work
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0, fontWeight: 700 }}>What's involved if I wanted help with my fleet management?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We would initially offer a 30min consultation and ask you to complete a Q&A form which we then use to put 3 proposals together on how and what we can offer, from theres its down to you to select which one you go with.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    );
  
  
}
export default Home;