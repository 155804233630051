const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 100 },
    {
      field: 'reg',
      headerName: 'Reg',
      headerClassName: 'table-header',
      width: 280,
      editable: false,
    },
    {
      field: 'driver',
      headerName: 'Driver',
      headerClassName: 'table-header',
      width: 280,
      editable: false,
    },
   {
      field: 'startTime',
      headerName: 'Start Timestamp',
      headerClassName: 'table-header',
      width: 280,
      editable: false,
    },
    {
       field: 'startCoordinates',
       headerName: 'Start Coordinates',
       headerClassName: 'table-header',
       width: 280,
       editable: false,
     },
     {
        field: 'endTime',
        headerName: 'End Timestamp',
        headerClassName: 'table-header',
        width: 280,
        editable: false,
      },
     {
        field: 'endCoordinates',
        headerName: 'End Coordinates',
        headerClassName: 'table-header',
        width: 280,
        editable: false,
        
      },
      {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 280,
        editable: false,
        
      },
      /*{
        field: 'statusUpdate',
        headerName: 'Status updated to',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
        
      }*/
    ]
    export default columns;