import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const OffUpdate = () =>{
    const [ data, setData ] = useState()
    const [ make, setMake ] = useState()
    const [ model, setModel] = useState()
    const [ hiredTo, setHiredTo ] = useState()
    const [ offDate, setOffDate ] = useState()
    const [ refOption, setRefOption ] = useState([])
    const [ refNo, setRefNo ] = useState()
    const [ reason, setReason ] = useState()
    const [ fault, setFault ] = useState()
    const [ amount, setAmount ] = useState()
    const [ iRaised, setIRaised ] = useState()
    const [ dAware, setDAware ] = useState()
    const [ payPlan, setPayPlan ] = useState()
    const [ gAware, setGAware ] = useState()
    const [ status, setStatus ] = useState()
    const [ check, setCheck ] = useState()

    useEffect(()=>{
        UserService.getOffTrack().then(
            response => setData(response.data)
        )
    },[])

    useEffect(()=>{
        const len = data?.length
        for(let i = 0; i < len; i++){
            setRefOption(oldRefOption => [...oldRefOption, {value: data[i].agreement_no, label: data[i].agreement_no}])
        } 
        console.log(refOption)
    },[data])
    const handleChange = (e) =>{
        console.log(e.value)
        data?.map(row =>{
            if(row.agreement_no == e.value){
                setRefNo(e.value)
                setMake(row.make)
                setModel(row.model)
                setHiredTo(row.name)
                setOffDate(row.return_date)
            }
        })
    }
    const handleUpdate = () => {
        const updateData = {agreement_no: refNo, at_fault: fault, amount: amount, invoice_raised: iRaised, driver_aware: dAware, payment_plan: payPlan, accounts_aware: gAware, status: status}
        console.log(updateData)
        UserService.updateOffTrack(updateData).then(
            response => {
                if(response.status == 200){
                    setCheck(true)
                }
            }
        ).catch(err => setCheck(false))
    }
    return(
        <>
        {check == true ? <h1 className="assetsOutcome">Data has been updated!!</h1> : check == false ?<h1 className="assetsOutcome">Something went wrong!!</h1> :

    
        <div>
            <div className="containerf containerfCorrection">
                <header className="jumbotronl">
                    <h1 className="collectionTitle">Off Hire Tracker Update</h1>
                </header> 
                <h2 className="subttV">Agreement Details</h2> 
                <div className="inlineLnx">
                    <label for='agreement' className="eCorrection correctionFieldsMobile6">Ref No <span className="req">(Require)</span></label>
                        <Select 
                            options={refOption}
                            styles={colourStylesS}
                            onChange={e => handleChange(e)}
                            className="correctionFieldsMobile3"
                        /> 
                </div>
                <div className="flexAssets">
                    <div className="inlineLnI">
                        <label for='make' className="eCorrection correctionFieldsMobile5">Make <span className="req">(Require)</span></label>
                        <input type="text" name='make' className="twoIn2 correctionFieldsMobile5" value={make} onChange={e => setMake(e.target.value)}  />
                    </div>
                    <div className="inlineLnI">
                        <label for='model' className="eCorrection correctionFieldsMobile5">Model <span className="req">(Require)</span></label>
                        <input type="text" name='model' className="twoIn2 correctionFieldsMobile5" value={model} onChange={e => setModel(e.target.value)}  />
                    </div>
                </div>  
                <div className="flexAssets">
                    <div className="inlineLnI">
                        <label for='hired' className="eCorrection correctionFieldsMobile5">Hired To <span className="req">(Require)</span></label>
                        <input type="text" name='hired' className="twoIn2 correctionFieldsMobile5" value={hiredTo} onChange={e => setHiredTo(e.target.value)}  />
                    </div>
                    <div className="inlineLnI">
                        <label for='offDate' className="eCorrection correctionFieldsMobile5">Off Hire Date <span className="req">(Require)</span></label>
                        <input type="text" name='offDate' className="twoIn2 correctionFieldsMobile5" value={offDate} onChange={e => setOffDate(e.target.value)}  />
                    </div>
                </div> 
                <h2 className="subttV">Update Data</h2> 
                <div className="flexAssetsY">
                    <div>    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Reason For Vehicle Return<span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setReason(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="Exchange – Driver Still Active" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Exchange – Driver Still Active"
                                                                        />
                                <FormControlLabel disableTypography value="Return – Driver Offboarded" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Return – Driver Offboarded" />
                                <FormControlLabel disableTypography value="Changing to Owner Driver" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Changing to Owner Driver" />                                            
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMargin">
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Is the Driver at Fault for the Damages(Required) <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setFault(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="No"
                                                                        />
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Yes" />                                          
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="inlineLnx">
                        <label for='cost' className="eCorrection correctionFieldsMobile5">Cost of Damages<span className="req">(Require)</span></label>
                        <input type="number" name='cost' className="twoIn2 correctionFieldsMobile5" value={amount} onChange={e => setAmount(e.target.value)}  />
                </div>
                <div className="flexAssetsY">
                    <div>    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Has an Invoice been raised <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setIRaised(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="No"
                                                                        />
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Yes" />
                                <FormControlLabel disableTypography value="Not Applicable" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Not Applicable" />                                            
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMargin">
                    <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Is the driver aware of the Invoice Amount <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setDAware(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="No"
                                                                        />
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Yes" />
                                <FormControlLabel disableTypography value="Not Applicable" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Not Applicable" />                                            
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="flexAssetsY">
                    <div>    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Is a payment plan set up <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setPayPlan(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="No - Driver Leaving" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="No - Driver Leaving"
                                                                        />
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Yes" />
                                <FormControlLabel disableTypography value="Not Applicable" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Not Applicable" />                                            
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMargin">
                    <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Has Garth been made aware <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setGAware(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="No"
                                                                        />
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Yes" />
                                <FormControlLabel disableTypography value="Not Applicable" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Not Applicable" />                                            
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="flexAssetsY">
                    <div>    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>Current Status <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setStatus(e.target.value)}
                            >
                                <FormControlLabel disableTypography value="Closed" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Closed"
                                                                        />
                                <FormControlLabel disableTypography value="Open" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="Open" />
                                                       
                            </RadioGroup>
                        </FormControl>
                    </div>
                    
                </div>
                
            </div>
            {reason && fault && amount && iRaised && payPlan && gAware && dAware && status && refNo ? <button className="buttonPay" onClick={handleUpdate} >Update Data</button> : <button className="buttonPay" style={{backgroundColor : 'gray'}}>Update Data</button>}
        </div>
        }
        </>
    )
}
export default OffUpdate;