import { useState, useEffect } from "react";

function useFetch(url){
    const [data, setData] = useState();
   let urli = 'https://node.tambor.uk:9000' + url;
    useEffect(() => {
      async function fetchData() {
        const response = await fetch(urli,{
          headers: {
          "Access-Control-Allow-Origin":"*",
          "Accept": "application/json",
          "Content-Type": "application/json"
          }
      })
        const json = await response.json();
        setData(json);
        console.log(json);
      }
      fetchData();
    }, [url]);
  
    return data;
  };
  export default useFetch;