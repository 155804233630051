import React from "react";
import { useState, useEffect, useSearchParams } from "react";
import PDFOffViewer from "./pdfOffhire.component";

const PdfOffhireFrame = () =>{
    const query = new URLSearchParams(window.location.search);
    const fileName = query.get('fileName')
    console.log(fileName)
    return(
        <div>
            <PDFOffViewer file={fileName} />
        </div>
    )
}
export default PdfOffhireFrame;