import React from "react";
import { useState, useEffect } from "react";

const PayConfirmation = () =>{
    return(
        <div className="payConfirmation">
            <h1>Thank you for your payment! You will receive an email shortly with the payment confirmation!</h1>
        </div>
    )
}
export default PayConfirmation;