import React, { Component } from "react";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";


export default class UserHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      waitingContent: "",
      username: ""
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({username: currentUser.username})
    UserService.getOffRoadAssets().then(
      response => {
        this.setState({
          content: response.data,
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getWaitingList().then(
        response => {
          this.setState({
            waitingContent: response.data,
          });
        },
        error => {
          this.setState({
            content2:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          });
  
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
  }

  render() {
    let available = 0;
    let unavailable = 0;
    let content =  this.state.content;
    let waitingContent = this.state.waitingContent;
    let ownerWaiting = 0;
    let rentalWaiting = 0;

    for(let i =0 ; i < waitingContent.length; i++){
        if(waitingContent[i].type == 'Rent from Company'){
            rentalWaiting += 1
        }else{
            ownerWaiting += 1
        }
    }

    for(let i = 0; i < content.length; i++){
        if(content[i].assignment == 'Available'){
            available += 1
        }
    }
    for(let i = 0; i < content.length; i++){
        if(content[i].assignment !== 'Available' && content[i].assignment !== 'Inactive' && content[i].assignment !== 'On Rent' && content[i].assignment !== 'Grey Fleet'){
            unavailable += 1
        }
    }

    return (
      <div className="containerB mobileContainerCorrection containerfCorrection">
        <header>
        <h1 className="titlel">
            Hi <strong>{this.state.username?.split(' ')[0]}</strong>
          </h1>
        </header>
        <h3>The following needs your attention</h3>
        <table>
            <tbody>
                <tr>
                    <td className="userTable">Vans Available:</td>
                    <td className="userTable">{available}</td>
                </tr>
                <tr>
                    <td className="userTable">Unavailable Vans:</td>
                    <td className="userTable">{unavailable}</td>
                </tr>
                <tr>
                    <td className="userTable">People waiting for a van:</td>
                    <td className="userTable">{rentalWaiting}</td>
                </tr>
                <tr>
                    <td className="userTable">Owner drivers waiting for trackers:</td>
                    <td className="userTable">{ownerWaiting}</td>
                </tr>
            </tbody>
        </table>
        <p className="reqUser">*For more details please check Allocations-&gt;Off Road Allocations</p>
      </div>
    );
  }
  
}
