import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const WaitingOwnerBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;



 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 20 },
    {
      field: 'timestamp',
      headerName: 'Date/Time Requested',
      headerClassName: 'table-header',
      width: 250,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 250,
      editable: false,
    },
   {
      field: 'contact_no',
      headerName: 'Contact No',
      headerClassName: 'table-header',
      width: 210,
      editable: false,
    },
    {
       field: 'station',
       headerName: 'Station',
       headerClassName: 'table-header',
       width: 208,
       editable: false,
     },
    
     /*{
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            console.log(params)
            UserService.updateNotes(params.value, params.row.id)
            //handleOC(params.value, params.row.id)
            return params.value
          }
      },*/
    ]
let displaiC = [];
if(data){
data?.map(dataRow => {
  
        displaiC.push(
          {id: dataRow.entry, timestamp: dataRow.timestamp?.split(' ')[0]?.split('-')[2]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[1]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[0]+'  '+dataRow.timestamp?.split(' ')[1]?.split(':')[0]+':'+dataRow.timestamp?.split(' ')[1]?.split(':')[1], name: dataRow.name , contact_no: dataRow.contact_no, station: dataRow.station}
        ) 

    })
  }

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput4').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {

        if(dataRow.Name?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Station?.toUpperCase().match(searchInput.toUpperCase()) ){
            findC.push(
                {id: dataRow.entry, timestamp: dataRow.timestamp?.split(' ')[0]?.split('-')[2]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[1]+'-'+dataRow.timestamp?.split(' ')[0]?.split('-')[0]+'  '+dataRow.timestamp?.split(' ')[1]?.split(':')[0]+':'+dataRow.timestamp?.split(' ')[1]?.split(':')[1], name: dataRow.name , contact_no: dataRow.contact_no, station: dataRow.station}
            )
        }
            
        
});

}

return( 
<div style={{width: '900px', marginTop: '3%'}}>
    
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput4'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
       <h3 className='availableOffTitle3'>Owner Tracking Waiting</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        />
    </Box>: <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }}}>
        <DataGrid
        
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        />
    </Box>}
    
</div>
)

};

export default WaitingOwnerBar;