import React from "react";
import { useEffect, useState } from "react";
import {QrScanner} from '@yudiel/react-qr-scanner';
import UserService from "../../services/user.service";
import Select from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';


const statusOption = [
    {value: 'Off Road - Body Shop', label: 'Off Road - Body Shop'},
    {value: 'Off Road - Mechanical', label: 'Off Road - Mechanical'},
    {value: 'Available', label: 'Available'},
    {value: 'Reserved', label: 'Reserved'},
    {value: 'Pending', label: 'Pending'},
    {value: 'Return to Supplier', label: 'Return to Supplier'},
    {value: 'Off Road - CR Required', label: 'Off Road - CR Required'},
]
const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}


const VehicleMovementsForm = () => {
    const [ startCoordinates, setStartCoordinates ] = useState()
    const [ vin, setVin ] = useState()
    const [ driver, setDriver ] = useState()
    const [ warningVin, setWarningVin ] = useState()
    const [ warningDriver, setWarningDriver ] = useState()    
    const [ reg, setReg ] = useState()
    const [ steps, setSteps ] = useState(1)
    const [ startTime, setStartTime ] = useState()
    const [ errorPls, setErrorPls ] = useState()
    const [ status, setStatus ] = useState()
    const [ notes, setNotes ] = useState()
    const [ endTime, setEndTime ] = useState()
    const [ endCoordinates, setEndCoordinates ] = useState()
    const [ loading, setLoading ] = useState(false)
    //setStartCoordinates(position.coords.latitude+', '+position.coords.longitude)
          
    
    const handleVin = (e) =>{
        setWarningVin("")
        if(e.target.value?.length <= 17){
            setVin(e.target.value?.toUpperCase())
        }
        if(e.target.value?.length == 17){
            let data = {vin: e.target.value}
            UserService.validateVin(data).then(
                response =>{
                    if(response.data?.reg){
                        setReg(response.data?.reg)
                    }else(setWarningVin("van not in database"))
                }
            ).catch(err => {
                setWarningVin(err.message)
            })
        }
    }
    const handleDriver = (e) =>{
        setDriver(e.target.value)
    }
    const handleReg = (result) =>{
        setWarningVin("")
        let data = {vin: result}
        UserService.validateVin(data).then(
            response =>{
                if(response.data?.reg){
                    setReg(response.data?.reg)
                }else(setWarningVin("van not in database"))
            }
        ).catch(err => setWarningVin(err.message))
        setVin(result)
    }
    useEffect(() =>{
        if(driver?.length > 0){
            setWarningDriver("")
        }
    }, [driver])
   
    const handleStart = () =>{
        navigator.geolocation.getCurrentPosition(
            position =>{
                let date = new Date()
                setStartTime(date)
                if(reg && driver ){
                    setStartCoordinates(position.coords?.latitude+', '+position.coords?.longitude)
                    let data = {reg: reg, name: driver, start_time: date, start_coordinates: position.coords?.latitude+', '+position.coords?.longitude}
                    console.log(data)
                    UserService.startTrip(data).then(
                        response =>{
                            setSteps(steps+1)
                        }
                    ).catch(err => setErrorPls(err.message))

                }else{
                    //console.log(driver)
                    if(!reg){
                        setWarningVin("valid vin required")
                    }
                    if(!driver){
                        setWarningDriver("driver required")
                    }
                }
            } 
        )
    }
    const handleEnd = () =>{
        setLoading(true)
        navigator.geolocation.getCurrentPosition(
            position =>{
                let date = new Date()
                setEndCoordinates(position.coords?.latitude+', '+position.coords?.longitude)
                setEndTime(date)
                let data = {reg: reg, driver: driver, start_time: startTime, end_time: date, end_coordinates: position.coords?.latitude+', '+position.coords?.longitude, status: status, notes: notes}
                UserService.endTrip(data).then(
                    response =>{
                        setSteps(steps+1)
                        setLoading(false)
                        console.log(response.data)
                    }
                ).catch(err => console.log(err))
            }
        )
    }
    return(
        <>

            {steps == 1? 
                <div style={{marginTop:'100px', marginLeft: '20px'}}>
                <h1 className="collectionTitle" style={{color: 'gray'}}>Vehicle Movement</h1>
                <div >
                    <QrScanner
                        onDecode={(result) => handleReg(result)}
                        onError={(error) => console.log(error?.message)}
                    />
                </div>
                <div className="inlineLnx">
                    <label for='notes' className="eCorrection">Vin <span className="req">(Require)</span></label>
                    <input type="text" name='notes' className="twoIn3 correctionFieldsMobile" onChange={e => handleVin(e)} value={vin} />
                </div> 
                {warningVin ? <p className="req">{warningVin}</p> :''}
                <div className="inlineLnx">
                    <label for='notes' className="eCorrection">Driver <span className="req">(Require)</span></label>
                    <input type="text" name='notes' className="twoIn3 correctionFieldsMobile" onChange={e => handleDriver(e)} value={driver} />
                </div> 
                {warningDriver ? <p className="req">{warningDriver}</p> :''}
                <button onClick={handleStart} className="twoIn3 correctionFieldsMobile" style={{marginTop: '20px', height: '50px', backgroundColor: '#4b67a0', color: 'white', fontSize: '20px'}}>Start Trip</button>
                <p className="req">{errorPls}</p>
            </div> : 
            steps == 2 ?
            <div style={{marginTop:'100px', marginLeft: '20px'}}>
                <h1 className="collectionTitle">Vehicle Movement</h1>
                <div className="inlineLnx">
                    <label for='notes' className="eCorrection">Ongoing Trip</label>
                    
                </div> 
                <div style={{marginLeft: '25px', fontSize: '16px'}}>
                    <p ><b>Start date and time:</b> {JSON.stringify(startTime).split('T')[0]?.split('-')[2] +'/'+JSON.stringify(startTime).split('T')[0]?.split('-')[1]+'/'+JSON.stringify(startTime).split('T')[0]?.split('-')[0]?.split('"')[1]+' - '+JSON.stringify(startTime).split('T')[1]?.split(':')[0]+':'+JSON.stringify(startTime).split('T')[1]?.split(':')[1]   }</p>
                    <p ><b>Driver:</b> {driver}</p>
                    <p ><b>Reg:</b> {reg}</p>
                    <p ><b>Starting point:</b> {startCoordinates}</p>
                </div>
                <div className="inlineLnI cor">
                        <label for='vin' className="eCorrection">Status <span className="req" style={{color: 'gray'}}><i>(not required)</i></span></label>
                        <Select 
                            options={statusOption}
                            styles={colourStylesS}
                            //isSearchable={ false }
                            onChange={e => setStatus(e.value)}
                            //value={{label: status || 'None', value: status || ''}}
                        />
                </div>
                <div className="inlineLnx">
                    <label for='notes' className="eCorrection">Notes <span className="req" style={{color: 'gray'}}><i>(not required)</i></span></label>
                    <textarea name='notes' className="twoIn3 correctionFieldsMobile" style={{height:'200px'}} onChange={e => setNotes(e.target.value)} value={notes} />
                </div> 
                <button onClick={handleEnd} rows={10} className="twoIn3 correctionFieldsMobile" style={{marginTop: '20px', height: '50px', backgroundColor: '#4b67a0', color: 'white', fontSize: '20px'}}>{!loading ? 'End Trip' : <CircularProgress />}</button>
            </div>: 
            <div style={{marginTop:'100px', marginLeft: '20px'}}>
                <h1 className="collectionTitle">Vehicle Movement</h1>
                <div className="inlineLnx">
                    <label for='notes' className="eCorrection">Trip summary</label>
                </div> 
                <div style={{marginLeft: '25px', fontSize: '16px'}}>
                    <p ><b>Start date and time:</b> {JSON.stringify(startTime).split('T')[0]?.split('-')[2] +'/'+JSON.stringify(startTime).split('T')[0]?.split('-')[1]+'/'+JSON.stringify(startTime).split('T')[0]?.split('-')[0]?.split('"')[1]+' - '+JSON.stringify(startTime).split('T')[1]?.split(':')[0]+':'+JSON.stringify(startTime).split('T')[1]?.split(':')[1]   }</p>
                    <p ><b>Driver:</b> {driver}</p>
                    <p ><b>Reg:</b> {reg}</p>
                    <p ><b>Starting point:</b> {startCoordinates}</p>
                    <p ><b>End date and time:</b> {JSON.stringify(endTime).split('T')[0]?.split('-')[2] +'/'+JSON.stringify(endTime).split('T')[0]?.split('-')[1]+'/'+JSON.stringify(endTime).split('T')[0]?.split('-')[0]?.split('"')[1]+' - '+JSON.stringify(endTime).split('T')[1]?.split(':')[0]+':'+JSON.stringify(endTime).split('T')[1]?.split(':')[1]   }</p>
                    <p ><b>End point:</b> {startCoordinates}</p>
                    <p ><b>Status updated:</b> {status ? status : 'No'}</p>
                    <p ><b>Notes:</b> {notes ? notes : 'No'}</p>
                </div>
                <h3 className="collectionTitle">Thank you for your help. To start another trip please refresh.</h3>
            </div>
            }
        </>
    )
};

export default VehicleMovementsForm;