import React from "react";
import { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import UserService from "../../services/user.service";
import { IconButton } from '@material-ui/core';
import Slide from '@mui/material/Slide';
import Select from 'react-select';
import EventBus from "../../common/EventBus";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const userAccess = [
    {value: 'admin', label:'Admin'},
    {value: 'moderator', label: 'Moderator'},
    {value: 'user', label: 'user'}
]

const AccessTable = (props) =>{
    console.log(props)
    const [ tableBody, setTableBody ] = useState([])
    const [ userName, setUserName ] = useState()
    const [ userId, setUserId ] = useState()
    const [ email, setEmail ] = useState()
    const [ userRole, setUserRole ] = useState()
    const [ open, setOpen ] = useState(false)
    const [ stat, setStat ] = useState(0)
    const [ newAccess, setNewAccess ] = useState('')

    let users = props.users;
    
    useEffect(()=>{
        setTableBody([])
        console.log(users)
        for(let i = 0; i < users.length; i++){
            setTableBody(oldTableBody => [...oldTableBody,
              
                    <tr key={i}>
                        <td>{users[i].id}</td>
                        <td>{users[i].username}</td>
                        <td>{users[i].email}</td>
                        <td>{users[i].userRole}</td>
                        <td><button className="button" onClick={e => handleEdit(users[i].id, users[i].username, users[i].email, users[i].userRole)} >Edit</button></td>
                        <td><button className="button" onClick={e => handleDelete(users[i].id)} >Delete</button></td>
                    </tr>
            
            ])
        }
        
    },[props])   
    const handleEdit = (id, username, email, userRole) =>{
        console.log(id+'   '+username+'   '+email+'   '+userRole)
        setUserId(id)
        setUserName(username)
        setEmail(email)
        setUserRole(userRole)
        setOpen(true)
    }   
    console.log(tableBody)
    const handleDelete = (id) =>{
        //window.location.reload(false);
        UserService.deleteUser(id).then(response => console.log(response)).catch(err => {
            if (err.response && err.response.status === 401) {
                EventBus.dispatch("logout");
              }    
        })
        setTimeout(window.location.reload(false), 500)
    }
    const handleClose = () =>{
        setOpen(false)
        let data = {id: userId, username: userName, email: email, newAccess: newAccess}
        UserService.updateUser(data).then(response => console.log(response)).catch(err => {
            if (err.response && err.response.status === 401) {
                EventBus.dispatch("logout");
            }
        })
        setTimeout(window.location.reload(false), 500)
    } 
    return(
        <>
            <table className="tableX">
                <thead>
                    <th>User Id</th>
                    <th>UserName</th>
                    <th>Email</th>
                    <th>User Role</th>
                    <th>Edit</th>
                    <th>Remove</th>
                </thead>
                <tbody>
                    {tableBody}
                </tbody>
            </table>
            <div className="legend">
                <p>*Admins can view, edit, add and remove users</p>
                <p>*Moderators can only view and add users</p>
                <p>*Users dont have access to Users/Access page</p>
            </div>
            <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle></DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <div >
                <Grid container>
                    <Grid item xs={12}>

                        <div className="pictures">
                            <h3 className="titleL">Edit User and Access</h3>
                            <div className="flex">
                                <div className="inlineLnI">
                                    <label for='id' className="eCorrection">User Id </label>
                                    <input type="text" name='id' className="twoIn2 correctionFieldsMobile" /*onChange={e => handleVin(e)}*/ defaultValue={userId} />
                                </div>
                                <div className="inlineLnI">
                                    <label for='userName' className="eCorrection">UserName </label>
                                    <input type="text" name='userName' className="twoIn2 correctionFieldsMobile" onChange={e => setUserName(e.target.value)} defaultValue={userName} />
                                </div>
                            </div>
                            <div className="flex">
                                <div className="inlineLnI">
                                    <label for='email' className="eCorrection">User email </label>
                                    <input type="text" name='email' className="twoIn2 correctionFieldsMobile" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                                </div>
                                <div className="inlineLnI">
                                    <label for='access' className="eCorrection">Current Access </label>
                                    <input type="text" name='access' className="twoIn2 correctionFieldsMobile" /*onChange={e => handleVin(e)}*/ defaultValue={userRole} />
                                </div>
                            </div>
                            <div className="inlineLnx">
                                <label for='role' className="eCorrection">Change Access To</label>
                                <Select 
                                    options={userAccess}
                                    styles={colourStylesS}
                                    onChange={e => setNewAccess(e.value)}
                                /> 
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </div>                          
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <button className="buttonPay" onClick={handleClose}>Save&Close</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
        </>
    )
}
export default AccessTable;