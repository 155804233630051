import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SearchBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let url_f = '/allocation';
let data_f = 'url_f)';
 let url_D = '/DVLA';
 let data_D = 'url_D)';
 let url_R = '/RTW';
 let data_R = 'url_R)';
 let url = '/applicants';
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;
 
 useEffect(()=>{
    if(!data_f || !data_D || !data_R || !data){
        setLoading(true)
    }else{
        setLoading(false)
    }
 },[data_f, data_D, data_R, data])

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'table-header',
      width: 300,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'table-header',
      width: 300,
      editable: false,
    },
   {
      field: 'station',
      headerName: 'Station',
      headerClassName: 'table-header',
      width: 300,
      editable: false,
    },
    {
       field: 'name',
       headerName: 'Name',
       headerClassName: 'table-header',
       width: 350,
       editable: false,
     },
     {
        field: 'reg',
        headerName: 'Reg',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'make',
        headerName: 'Make',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'model',
        headerName: 'Model',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      ,
     {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 300,
        editable: true
       
      },
      {
        field: 'user',
        headerName: 'User',
        headerClassName: 'table-header',
        width: 180,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Filmer', 'Dan', 'Garth', 'Ewelina', 'Ventsi', 'Nick', 'Ben', 'Maddison'],
        valueGetter: (params) => {
          if (!params.value) {
            return params.value;
          }
          handleUser(params.value, params.row.id)
          return params.value
        }
      }
  ];

let displaiC = [];

if(data){
  data?.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  data?.map(dataRow => {
      let uTime = new Date(new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()).getTime()
      let utime2 = new Date(dataRow.Date).getTime()
      if(utime2 >= uTime){
        
          displaiC.push(
                  {id: dataRow.Line, date: dataRow.Date?.split('T')[0].split('-')[2]+'-'+dataRow.Date?.split('T')[0].split('-')[1]+'-'+dataRow.Date?.split('T')[0].split('-')[0]+' '+ dataRow.Date.split('T')[1].substr(0, 5), type: dataRow.Type , station: dataRow.Station, name: dataRow.Name, reg: dataRow.Reg, make: dataRow.Make, model: dataRow.Model, notes: dataRow.notes, user: dataRow.schedule_with}
          ) 
      }  

  })
}

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {
        let reg = '';
                if((dataRow.Name?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Type?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Station?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Reg?.toUpperCase().match(searchInput.toUpperCase())) && (new Date(dataRow.Date).getTime() >= new Date(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()).getTime())){
                
                    
                    findC.push(
                        {id: dataRow.Line, date: dataRow.Date.split('T')[0]+' '+ dataRow.Date.split('T')[1].substr(0, 5), type: dataRow.Type , station: dataRow.Station, name: dataRow.Name, reg: dataRow.Reg, notes: dataRow.notes, user: dataRow.schedule_with}
                    )
                }
            
        
});

}
useEffect(()=>{console.log(data)},[selected])
const handleDelete = () =>{
  console.log(data)
  let regs = [];
  let names = [];
  data.map(element =>{
    selected.map(sel =>{
      if(element.Line == sel){
        regs.push(element.Reg)
        names.push(element.Name)
      }
    })
  })
  UserService.deleteRows({rows: selected, names: names, regs: regs})
  setTimeout(()=>{
    window.location.reload(true)
  }, 500)
}

let dataH = {};
const handleCellEdit = (params) =>{
  console.log(params);
  dataH = {Line: params.id, notes: params.notes || null}
  console.log(dataH)
    UserService.updateAppointments(dataH)
    if(params.notes){
      setTimeout(()=>{
        window.location.reload(true)
      }, 200)
    }
   return params;
}
const handleUser = (value, id) =>{
  let data = {Line: id, schedule_with: value}
  UserService.updateAppointments(data).then(
    response => console.log(response)
  ).catch(err => console.log(err))
}
return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
        <button type='button' className='buttonDel' onClick={handleDelete}
        >Delete selected</button><h3 className='availableOffTitleC titleCorrection'>Appointments</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>: <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>}
    
</div>
)

};

export default SearchBar;