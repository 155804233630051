import React, { Component } from "react";
import AvailableBar from "./available.component";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import UnvailableBar from "./unavailable.components";
import WaitingRentalBar from "./waitingRental.component";
import WaitingOwnerBar from "./waitingOwner.component";
export default class OffroadAllocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      waitingContent: ""
    };
  }

  componentDidMount() {
    UserService.getOffRoadAssets().then(
      response => {
        this.setState({
          content: response.data,
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getWaitingList().then(
        response => {
          this.setState({
            waitingContent: response.data,
          });
        },
        error => {
          this.setState({
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          });
  
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
  }

  render() {
    let available = [];
    let unavailable = [];
    let content =  this.state.content;
    let waitingContent = this.state.waitingContent;
    let ownerWaiting = [];
    let rentalWaiting = [];

    for(let i =0 ; i < waitingContent.length; i++){
        if(waitingContent[i].type == 'Rent from Company'){
            rentalWaiting.push(waitingContent[i]);
        }else{
            ownerWaiting.push(waitingContent[i])
        }
    }

    for(let i = 0; i < content.length; i++){
        if(content[i].assignment == 'Available'){
            available.push(content[i]);
        }
    }
    for(let i = 0; i < content.length; i++){
        if(content[i].assignment !== 'Available' && content[i].status !== 'Inactive' && content[i].assignment !== 'On Rent' && content[i].assignment !== 'Grey Fleet'){
            unavailable.push(content[i]);
        }
    }
    return (
      <div className="containerf">
        <header className="jumbotronl">
          <div className="flexAssets">
            <div>  
                <h1 className="title">Allocations</h1>
                <AvailableBar table_data={available} />
            </div>
            <div className="correctUn">  
                <UnvailableBar table_data_un={unavailable} />
            </div>
          </div>
            <div className="flexAssets">
            <div>  
                <WaitingRentalBar table_data={rentalWaiting} />
            </div>
            <div className="correctUn2">  
                <WaitingOwnerBar table_data={ownerWaiting} />
            </div>
            </div>
        </header>
       
      </div>
    );
  }
}