import React from "react";
import { useEffect, useState } from "react";
import Select from 'react-select';
import UserService from "../../services/user.service";
import Box from '@material-ui/core/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Calendar from 'react-calendar';
import PublishIcon from '@mui/icons-material/Publish';
import AuthService from "../../services/auth.service";



const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '20% !important', fontSize: '20px', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        minHeight: '100%',
        fontSize: 'x-large',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const PayRequest = () =>{
    const [ contacts, setContacts ] = useState([])
    const [ names, setNames ] = useState([])
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ contact, setContact ] = useState()
    const [ damages, setDamages ] = useState(false)
    const [ deposit, setDeposit ] = useState(false)
    const [ rental, setRental ] = useState(false)
    const [ date, setDate ] = useState()
    const [ amount, setAmount ] = useState()
    const [ submitCheck, setSubmitCheck ] = useState()
    const namesS = []

    useEffect(()=>{
        UserService.getContacts().then(response =>{
            console.log('data received')
            setContacts(response.data)
            response.data.map(contact =>{
                
                namesS.push({label: contact.name, value: contact.name})
            })
            setNames(namesS)
            console.log(response.data)
        })
        
    },[])
    const handleContact = (e) =>{    
        setContact(e.value)
        contacts.map(element =>{
            if(element.name == e.value){
                setEmail(element.email)
                setPhone(element.phone_no)
            }
        })
    }
    const handleSubmit = (e) =>{
        const user = AuthService.getCurrentUser();
        const fleetUser = user.username;
        let reason = []
        reason[0] = damages;
        reason[1] = deposit;
        reason[2] = rental;
        let dataSend = {DA_Name: contact, Reason: (reason[0] ? 'Vehicle Damages' : '') + (reason[1] ?  (reason[0] ? ', Vehicle Deposit' : 'Vehicle Deposit') : '') + (reason[2] ? (reason[0] || reason[1] ? ', Vehicle Rental': 'Vehicle Rental') :'') , Due: date, Amount: amount,Reference: (new Date().getTime()+'PR'), Status: 'Unpaid', Entered_By: fleetUser, email: email}
        console.log(typeof(reason[2]))
        UserService.postPayment(dataSend).then(response =>{ 
                if(response.status == 200){
                    setSubmitCheck(true)
                }else{
                    setSubmitCheck(false)
                }
            }
            ).catch(err => setSubmitCheck(false))
    }
    return(
        <>
        {submitCheck ?  <h1 className="assetsOutcome">The payment request has been sent to the client!!</h1> : submitCheck === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
        <div>
            <div className="containerf containerfCorrection">
                <header className="jumbotronl">
                    <h1 className="collectionTitle">Payment Request</h1>
                </header>
                <div className="inlineLnx">
                    <label for='reg' className="eCorrection">Client<span className="req">(Require)</span></label>
                    <Select 
                        options={names}
                        styles={colourStylesS}
                        //isSearchable={ false }
                        onChange={e => handleContact(e)}
                        //value={{label: status || 'None', value: status || ''}}
                    />
                </div>
                <div className="flexAssets">
                    <div className="inlineLnI">
                        <label for='phone' className="eCorrection">Phone <span className="req">(Require)</span></label>
                        <input type="text" name='phone' className="twoIn2"  value={phone} /*onChange={e => handleFrDepth(e)}*/  />
                    </div>
                    <div className="inlineLnI">
                        <label for='email' className="eCorrection">Email <span className="req">(Require)</span></label>
                        <input type="text" name='email' className="twoIn2" value={email} /*onChange={e => handleFlDepth(e)}*/  />
                    </div>
                </div>
                <div className="distR"></div>
                <div className="inlineLnI">
                    <label for='make' className="eCorrection">Reason <span className="req">(Require)</span></label>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      <FormControlLabel
                        label="Vehicle Damages"
                        control={<Checkbox /*checked={clean[0]}*/ onChange={e => setDamages(e.target.checked)} />}
                      />
                      <FormControlLabel
                        label="Vehicle Deposit"
                        control={<Checkbox /*checked={clean[1]}*/ onChange={e => setDeposit(e.target.checked)} />}
                      />
                       <FormControlLabel
                        label="Vehicle Rental"
                        control={<Checkbox /*checked={clean[2]}*/ onChange={e => setRental(e.target.checked)} />}
                      />
                    </Box>
                </div>
                <div className="inlineLnx">
                <label for='notes' className="eCorrection">Due Date <span className="req">(Require)</span></label>
                    <div className="calendarOff">
                        <Calendar 
                            onChange={e => setDate(e)}
                        />
                    </div>
                </div>
                <div className="inlineLnx">
                    <label for='amount' className="eCorrection">Amount <span className="req">(Require)</span></label>
                    <input type="number" name='amount' className="twoIn2"  onChange={e => setAmount(e.target.value)}  />
                </div>
                
                <div className="submitPay">
                    {contact && phone && email && (damages || deposit || rental) && date && amount? <button className="buttonX space" onClick={e => handleSubmit(e)} >Submit <PublishIcon/></button> : <button className="buttonX space" style={{backgroundColor: 'gray'}} >Submit <PublishIcon/></button>}
                </div>
                
            </div>
        </div>
        }
        </>
    )
}
export default PayRequest;