import {QrScanner} from '@yudiel/react-qr-scanner';
import { useEffect, useState, useRef } from 'react';
import  VideoRecorder  from 'react-video-recorder';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SearchIcon from '@mui/icons-material/Search';

const fd = new FormData();

/*window.addEventListener('beforeunload', (event) => {
  event.returnValue = `Please wait`;
});*/

const AppQR = () => {
  const [x, setX] = useState()
  const [step, setStep] = useState(1)
  const [hasVideoPermission, setHasVideoPermission] = useState(false)
  const [hasAudioPermission, setHasAudioPermission] = useState(false)
  const [videoCheck, setVideoCheck ] = useState()
  const [damages_windscreen, setDamages_Windscreen] = useState()
  const [lights_condition, setLignts_Condition] = useState()
  const [damages_bodywork, setDamages_Bodywork ] = useState()
  const [damages_sharp, setDamages_Sharp] = useState()
  const [tyre_condition, setTyre_Condition] = useState()
  const [overall_condition, setOverall_Condition ] = useState()
  const [ search, setSearch ] = useState()
  const updateNotificationRef = useRef()
  const updateNotificationRef2 = useRef()
  const updateThank = useRef()
  const [ osm, setOsm ] = useState()

  window.Buffer = window.Buffer || require("buffer").Buffer;
  function toUpper(e){
    document.getElementById('upper').value = e.target.value.toUpperCase();
    setSearch(e.target.value.toUpperCase())
  }
  const handleSearch = () =>{
    
      fetch('https://node.tambor.uk:9000/sqlResponse', { method: "POST", headers:{"Content-Type": "application/json","Access-Control-Allow-Origin":"*"},  body:JSON.stringify({vin: search.toUpperCase()})}).then((response) => {
      console.log(response.status);
      if(response.status == 200){
        setX(search.toUpperCase());
        setStep(2)
      }else{
        updateNotificationRef2.current.animate(
          {
            opacity: [0, 1, 0]
          },
          2000
        );
        setX(false);
      }
    }).catch(err => console.log(err));
      
    
  }
  
  
  const handleError = (err) =>{
    console.log(err)
  }
  const handleScan = (data) =>{
    document.getElementById('upper').value = data;
    setSearch(data)
    /*if(data.length >= 17){
      fetch('https://node.tambor.uk:9000/sqlResponse', { method: "POST", headers:{"Content-Type": "application/json","Access-Control-Allow-Origin":"*"},  body:JSON.stringify({vin: data.toUpperCase()})}).then((response) => {
      console.log(response.status);
      if(response.status == 200){
        setX(data);
        setStep(2)
      }else{
        updateNotificationRef2.current.animate(
          {
            opacity: [0, 1, 0]
          },
          2000
        );
        setX(false);
      }
    }).catch(err => console.log(err));
      
    }*/
  
  }
  const handleSubmit = () =>{
    if(videoCheck !== true){
      updateNotificationRef.current.animate(
        {
          opacity: [0, 1, 0]
        },
        2000
      );
    }else{
      document.getElementById('hide').style.display = "none";
      updateThank.current.animate(
        {
          opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        200000
      );
    }
  }
  
  
  return(
    <div className='videoUpload videoCorrection'>
    { !x && step == 1 ? 
    
    <div className='doneB'>
      <QrScanner
              onCameraOn={() => {
                setHasVideoPermission(true)
                setHasAudioPermission(true)
              }}
              onDecode={(result) => handleScan(result)}
              onError={(error) => handleError(error)}
          />
        <h4 ref={updateNotificationRef2} id="unhide2" style={{opacity: 0}}>Vin number not found!</h4>
        <label className='regLabel'>Vin</label>
        <input type="text" name="reg" className='reg' placeholder='Enter your vin number' onChange={(e) => toUpper(e) } id='upper'/><button className='buttonVin' onClick={handleSearch}><SearchIcon fontSize='xx-large'/>Search Vin</button>
      
  </div> : x &&  step == 2 ?
  <div className='corVideoForm'>
    
    <h1 className="titleForm">Vehicle Condition</h1> 
              <div className="inlineLnx">
                  <label for='namem' className="eCorrection">Created By <span className="req">(Require)</span></label>
                  <input type="text" name='namem' className="twoIn2"/*value={assigned_by}*/  onChange={e => setOsm(e.target.value)}  /> 
              </div>
                <div className="videoForm">
                    <div className="bitMarginF">    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>1, Are there any cracks/chips in the windscreen?<span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setDamages_Windscreen(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" />     
                              </div>                                                                                   
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMarginF">
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>2, Are all the lights working? <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setLignts_Condition(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" />     
                              </div>                                                                                   
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
              
                <div className="videoForm">
                    <div className="bitMarginF">    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>3, Are their any defects in the bodywork? <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setDamages_Bodywork(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" />  
                              </div>                                                                                      
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMarginF">
                    <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>4, Are their any defects in the bodywork that could be considered sharp or Unsafe? <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setDamages_Sharp(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" /> 
                              </div>                                                                                        
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="videoForm">
                    <div className="bitMarginF">    
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>5, Are all the tyres in a legal roadworthy condition? <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setTyre_Condition(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" />  
                              </div>                                                                                       
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="bitMarginF">
                    <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"><h3>6, Is the vehicle overall in a roadworthy condition? <span className="req">(Require)</span></h3></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //value={valueRadio3}
                                onChange={e => setOverall_Condition(e.target.value)}
                            >
                              <div className='flexFormF'>
                                <FormControlLabel disableTypography value="Yes" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                        />}
                                                                            sx={{m: 2, fontSize: 22}}            
                                                                            label="Yes"
                                                                        />
                                <FormControlLabel disableTypography value="No" control={<Radio sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 38,
                                                                                    }}} 
                                                                                    className="check"
                                                                                    />} 
                                                                            sx={{m: 2, fontSize: 22}}         
                                                                            label="No" /> 
                              </div>                                                                                       
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                {osm && damages_windscreen && lights_condition && damages_bodywork && damages_sharp && tyre_condition && overall_condition ? <button className='submitButton' onClick={e => setStep(3)}>Take video</button> : <button className='submitButton' style={{backgroundColor: 'gray'}}>Take video</button>}

  </div> : x && step == 3 ?
  <>  
    <div id="hide" className='videoPlayer'>
      
    <VideoRecorder
        isFlipped={false}
        
        onCameraOn={() => {setHasVideoPermission(true); setHasAudioPermission(true)}}
        //mimeType="video/webm;codecs=vp8,opus"
        constraints={{
          
          audio: true,
          video: {
            width: { exact: 1000, ideal: 1000 },
            height: { exact: 1000, ideal: 1000 },
            aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
            resizeMode: "crop-and-scale",
            facingMode: {ideal: 'environment'}
          }
        }}
        onRecordingComplete={(videoBlob) => {
            setVideoCheck(true)
            //console.log(videoCheck)
            fd.append('video', videoBlob, x+'.webm')
            fd.append('osm', osm)
            fd.append('damages_windscreen', damages_windscreen)
            fd.append('lights_condition', lights_condition)
            fd.append('damages_bodywork', damages_bodywork)
            fd.append('damages_sharp', damages_sharp)
            fd.append('tyre_condition', tyre_condition)
            fd.append('overall_condition', overall_condition)
            fetch('https://node.tambor.uk:9000/video', { method: "POST", "Content-Type": "multipart/form-data","Access-Control-Allow-Origin":"*",  body: fd}).then((response) => {
                if(response.status == 200){
                    setVideoCheck(false)
                    updateThank.current.animate(
                        {
                            opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                        },
                        200000
                        );
                    return response;
                }
            }).catch(err => {
                console.log(err)
            });
                //console.log('videoBlob', fd);
                document.getElementById('hide').style.display = "none";
                
          }} 
      
      
      
    />
  <h4 ref={updateNotificationRef} id="unhide" style={{opacity: 0}}>A video recording is require before submitting!!</h4>
  
  {/*<a  onClick={handleSubmit} className="myButton" >Submit</a>*/}
  
  </div>
          {
            videoCheck == true? <div className='vUpload'><CircularProgress /><h4>  Video uploading...</h4></div> : videoCheck == false ? '' : ''
          }
        <h4 ref={updateThank} id="uh" style={{opacity: 0, color: '#1d8c3b', paddingTop: '10%'}} >Video Uploaded!! Thank you for your submit!!</h4>
        
   </> : <h3 style={{paddingTop: '10%'}}>{step+'       '+x}</h3>}
    </div>
  )
}

export default AppQR;