import React, { Component, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const steps = [
    'Select a date',
    'Complete the van details',
    'Complete payment details',
    'Select Suplier',
    'Select insurance'
  ];
const modelOptions = [];

const classOptions = [
    {value: 'SWB', label: 'SWB'},
    {value: 'MWB', label: 'MWB'},
    {value: 'MWB-A', label: 'MWB-A'},
    {value: 'LWB', label: 'LWB'},
    {value: 'LWB-A', label: 'LWB-A'},
    {value: 'XLWB', label: 'XLWB'}
];

let supplierOptions = [];

const insuranceOptions = [
    {value: 'Business Compare Direct (25+)', label: 'Business Compare Direct (25+)'},
    {value: 'Gray Fleet', label: 'Gray Fleet'},
    {value: 'Insure Fleet', label: 'Insure Fleet'},
];

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

export default class CollectionForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          steps: 0,
          date: "",
          reg: "",
          model: "",
          class: "",
          vin: "",
          serviceDate: "",
          serviceMileage: "",
          rate: "",
          deposit: "",
          supplier: "",
          insurance: "",
          tyreSize: "",
          countVdetails:6,
          countPayment:2,
          countLast:2,
          suppliers: "" ,
          models: "" ,
          checkSubmit: "",
          capacity: "",
          insuranceExp: "",
          ccAccount: "",
          dcAccount: "",
          git: "",
          pl: "",
          clientDrop: "",
          clientName: "",
        };
      }
      
    componentDidMount() {
        this.setState({suppliers: ""})
        
        UserService.getSuppliers().then(
            response => {
              this.setState({
                suppliers: response.data,
              });
            },
            error => {
              this.setState({
                suppliers:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getVehicleModels().then(
            response => {
              this.setState({
                models: response.data,
              });
            },
            error => {
              this.setState({
                models:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString()
              });
      
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
              }
            }
          );
          UserService.getClientDrop().then(
            response => {
                this.setState({
                  clientDrop: response.data,
                });
              },
              error => {
                this.setState({
                  clientDrop:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString()
                });
        
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                }
              }
          )
    }
    
    render() {
        //date
        supplierOptions = [];
        const dateChange = (e) =>{
            this.setState({steps: 1});
            this.setState({date: e});
        }

        console.log(this.state.clientDrop)
        //vehicle details
     
        const handleReg = (e) =>{
            this.setState({reg: e.target.value})
            if(e.target.value.length > 0){
                this.setState({countVdetails: this.state.countVdetails-1})
            }
            if(this.state.countVdetails < 0){
                this.setState({countVdetails: 0})
            }
            if(e.target.value.length == 0){
                this.setState({countVdetails: this.state.countVdetails+1})
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }

        const handleModel = (e) =>{
            this.setState({model: e.value})
            if(e.value.length > 0){
                this.setState({countVdetails: this.state.countVdetails-1})
            }
            if(this.state.countVdetails < 0){
                this.setState({countVdetails: 0})
            }
            if(e.value.length == 0){
                this.setState({countVdetails: this.state.countVdetails+1})
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }

        const handleClass = (e) =>{
            this.setState({class: e.value})
            if(e.value.length > 0){
                this.setState({countVdetails: this.state.countVdetails-1})
            }
            if(this.state.countVdetails < 0){
                this.setState({countVdetails: 0})
            }
            if(e.value.length == 0){
                this.setState({countVdetails: this.state.countVdetails+1})
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }

        const handleVin = (e) =>{
            if(e.target.value.length <= 17){
                this.setState({vin: e.target.value})
                if(e.target.value.length > 0){
                    this.setState({countVdetails: this.state.countVdetails-1})
                }
                if(this.state.countVdetails < 0){
                    this.setState({countVdetails: 0})
                }
                if(e.target.value.length == 0){
                    this.setState({countVdetails: this.state.countVdetails+1})
                }
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }

        const handleSDate = (date) =>{
            this.setState({serviceDate: date})
            if(date){
                this.setState({countVdetails: this.state.countVdetails-1})
            }
            if(this.state.countVdetails < 0){
                this.setState({countVdetails: 0})
            }
            if(!date){
                this.setState({countVdetails: this.state.countVdetails+1})
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }
        
        const handleSMileage = (e) =>{
            if(e.target.value.length < 7){
                this.setState({serviceMileage: e.target.value})
            }
            if(e.target.value.length > 0){
                this.setState({countVdetails: this.state.countVdetails-1})
            }
            if(this.state.countVdetails < 0){
                this.setState({countVdetails: 0})
            }
            if(e.target.value.length == 0){
                this.setState({countVdetails: this.state.countVdetails+1})
            }
            if(this.state.countVdetails == 0 || this.state.countVdetails == -1){
                this.setState({steps: 2})
            }
        }

        const handleTyre = (e) =>{
                    

            if(e.target.value.length <= 9){
                this.setState({tyreSize: ""})   
                if(e.target.value.length == 3){
                    this.setState({tyreSize: e.target.value + '/'})
                }else if(e.target.value.length == 6){
                    this.setState({tyreSize: e.target.value + 'R'})
                }else{
                    this.setState({tyreSize: e.target.value})
                }
            }
        }
       
        //Payment
        const handleRate = (e) =>{
            this.setState({rate: e.target.value})
            if(e.target.value.length > 0){
                this.setState({countPayment: this.state.countPayment-1})
            }else{
                this.setState({countPayment: this.state.countPayment+1})
            }
            if(this.state.countPayment == 0){
                this.setState({steps: 3})
            }
        }

        const handleDeposit = (e) =>{
            this.setState({deposit: e.target.value})
            if(e.target.value.length > 0){
                this.setState({countPayment: this.state.countPayment-1})
            }else{
                this.setState({countPayment: this.state.countPayment+1})
            }
            if(this.state.countPayment == 0){
                this.setState({steps: 3})
            }
        }
        let clientDropOption = [];
        //supplier and insurance
        const handleSupplier = (e) =>{
            this.setState({supplier: e.value})
         
                this.setState({steps: 4})
            if(e.value == 'Skilled Solutions - Gray Fleet'){
                let len = this.state.clientDrop.length;
                for(let i = 0; i < len; i++){
                    clientDropOption.push({value: this.state.clientDrop[i].name, label: this.state.clientDrop[i].name})
                }
                console.log(clientDropOption)
                this.setState({clientDrop: clientDropOption})
            }
            
        }

        const handleInsurance = (e) =>{
            this.setState({insurance: e.value})
          
                this.setState({steps: 5})
    
        }
        let suppliers = this.state.suppliers;
        for(let i = 0; i< suppliers.length; i++){
            supplierOptions.push({value: suppliers[i].name, label: suppliers[i].name})
        }
        let models = this.state.models;
        for(let i =0; i < models.length; i++){
            modelOptions.push({value: models[i].model, label: models[i].model})
        }

        const handleSubmit = () =>{
            UserService.insertAsset(this.state.date, this.state.reg, this.state.model, this.state.class, this.state.vin, this.state.serviceDate, this.state.serviceMileage, this.state.tyreSize, this.state.rate, this.state.deposit, this.state.supplier, this.state.insurance, this.state.capacity, this.state.insuranceExp, this.state.ccAccount, this.state.dcAccount, this.state.clientName, this.state.pl, this.state.git)
            .then(response => {
                this.setState({checkSubmit: true}) 
                console.log(response)
            }).catch(err => {
                this.setState({checkSubmit: false})
                console.log(err)
            })
            
        }
        const handleCapacity = (e) =>{
            if(e.target.value.length <= 3){
                this.setState({capacity: e.target.value})
            }
        }
        const handleInsuranceExp = (e) =>{
            this.setState({insuranceExp: e});
        }
        const handleCcAccount = (e) =>{
            this.setState({ccAccount: e});
        }
        const handleDcAccount = (e) =>{
            this.setState({dcAccount: e});
        }
        const handleGit = (e) =>{
            this.setState({git: e})
        }
        const handlePl = (e) =>{
            this.setState({pl: e})
            console.log(e)
        }
        return(
            <>
            {this.state.checkSubmit ? <h1 className="assetsOutcome">Asset Added!!</h1> : this.state.checkSubmit === false ? <h1 className="assetsOutcome">Something whent wrong! Please refresh and try again!</h1> :
            <div>
                <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Add Assett</h1>
                    </header> 
                    <Box sx={{ width: '100%', fontSize: 'x-large' }}>
                        <Stepper activeStep={this.state.steps} alternativeLabel>
                            {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Box>   
                    <div>
                        <h2 className="subttV">Supply Date</h2>  
                        <p>Please enter the date we acquired the vehicle/Owner Driver started using the vehicle</p> 
                        <div className="calendar">
                            <Calendar  
                            onChange={e => dateChange(e)} 
                            //value={this.state.value} 
                            validRange = {{
                                start: new Date(),
                                end: null
                            }}
                            />
                        </div>
                        <h2 className="subttV">Vehicle Details</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Vehicle Registration <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile5" onChange={e => handleReg(e)} value={this.state.reg.toUpperCase()} />
                            </div>
                            <div className="inlineLnI">
                                <label for='model' className="eCorrection">Vehicle Model <span className="req">(Require)</span></label>
                                <Select 
                                    options={modelOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleModel(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                            <label for='class' className="eCorrection">Vehicle Class <span className="req">(Require)</span></label>
                                <Select 
                                    options={classOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleClass(e)}
                                    value={this.state.defaultValueClass}
                                /> 
                            </div>
                            <div className="inlineLnI">
                                <label for='reg' className="eCorrection">Vehicle Vin Number <span className="req">(Require)</span></label>
                                <input type="text" name='reg' className="twoIn2 correctionFieldsMobile5" onChange={e => handleVin(e)} value={this.state.vin.toUpperCase()} />
                            </div>
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='serviceD' className="eCorrection">Service Date <span className="req">(Require)</span></label>
                                <DatePicker selected={this.state.serviceDate} onChange={(date) => handleSDate(date)} className="correctionFieldsMobile3"/>
                            </div>
                            
                            <div className="inlineLnI">
                                <label for='mileage' className="eCorrection">Service Mileage <span className="req">(Require)</span></label>
                                <input type="number" name='mileage' className="twoIn2 correctionFieldsMobile5" value={this.state.serviceMileage} onChange={e => handleSMileage(e)}  />
                            </div>
                        </div>
                        <div className="inlineLnx">
                                <label for='mileage' className="eCorrection correctionFieldsMobile6">Tyre Size <span className="req">(Require)</span></label>
                                <input type="text" name='mileage' className="twoIn2 correctionFieldsMobile6" placeholder="___/__R__" value={this.state.tyreSize} onChange={e => handleTyre(e)}  />
                        </div>
                        
                        <div className="inlineLnx">
                            <label for='capacity' className="eCorrection">Fuel Capacity<span className="req">(Require)</span></label>
                            <input type="number" name='capacity' className="twoIn2 correctionFieldsMobile5" value={this.state.capacity} onChange={e => handleCapacity(e)}  />
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='rate' className="eCorrection">Insurance Expires <span className="req">(Require)</span></label>
                                <div className="calendar calendarCorrection">
                                    <Calendar  
                                    onChange={e => handleInsuranceExp(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    />
                                </div>                            
                            </div>
                            <div className="inlineLnI">
                                <label for='deposit' className="eCorrection">Congestion Charge Account <span className="req">(Require)</span></label>
                                <div className="calendar calendarCorrection">
                                    <Calendar  
                                    onChange={e => handleCcAccount(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    />
                                </div>
                             </div>
                        </div>
                        <div className="flexAssets">
                                    <div className="inlineLnI">
                                        <label for='rate' className="eCorrection">Public liability expiry <span className="req">(Require)</span></label>
                                        <div className="calendar calendarCorrection">
                                            <Calendar  
                                            onChange={e => handlePl(e)} 
                                            //value={this.state.value} 
                                            validRange = {{
                                                start: new Date(),
                                                end: null
                                            }}
                                            />
                                        </div>                            
                                    </div>
                                    <div className="inlineLnI">
                                        <label for='deposit' className="eCorrection">Goods in transit expiry <span className="req">(Require)</span></label>
                                        <div className="calendar calendarCorrection">
                                            <Calendar  
                                            onChange={e => handleGit(e)} 
                                            //value={this.state.value} 
                                            validRange = {{
                                                start: new Date(),
                                                end: null
                                            }}
                                            />
                                        </div>
                                    </div>
                                </div>
                        <div className="inlineLnI">
                                <label for='deposit' className="eCorrection">Dart Charge Account <span className="req">(Require)</span></label>
                                <div className="calendar calendarCorrection">
                                    <Calendar  
                                    onChange={e => handleDcAccount(e)} 
                                    //value={this.state.value} 
                                    validRange = {{
                                        start: new Date(),
                                        end: null
                                    }}
                                    />
                                </div>
                             </div>
                        
                        <h2 className="subttV">Payment Details</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='rate' className="eCorrection">Rate <span className="req">(Require)</span></label>
                                <input type="number" name='rate' className="twoIn2 correctionFieldsMobile5" value={this.state.rate} onChange={e => handleRate(e)}  />
                            </div>
                            <div className="inlineLnI">
                                <label for='deposit' className="eCorrection">Deposit <span className="req">(Require)</span></label>
                                <input type="number" name='deposit' className="twoIn2 correctionFieldsMobile5" value={this.state.deposit} onChange={e => handleDeposit(e)}  />
                            </div>
                        </div>
                        <h2 className="subttV">Insurance and Supplier</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='supplier' className="eCorrection">Select Supplier <span className="req">(Require)</span></label>
                                <Select 
                                    options={supplierOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleSupplier(e)}
                                    value={this.state.defaultValueClass}
                                />
                            </div>
                            <div className="inlineLnI">
                                <label for='insurance' className="eCorrection">Select Insurance <span className="req">(Require)</span></label>
                                <Select 
                                    options={supplierOptions}
                                    styles={colourStylesS}
                                    onChange={e => handleInsurance(e)}
                                    value={this.state.defaultValueClass}
                                />
                            </div>
                        </div>
                            
                            <>
                            {this.state.supplier == 'Skilled Solutions - Gray Fleet' ?
                                <>
                                    <h2 className="subttV">Please complete the below</h2>
                                    <div className="inlineLnx">
                                        <label for='driver' className="eCorrection">Drivers Name<span className="req">(Require)</span></label>
                                        <Select 
                                            options={this.state.clientDrop}
                                            styles={colourStylesS}
                                            onChange={e => this.setState({clientName: e.value})}
                                        // value={this.state.clientName}
                                        /> 
                                    </div>

                                </> :''
                            } 
                               
                            </>
                        {this.state.tyreSize && this.state.vin && this.state.capacity && this.state.date && this.state.reg && this.state.model && this.state.class && this.state.serviceMileage && this.state.serviceDate && this.state.insuranceExp && this.state.ccAccount && this.state.dcAccount && this.state.rate && this.state.deposit && this.state.insurance && this.state.supplier ?
                            <div className="submitButtonAss">
                                <button type="button" className="submitButtonnAss" onClick={handleSubmit}>Submit</button>
                            </div>:
                            <div className="submitButtonAss">
                            <button type="button" className="submitButtonnAss" style={{backgroundColor: 'gray'}}>Submit</button>
                        </div>
                        }
                    </div> 
                </div>
            </div> 
     
            } 
            </>                   
        )
    }
}