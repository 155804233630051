import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactDOM from "react-dom";

const container = document.getElementById("root");
const root = document.getElementById('root')//createRoot(container);

ReactDOM.render(
  <BrowserRouter>
    
      <App />
   
    <footer className="footer">
        <h5>Copyright © 2024 Tambor Fleet Solutions Ltd - Tambor Fleet Solutions, 20 Nobel Square, Burnt Mills Industrial Estate, Basildon SS13 1LP</h5>
    </footer>
  </BrowserRouter>,
  root
);

serviceWorker.unregister();
