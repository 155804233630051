import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import TcComp from "./components/tracfast-tc.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import RateCard from "./components/rate-card.component";
import HireTc from "./components/hiretc.component";
import AppointmentView from "./components/Appointments/view.component";
import CollectionForm from "./components/Appointments/collection.component";
import AddAssets from "./components/vehicles/addAssets.component";
import OffroadAllocations from "./components/Allocation/offRoadAllocation.component";
import SupplierFrame from "./components/Suppliers/supplierFrame.component";
import AddContact from "./components/contacts/addContact.component";
import IssuingFrame from "./components/pcn/issuing.component";
import PcnDataFrame from "./components/pcn/pcnData.component";
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider } from '@coreui/react'
import EventBus from "./common/EventBus";
import CodesFrame from "./components/pcn/codes.component";
import AddPcn from "./components/pcn/addPcn.component";
import UserService from "./services/user.service";
import NewHireForm from "./components/Hire/newHire.component";
import CurrentAllocationFrame from "./components/Allocation/currentAllocation.component";
import ConditionReportForm from "./components/Hire/conditionReport.component";
import PayDataFrame from "./components/Payments/payDataFrame.component";
import PdfCameraFrame from "./components/Hire/pdfFrame.component";
import StatusUpdate from "./components/Hire/statusUpdate.component";
import ProfilesFrame from "./components/Hire/profilesFrame.component";
import OffHire from "./components/Hire/offHire.component";
import PdfOffhireFrame from "./components/Hire/pdfOffhireFrame.component";
import PDFNewViewer from "./components/Hire/pdfNew.component";
import PdfNewFrame from "./components/Hire/pdfNewFrame.component";
import userService from "./services/user.service";
import tamborLogo from './TamborLogo.png';
import UserHome from "./components/userHome.component";
import axios  from "axios";
import PayRequest from "./components/Payments/payRequest.component";
import StripeFrame from "./components/Payments/stripeFrame.component";
import PayConfirmation from "./components/Payments/payConfirmation.component";
import AppQR from "./components/video/viedoForm.component";
import VideoList from "./components/video/videoList.component";
import OffTrack from "./components/offTracker/offList.component";
import OffUpdate from "./components/offTracker/offUpdate.component";
import AuditTrack from "./components/fleetAudit/auditTacker.component";
import ReturnToSupplier from "./components/Hire/returnToSupplier.component";
import AuthVerify from "./common/auth-verify";
import AskLogin from "./components/askLogin.component";
import TcForm from "./components/tandc/tc.component";
import NewNewHireForm from "./components/Hire/newNewHire.component";
import VehicleMovementsForm from "./components/vehicles/vehicleMovementsForm.component";
import VehicleMovementsView from "./components/vehicles/vehicleMovementsView.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      dropdownOpen: false,
      assets: "",
      applicants: "",
      codes: "",
      issuing:"",
      userSend: ""
    };

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    console.log(user)
    UserService.getOffRoadAssets().then(
      response => {
        this.setState({
          assets: response.data,
        });
      },
      error => {
        this.setState({
          assets:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getAllApplicants().then(
      response => {
        this.setState({
          applicants: response.data,
        });
      },
      error => {
        this.setState({
          applicants:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getCodes().then(
      response => {
        this.setState({
          codes: response.data,
        });
      },
      error => {
        this.setState({
          codes:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getIssuing().then(
      response => {
        this.setState({
          issuing: response.data,
        });
      },
      error => {
        this.setState({
          issuing:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });

   
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onMouseEnter() {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave() {
    this.setState({dropdownOpen: false});
  }
  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    const clearInterval = () =>{
      axios.get('http://192.168.150.27:9080/stop').then(res => console.log(res))
    }
    
    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          {currentUser ? 
          <Link to={"/userHome"} className="navbar-brand">
            <img src={tamborLogo} className="logo"/>
          </Link>
          :<Link to={"/"} className="navbar-brand">
            <img src={tamborLogo} className="logo"/>
          </Link>}
          <div className="navbar-nav mr-auto">
          <CDropdown dark component="li" variant="nav-item" >
              <CDropdownToggle>Documents</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/tfcomp">
                  <Link to={"/tfcomp"} className="nav-link-t">
                    Tracfast - tracking T&C's
                  </Link>
                </CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/rate-card"><Link to={"/rate-card"} className="nav-link-t">
                    Vehicle Hire - Rate Card
                  </Link></CDropdownItem>
                  <CDropdownDivider />
                <CDropdownItem href="/hiretc"><Link to={"/hiretc"} className="nav-link-t">
                    Vehicle Hire - Terms and Conditions
                  </Link></CDropdownItem>
                  
              </CDropdownMenu>
            </CDropdown>
           {!currentUser ? 
            <CDropdown dark component="li" variant="nav-item" >
              <CDropdownToggle>Forms</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/videoUpload">
                  <Link to={"/videoUpload"} className="nav-link-t">
                    Video Report
                  </Link>
                </CDropdownItem>
                <CDropdownDivider />
                  <CDropdownItem href="/moveForm"><Link to={"/moveForm"} className="nav-link-t">
                      Movement
                  </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            : ''
            }

            {/*showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )*/}
            

            {currentUser && (
             <>
             <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Appointments</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/apView">
                  <Link to={"/apView"} className="nav-link-t">
                    View
                  </Link>
                </CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/collection"><Link to={"/collection"} className="nav-link-t">
                    Booking Form
                  </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Allocations</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/currentAllocation"><Link to={"/currentAllocation"} className="nav-link-t">
                      Current Allocation
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/offRoadAllocations"><Link to={"/offRoadAllocations"} className="nav-link-t">
                      Off Road Allocation
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/offTrack"><Link to={"/offTrack"} className="nav-link-t">
                      Off Hire Tracker
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/offUpdate"><Link to={"/offUpdate"} className="nav-link-t">
                      Off Tracker Update
                    </Link></CDropdownItem>
                    {/*<CDropdownDivider />
                <CDropdownItem href="#"><Link to={"/"} className="nav-link-t">
                      Vehicle Movements
                  </Link></CDropdownItem>*/}
              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Payments</CDropdownToggle>
                <CDropdownMenu>
                  {/*<CDropdownItem href="/"><Link to={"/"} className="nav-link-t">
                      Make a Payment
                    </Link></CDropdownItem>
                    <CDropdownDivider />*/}
                <CDropdownItem href="/payData"><Link to={"/payData"} className="nav-link-t">
                      Payment Data
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/payRequest"><Link to={"/payRequest"} className="nav-link-t">
                      Request Payment
                    </Link></CDropdownItem>
               

              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>PCN's</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/codesList"><Link to={"/codesList"} className="nav-link-t">
                      Codes
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/pcnData"><Link to={"/pcnData"} className="nav-link-t">
                      Database
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/addPcn"><Link to={"/addPcn"} className="nav-link-t">
                      Enter PCN
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/issuingList"><Link to={"/issuingList"} className="nav-link-t">
                      Issuing Authorities
                    </Link></CDropdownItem>

              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Suppliers</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/supplierList"><Link to={"/supplierList"} className="nav-link-t">
                      Suppliers List
                    </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Vehiform</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/newnewHire"><Link to={"/newnewHire"} className="nav-link-t">
                      New Hire
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/statusUpdate"><Link to={"/statusUpdate"} className="nav-link-t">
                      Status Update
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/offHire"><Link to={"/offHire"} className="nav-link-t">
                      Off Hire
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/conditionReport"><Link to={"/conditionReport"} className="nav-link-t">
                      Condition Report
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/profiles"><Link to={"/profiles"} className="nav-link-t">
                      Vehicle Profiles
                    </Link></CDropdownItem>    
                    <CDropdownDivider />
                <CDropdownItem href="/videoList"><Link to={"/videoList"} className="nav-link-t">
                      Video Reports
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/videoUpload"><Link to={"/videoUpload"} className="nav-link-t">
                      Video Take
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/auditTrack"><Link to={"/auditTrack"} className="nav-link-t">
                      Audit Tracker
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/returnVehicle"><Link to={"/returnVehicle"} className="nav-link-t">
                      Return Vehicle
                    </Link></CDropdownItem>
              </CDropdownMenu>
              
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Vehicles</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/addAssets"><Link to={"/addAssets"} className="nav-link-t">
                      Add Vehicle
                  </Link></CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/moveForm"><Link to={"/moveForm"} className="nav-link-t">
                      Movement
                  </Link></CDropdownItem>
                  <CDropdownDivider />
                  <CDropdownItem href="/movementsView"><Link to={"/movementsView"} className="nav-link-t">
                      Movement View
                  </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>

            <CDropdown dark component="li" variant="nav-item" className="hide">
              <CDropdownToggle>Contact</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/addContact"><Link to={"/addContact"} className="nav-link-t">
                      Add Contact
                    </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>

            {/*mobile forms-----------------------------------------------------------------------------------------------------------*/}
            <CDropdown dark component="li" variant="nav-item" className="unhidden">
              <CDropdownToggle>Forms</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="/collection"><Link to={"/collection"} className="nav-link-t">
                      Booking - vehicle collection
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/addPcn"><Link to={"/addPcn"} className="nav-link-t">
                      Pcn entry
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/newnewHire"><Link to={"/newnewHire"} className="nav-link-t">
                      New Hire
                    </Link></CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/statusUpdate"><Link to={"/statusUpdate"} className="nav-link-t">
                      Status Update
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/offHire"><Link to={"/offHire"} className="nav-link-t">
                      Off Hire
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/addAssets"><Link to={"/addAssets"} className="nav-link-t">
                      Add vehicle
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/addContact"><Link to={"/addContact"} className="nav-link-t">
                      Add contact
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                <CDropdownItem href="/profiles"><Link to={"/profiles"} className="nav-link-t">
                      Profiles
                    </Link></CDropdownItem>
                    <CDropdownDivider />
                  <CDropdownItem href="/moveForm"><Link to={"/moveForm"} className="nav-link-t">
                      Movement
                  </Link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </>

             
            )}
            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Access
                </Link>
              </li>
            )}
            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Users
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username?.split(' ')[0]}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>
              {/*<li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
                </li>*/}
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tfcomp" element={<TcComp/>} />
            <Route path="/rate-card" element={<RateCard />}/>
            <Route path="/hiretc" element={<HireTc/>} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={currentUser ? <BoardUser /> : <AskLogin />} />
            <Route path="/admin" element={currentUser ? <BoardAdmin />: <AskLogin />} />
            <Route path="/mod" element={currentUser ? <BoardModerator /> : <AskLogin />} />
            <Route path="/apView" element={currentUser ? <AppointmentView/> : <AskLogin />}/>
            <Route path="/collection" element={currentUser ? <CollectionForm /> : <AskLogin />}/>
            <Route path="/addAssets" element={currentUser ? <AddAssets /> : <AskLogin />}/>
            <Route path="/offRoadAllocations" element={currentUser ? <OffroadAllocations/> : <AskLogin />}/>
            <Route path="/supplierList" element={currentUser ? <SupplierFrame />: <AskLogin />} />
            <Route path="/addContact" element={currentUser ? <AddContact /> : <AskLogin />} />
            <Route path="/codesList" element={currentUser ? <CodesFrame />: <AskLogin />} />
            <Route path="/issuingList" element={currentUser ? <IssuingFrame />: <AskLogin />} />
            <Route path="/pcnData" element={currentUser? <PcnDataFrame /> : <AskLogin />} />
            <Route path="/addPcn" element={currentUser ? <AddPcn /> : <AskLogin />}/>
            <Route path="/currentAllocation" element={currentUser ? <CurrentAllocationFrame /> : <AskLogin />} />
            <Route path="/newHire" element={currentUser ? <NewHireForm /> : <AskLogin />}/>
            <Route path="/conditionReport" element={currentUser ? <ConditionReportForm /> : <AskLogin />} /*onLeave={clearInterval()}*/ />
            <Route path="/payData" element={currentUser ? <PayDataFrame /> : <AskLogin />} />
            <Route path="/pdfView" element={<PdfCameraFrame /> } />
            <Route path="/pdfOff" element={<PdfOffhireFrame /> } />
            <Route path="/statusUpdate" element={currentUser ? <StatusUpdate /> : <AskLogin />} />
            <Route path="/profiles" element={currentUser ? <ProfilesFrame /> : <AskLogin />} />
            <Route path="/offHire" element={currentUser ? <OffHire currentUser={currentUser?.username} />: <AskLogin />} />
            <Route path="/pdfNew" element={<PdfNewFrame />} />
            <Route path="/userHome" element={<UserHome /> } />
            <Route path="/payRequest" element={currentUser ? <PayRequest /> : <AskLogin />} />
            <Route path="/stripePayment" element={<StripeFrame />} />
            <Route path="/paymentConfirmation" element={<PayConfirmation />}/>
            <Route path="/videoUpload" element={<AppQR />} />
            <Route path="/videoList" element={currentUser ? <VideoList /> : <AskLogin />} />
            <Route path="/offTrack" element={currentUser ? <OffTrack /> : <AskLogin />} />
            <Route path="/offUpdate" element={currentUser ? <OffUpdate /> : <AskLogin />} />
            <Route path="/auditTrack" element={currentUser ? <AuditTrack/> : <AskLogin />} />
            <Route path='/returnVehicle' element={currentUser ? <ReturnToSupplier /> : <AskLogin />} />
            <Route path="/tambor-tcs/" element={<TcForm />} />
            <Route path="/newnewHire" element={<NewNewHireForm />} />
            <Route path="/moveForm" element={<VehicleMovementsForm />} />
            <Route path="/movementsView" element={<VehicleMovementsView />} />
          </Routes>
        </div>
        <AuthVerify logOut={this.logOut}/> 
        {/* <Route path="/register" element={<Register />} /> */
        }
      </div>
    );
  }
}

export default App;
