import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import vanLwb from './img/vanLwb.png';
import vanLwba from './img/vanLwba.png';
import vanMwb from './img/vanMwb.png';
import vanMwba from './img/vanMwba.png';
import vanSwb from './img/vanSwb.png';
import vanXlwb from './img/vanXlwb.png';
import active from './img/active.png';
import inactive from './img/inactive.png';
import transitMwb from './img/transitMwb.png';
import SprinterLwb from './img/SprinterLwb.jpg';
import transitLwb from './img/transitLwb.jfif';
import vivaroMwb from './img/vivaroMwb.png';
import trafficMwb from './img/trafficMwb.png';
import transitSwb from './img/transitSwb.png';
import transporterMwb from './img/transporterMwb.png';
import expertMwb from './img/expertMwb.png';
import comboSwb from './img/comboSwb.png';
import fiorinoSwb from './img/fiorinoSwb.jpeg';
import vitoMwb from  './img/vitoMwb.png';
import crafterLwb from './img/crafterLwb.jpg';
import movanoLwb from './img/movanoLwb.jpg';
import dispatchMwb from './img/dispatchMwb.png';
import relayLwb from './img/relayLwb.png';
import boxerLwb from './img/boxerLwb.jpg';
import lutonLuton from './img/lutonLuton.jpg';
import dobloSwb from './img/dobloSwb.jpg';
import berlingoSwb from './img/berlingoSwb.jpg';
import proaceMwb from './img/proaceMwb.jpg';
import masterLwb from './img/masterLwb.jpg';
import partnerMwb from './img/partnerMwb.jpg';
import kangooSwb from './img/kangooSwb.jpg';
import caddySwb from './img/caddySwb.jpg';
import partnerSwb from './img/partnerSwb.jpg';
import primasterMwb from './img/primasterMwb.jpg';
import ducatoLwb from './img/ducatoLwb.jpg';
import scudoMwb from './img/scudoMwb.jpg';
import iloadMwb from './img/iloadMwb.jpg';
import citanSwb from './img/citanSwb.jpg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PDFProfile from './pdfProfile.component';
import { isMobile } from 'react-device-detect';





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );


const SearchProfile = (props) => {
 const [ searchInput, setSearchInput ] = useState("");
 let data =  props.primaryData;
const [ loading, setLoading ] = useState(false);
const [ selected, setSelected ] = useState();
const [ profile, setProfile ] = useState();
const [ timeLn, setTimeLn ] = useState([]);
const [ open, setOpen ] = useState(false);
const [ vehicle, setVehicle ] = useState(); 
const [ log, setLog ] = useState([]);
const [ pdf, setPdf ] = useState();
const [ activityLog, setActivityLog ] = useState([])

useEffect(() =>{
    UserService.postTimeLog().then(response => {
        setLog(response.data)
    }
    ).catch(err => console.log(err))   
    setVehicle(true)
    UserService.getActivities().then(response =>{
        setActivityLog(response.data)
        
    }).catch(err => console.log(err))
}, [])
const handlePdf = (file, heading) =>{
    if(file?.split('/').length == 7){
        setPdf(<PDFProfile link={file}/>)
    }else{
        console.log(heading)
        setPdf(<PDFProfile heading={heading} link={file}/>)
    }
}
const handleShow = (vehicleData) =>{
    
    setOpen(true)
    let reg = { reg: vehicleData?.reg}
    activityLog.map(element =>{
        
        if(element.reg == reg.reg){
            log.push(element)
        }
        
    })
    
    log?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    log?.reverse()
    
 
        log?.map(dataRow =>{  
            
            if(dataRow.vehicle_reg == reg.reg || dataRow.reg == reg.reg){
                console.log(dataRow.heading)
                if(dataRow.file?.split('/').length > 1){ 
                    setTimeLn(oldTimeLn => [...oldTimeLn,
                            /*<TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>*/ 
                                <div style={{borderBottom: '1px solid gray', width: '480px', maxHeight: '200px'}}>                                 
                                <h3 className='subttTT'><b className='bold'><span class="dot"></span>{dataRow? dataRow.createdAt.split(' ')[0].split('-')[2] +'/'+dataRow.createdAt.split(' ')[0].split('-')[1]+'/'+dataRow.createdAt.split(' ')[0].split('-')[0].split('0')[1]+' '+dataRow.createdAt.split(' ')[1] : ''}</b></h3>
                                <div className='flex' style={{paddingLeft: '20px'}}><h3 className='subttTT'>{dataRow? dataRow.last_client ? dataRow.last_client+' - Condition Report' : 'Condition Report' : ''}</h3><button className='buttonP' onClick={e => handlePdf(dataRow.file)}>{isMobile ? 'download pdf' : 'open pdf'}</button></div>
                                </div>  
                                /*</TimelineContent>
                            </TimelineItem>*/
                    ])
                }else if(dataRow.file?.split('/').length == 1 || !dataRow.file ){
                    if(dataRow.heading == 'On Hire'){
                        setTimeLn(oldTimeLn => [...oldTimeLn,
                            /*<TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>*/  
                                <div style={{borderBottom: '1px solid gray', width: '480px', maxHeight: '200px'}}>                               
                                <h3 className='subttTT'><b className='bold'><span class="dot"></span>{dataRow? dataRow.createdAt.split(' ')[0].split('-')[2] +'/'+dataRow.createdAt.split(' ')[0].split('-')[1]+'/'+dataRow.createdAt.split(' ')[0].split('-')[0].split('0')[1]+' '+dataRow.createdAt.split(' ')[1] : ''}</b></h3>
                                <div className='flex' style={{paddingLeft: '20px'}}><h3 className='subttTT'>{dataRow? dataRow.assigned_to+' - '+dataRow.heading : ''}</h3><button className='buttonPP' onClick={e => handlePdf(dataRow.file, dataRow.heading)}>{isMobile ? 'download pdf' : 'open pdf'}</button></div>
                                </div>   
                                /*</TimelineContent>
                            </TimelineItem>*/
                        ])
                    }else if(dataRow.heading == 'Off Road-Condition Report Needed' || dataRow.heading == 'Off Hire'){
                        
                        setTimeLn(oldTimeLn => [...oldTimeLn,
                            /*<TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>*/  
                                <div style={{borderBottom: '1px solid gray', width: '480px', maxHeight: '200px'}}>                                
                                <h3 className='subttTT'><b className='bold'><span class="dot"></span>{dataRow? dataRow.createdAt.split(' ')[0].split('-')[2] +'/'+dataRow.createdAt.split(' ')[0].split('-')[1]+'/'+dataRow.createdAt.split(' ')[0].split('-')[0].split('0')[1]+' '+dataRow.createdAt.split(' ')[1] : ''}</b></h3>
                                <div className='flex' style={{paddingLeft: '20px'}}><h3 className='subttTT' >{dataRow? dataRow.assigned_to+' - '+dataRow.heading?.split('-')[0] : ''}</h3></div>
                                <div className='flex' style={{paddingLeft: '20px'}}><h3 className='subttTT' >{dataRow? dataRow.heading?.split('-')[1] : ''}</h3><button className='buttonPPP' onClick={e => handlePdf(dataRow.file, dataRow.heading)}>{isMobile ? 'download pdf' : 'open pdf'}</button></div>
                                </div>  
                                /*</TimelineContent>
                            </TimelineItem>*/
                        ])
                    }else if(dataRow.heading == 'Condition Report'){
                    }else{
                        
                        setTimeLn(oldTimeLn => [...oldTimeLn,
                            /*<TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>*/   
                                <div style={{borderBottom: '1px solid gray', width: '480px', maxHeight: '200px'}}>                               
                                <h3 className='subttTT'><b className='bold'><span class="dot"></span>{dataRow? dataRow.createdAt.split(' ')[0].split('-')[2] +'/'+dataRow.createdAt.split(' ')[0].split('-')[1]+'/'+dataRow.createdAt.split(' ')[0].split('-')[0].split('0')[1]+' '+dataRow.createdAt.split(' ')[1] : ''}</b></h3>
                                <div className='flex' style={{paddingLeft: '20px'}}><h3 className='subttTT'>{dataRow? dataRow.heading : ''}</h3></div>
                                
                                </div>  
                                /*</TimelineContent>
                            </TimelineItem>*/
                        ])
                    }
                }
            }
        })  
        setTimeLn(oldTimeLn => [...oldTimeLn, /*<TimelineItem>
            <TimelineOppositeContent color="textSecondary">
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>*/
            <div style={{borderBottom: '1px solid gray', width: '480px', maxHeight: '200px'}}>
            <h3 className='subttTT'><b className='bold'><span class="dot"></span>{vehicleData?.supply_date.split('-')[2]+'/'+vehicleData?.supply_date.split('-')[1]+'/'+vehicleData?.supply_date.split('-')[0].split('0')[1]}</b></h3>
            <h3 className='subttTT'>Vehicle collected from supplier</h3>
            </ div>
            /*</TimelineContent>
           
        </TimelineItem>*/])
  
   
    setProfile(
                <div className='marginPic'>
                    <div className='flex'>
                        
                            <img 
                                className='vehiPic'
                                src={vehicleData.model == 'Citan' && vehicleData.class.match('SWB') ? citanSwb :
                                 vehicleData.model == 'Iload' && vehicleData.class.match('MWB') ? iloadMwb : 
                                 vehicleData.model == 'Scudo' && vehicleData.class.match('MWB') ? scudoMwb : 
                                 vehicleData.model == 'Ducato' && vehicleData.class.match('LWB') ? ducatoLwb : 
                                 vehicleData.model == 'Primaster' && vehicleData.class.match('MWB') ? primasterMwb : 
                                 vehicleData.model == 'Partner' && vehicleData.class.match('SWB') ? partnerSwb : 
                                 vehicleData.model == 'Caddy' && vehicleData.class.match('SWB') ? caddySwb : 
                                 vehicleData.model == 'Kangoo' && vehicleData.class == 'SWB'? kangooSwb : 
                                 vehicleData.model == 'Partner' && vehicleData.class == 'MWB' ? partnerMwb :
                                 vehicleData.model == 'Master' && vehicleData.class == 'LWB'? masterLwb : 
                                 vehicleData.model == 'Proace' && vehicleData.class == 'MWB' ? proaceMwb : 
                                 vehicleData.model == 'Berlingo' && vehicleData.class == 'SWB' ? berlingoSwb : 
                                 vehicleData.model == 'Doblo' && vehicleData.class == 'SWB' ? dobloSwb : 
                                 vehicleData.model == 'Luton' && vehicleData.class == 'XLWB' ? lutonLuton : 
                                 vehicleData.model == 'Boxer' && vehicleData.class == 'LWB' ? boxerLwb : 
                                 vehicleData.model == 'Relay' && vehicleData.class == 'LWB' ? relayLwb : 
                                 vehicleData.model == 'Dispatch' && vehicleData.class == 'MWB' ? dispatchMwb : 
                                 vehicleData.model == 'Movano' && vehicleData.class.match('LWB') ? movanoLwb : 
                                 vehicleData.model == 'Crafter' && vehicleData.class.match('LWB') ? crafterLwb : 
                                 vehicleData.model == 'Vito' && vehicleData.class.match('MWB') ? vitoMwb : 
                                 vehicleData.model == 'Fiorino' && vehicleData.class == 'SWB' ? fiorinoSwb : 
                                 vehicleData.model == 'Expert' && vehicleData.class == 'MWB' ? expertMwb : 
                                 vehicleData.model == 'Transit' && vehicleData.class == 'SWB' ? transitSwb : 
                                 vehicleData.model == 'Combo' ? comboSwb : 
                                 vehicleData.model == 'Transporter' && vehicleData.class == 'MWB' ? transporterMwb : 
                                 vehicleData.model == 'Trafic' && vehicleData.class.match('MWB') ? trafficMwb : 
                                 vehicleData.model == 'Vivaro' && vehicleData.class == 'MWB' ? vivaroMwb : 
                                 vehicleData.model == "Sprinter" ? SprinterLwb : 
                                 vehicleData.class.match('MWB') && vehicleData.model == 'Transit' ? transitMwb : 
                                 vehicleData.class.match('LWB') && vehicleData.model == 'Transit' ? transitLwb : 
                                 vehicleData.class == 'LWB'? vanLwb : 
                                 vehicleData.class == 'LWB-A' ? vanLwba : 
                                 vehicleData.class == 'MWB' ? vanMwb : 
                                 vehicleData.class == 'MWB-A' ? vanMwba : 
                                 vehicleData.class == 'SWB' ? vanSwb : 
                                 vehicleData.class == 'XLWB' ? vanXlwb : ''}
                            />
                          
                      
                        <div className='profileDiv'>
                            {isMobile ? '' :<header className="jumbotronl">
                                <h1 className="profileTitle" style={{marginLeft: '70%'}}>Vehicle Profile</h1>
                            </header>} 
                            <div className='flex'>
                                <table className='tableProfile'>
                                    <tr>
                                        <td className='subttX'>Vehicle Registration</td>
                                        <td className='subttXX'>{vehicleData.reg}</td>
                                    </tr>
                                    <tr>
                                        <td className='subttX'>Vehicle Vin</td>
                                        <td className='subttXX'>{vehicleData.vin}</td>
                                    </tr>
                                    <tr>
                                        <td className='subttX'>Vehicle Status</td>
                                        <td className='subttXX'>
                                            <img 
                                                className='andi'
                                                src={vehicleData.status == 'Active' ? active : inactive}
                                            />
                                            &nbsp;&nbsp;
                                            {vehicleData.status == 'Active'? 'Active' : 'Inactive'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='subttX'>Vehicle Make</td>
                                        <td className='subttXX'>{vehicleData.make}</td>
                                    </tr>
                                    <tr>
                                        <td className='subttX'>Vehicle Model</td>
                                        <td className='subttXX'>{vehicleData.model}</td>
                                    </tr>
                                    
                                    
                                </table>
                                {
                                    vehicleData.status == 'Active' ?
                                    <table className='tableProfileD'>
                                         <tr>
                                                <td className='subttX'>Van assingment</td>
                                                <td className='subttXX'>{vehicleData.assignment}</td>
                                            </tr>
                                            <tr>
                                                <td className='subttX'>Current Driver</td>
                                                <td className='subttXX'>{vehicleData.assigned_to}</td>
                                            </tr>
                                            <tr>
                                                <td className='subttX'>Assigned Date</td>
                                                <td className='subttXX'>{vehicleData.assigned_date}</td>
                                            </tr>
                                            <tr>
                                            <td className='subttX'>Deposit</td>
                                                <td className='subttXX'>£{vehicleData.deposit}</td>
                                            </tr>
                                            <tr>
                                                <td className='subttX'>Rate</td>
                                                <td className='subttXX'>£{vehicleData.rate}</td>
                                            </tr>
                                    </table>: ''
                                }
                            </div>
                        </div>
                    </div>
                </div>    
                )
              
}
const handleShowClose = () => {
    setOpen(false)
    setPdf()
    window.location.reload(false)
}
const [stDisplay, setStDisplay ] = useState([]);
let displaiC = [];

if(data){
data?.map((dataRow, index) => {
        displaiC.push(
            <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="150"
                    src={dataRow.model == 'Citan' && dataRow.class.match('SWB') ? citanSwb :
                    dataRow.model == 'Iload' && dataRow.class.match('MWB') ? iloadMwb : 
                    dataRow.model == 'Scudo' && dataRow.class.match('MWB') ? scudoMwb : 
                    dataRow.model == 'Ducato' && dataRow.class.match('LWB') ? ducatoLwb : 
                    dataRow.model == 'Primaster' && dataRow.class.match('MWB') ? primasterMwb : 
                    dataRow.model == 'Partner' && dataRow.class.match('SWB') ? partnerSwb : 
                    dataRow.model == 'Caddy' && dataRow.class.match('SWB') ? caddySwb : 
                    dataRow.model == 'Kangoo' && dataRow.class == 'SWB'? kangooSwb : 
                    dataRow.model == 'Partner' && dataRow.class == 'MWB' ? partnerMwb : 
                    dataRow.model == 'Master' && dataRow.class == 'LWB'? masterLwb : 
                    dataRow.model == 'Proace' && dataRow.class == 'MWB' ? proaceMwb : 
                    dataRow.model == 'Berlingo' && dataRow.class == 'SWB' ? berlingoSwb : 
                    dataRow.model == 'Doblo' && dataRow.class == 'SWB' ? dobloSwb : 
                    dataRow.model == 'Luton' && dataRow.class == 'XLWB' ? lutonLuton : 
                    dataRow.model == 'Boxer' && dataRow.class == 'LWB' ? boxerLwb : 
                    dataRow.model == 'Relay' && dataRow.class == 'LWB' ? relayLwb : 
                    dataRow.model == 'Dispatch' && dataRow.class == 'MWB' ? dispatchMwb : 
                    dataRow.model == 'Movano' && dataRow.class.match('LWB') ? movanoLwb : 
                    dataRow.model == 'Crafter' && dataRow.class == 'LWB' ? crafterLwb : 
                    dataRow.model == 'Vito' && dataRow.class.match('MWB') ? vitoMwb : 
                    dataRow.model == 'Fiorino' && dataRow.class == 'SWB' ? fiorinoSwb : 
                    dataRow.model == 'Expert' && dataRow.class == 'MWB' ? expertMwb : 
                    dataRow.model == 'Transit' && dataRow.class == 'SWB' ? transitSwb : 
                    dataRow.model == 'Combo' ? comboSwb : 
                    dataRow.model == 'Transporter' && dataRow.class == 'MWB' ? transporterMwb : 
                    dataRow.model == 'Trafic' && dataRow.class.match('MWB') ? trafficMwb : 
                    dataRow.model == 'Vivaro' && dataRow.class == 'MWB' ? vivaroMwb : 
                    dataRow.model == "Sprinter" ? SprinterLwb : 
                    dataRow.class.match('MWB') && dataRow.model == 'Transit' ? transitMwb : 
                    dataRow.class.match('LWB') && dataRow.model == 'Transit' ? transitLwb : 
                    dataRow.class == 'LWB'? vanLwb : dataRow.class == 'LWB-A' ? vanLwba : 
                    dataRow.class == 'MWB' ? vanMwb : dataRow.class == 'MWB-A' ? vanMwba : 
                    dataRow.class == 'SWB' ? vanSwb : dataRow.class == 'XLWB' ? vanXlwb : ''}                    
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h4" component="div">
                        {dataRow.reg}
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                        Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Active' ? <img className="andi" src={active} /> : <img className="andi" src={inactive} />}  {dataRow.status}
                    </Typography>
                    
                       
                   
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary" onClick={e=>handleShow(dataRow)}>
                    Show more
                    </Button>
                </CardActions>
            </Card> 
            ) 
   
  })
}
useEffect(()=>{
    setStDisplay(displaiC)
},[displaiC.length > 0])

const [activeX, setActiveX] = useState([]);
const [ buttonLabel, setButtonLabel ] = useState('Active only')
let displayA = [];
const onlyActive = () =>{
    displaiC = []
    findC = []
    displayA = []
    //setActiveX([])
    if(activeX.length > 0){
        setActiveX('') 
        setButtonLabel('Active only')
        console.log(stDisplay)
    }else{
        
    
        data?.map((dataRow, index) => {
            if(dataRow.status == 'Active'){
                displayA.push(
                    <Card sx={{ maxWidth: 345 ,margin: 1, padding: 2}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="150"
                            src={dataRow.model == 'Citan' && dataRow.class.match('SWB') ? citanSwb :
                            dataRow.model == 'Iload' && dataRow.class.match('MWB') ? iloadMwb : 
                            dataRow.model == 'Scudo' && dataRow.class.match('MWB') ? scudoMwb : 
                            dataRow.model == 'Ducato' && dataRow.class.match('LWB') ? ducatoLwb : 
                            dataRow.model == 'Primaster' && dataRow.class.match('MWB') ? primasterMwb : 
                            dataRow.model == 'Partner' && dataRow.class.match('SWB') ? partnerSwb : 
                            dataRow.model == 'Caddy' && dataRow.class.match('SWB') ? caddySwb : 
                            dataRow.model == 'Kangoo' && dataRow.class == 'SWB'? kangooSwb : 
                            dataRow.model == 'Partner' && dataRow.class == 'MWB' ? partnerMwb : 
                            dataRow.model == 'Master' && dataRow.class == 'LWB'? masterLwb : 
                            dataRow.model == 'Proace' && dataRow.class == 'MWB' ? proaceMwb : 
                            dataRow.model == 'Berlingo' && dataRow.class == 'SWB' ? berlingoSwb : 
                            dataRow.model == 'Doblo' && dataRow.class == 'SWB' ? dobloSwb : 
                            dataRow.model == 'Luton' && dataRow.class == 'XLWB' ? lutonLuton : 
                            dataRow.model == 'Boxer' && dataRow.class == 'LWB' ? boxerLwb : 
                            dataRow.model == 'Relay' && dataRow.class == 'LWB' ? relayLwb : 
                            dataRow.model == 'Dispatch' && dataRow.class == 'MWB' ? dispatchMwb : 
                            dataRow.model == 'Movano' && dataRow.class.match('LWB') ? movanoLwb : 
                            dataRow.model == 'Crafter' && dataRow.class == 'LWB' ? crafterLwb : 
                            dataRow.model == 'Vito' && dataRow.class.match('MWB') ? vitoMwb : 
                            dataRow.model == 'Fiorino' && dataRow.class == 'SWB' ? fiorinoSwb : 
                            dataRow.model == 'Expert' && dataRow.class == 'MWB' ? expertMwb : 
                            dataRow.model == 'Transit' && dataRow.class == 'SWB' ? transitSwb : 
                            dataRow.model == 'Combo' ? comboSwb : 
                            dataRow.model == 'Transporter' && dataRow.class == 'MWB' ? transporterMwb : 
                            dataRow.model == 'Trafic' && dataRow.class.match('MWB') ? trafficMwb : 
                            dataRow.model == 'Vivaro' && dataRow.class == 'MWB' ? vivaroMwb : 
                            dataRow.model == "Sprinter" ? SprinterLwb : 
                            dataRow.class.match('MWB') && dataRow.model == 'Transit' ? transitMwb : 
                            dataRow.class.match('LWB') && dataRow.model == 'Transit' ? transitLwb : 
                            dataRow.class == 'LWB'? vanLwb : dataRow.class == 'LWB-A' ? vanLwba : 
                            dataRow.class == 'MWB' ? vanMwb : dataRow.class == 'MWB-A' ? vanMwba : 
                            dataRow.class == 'SWB' ? vanSwb : dataRow.class == 'XLWB' ? vanXlwb : ''}                    
                            alt="green iguana"
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                                {dataRow.reg}
                            </Typography>
                            <Typography variant="h5" color="text.secondary">
                                Status: {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/} {dataRow.status == 'Active' ? <img className="andi" src={active} /> : <img className="andi" src={inactive} />}  {dataRow.status}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={e=>handleShow(dataRow)}>
                            Show more
                            </Button>
                        </CardActions>
                    </Card> 
                ) 
            }
        })
        setActiveX(displayA)
        setButtonLabel('All vehicles')
        }
    
    
}

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    data?.filter((dataRow, index) => {
                if(dataRow.reg?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.status?.toUpperCase() == searchInput.toUpperCase()){
                    findC.push(
                            <Card sx={{ maxWidth: 345 ,margin: 2}} >
                                <CardActionArea>
                                    <CardMedia
                                    component="img"
                                    height="140"
                                    src={dataRow.model == 'Citan' && dataRow.class.match('SWB') ? citanSwb :
                                    dataRow.model == 'Iload' && dataRow.class.match('MWB') ? iloadMwb : 
                                    dataRow.model == 'Scudo' && dataRow.class.match('MWB') ? scudoMwb : 
                                    dataRow.model == 'Ducato' && dataRow.class.match('LWB') ? ducatoLwb : 
                                    dataRow.model == 'Primaster' && dataRow.class.match('MWB') ? primasterMwb : 
                                    dataRow.model == 'Partner' && dataRow.class.match('SWB') ? partnerSwb : 
                                    dataRow.model == 'Caddy' && dataRow.class.match('SWB') ? caddySwb : 
                                    dataRow.model == 'Kangoo' && dataRow.class == 'SWB'? kangooSwb : 
                                    dataRow.model == 'Partner' && dataRow.class == 'MWB' ? partnerMwb : 
                                    dataRow.model == 'Master' && dataRow.class == 'LWB'? masterLwb : 
                                    dataRow.model == 'Proace' && dataRow.class == 'MWB' ? proaceMwb : 
                                    dataRow.model == 'Berlingo' && dataRow.class == 'SWB' ? berlingoSwb : 
                                    dataRow.model == 'Doblo' && dataRow.class == 'SWB' ? dobloSwb : 
                                    dataRow.model == 'Luton' && dataRow.class == 'XLWB' ? lutonLuton : 
                                    dataRow.model == 'Boxer' && dataRow.class == 'LWB' ? boxerLwb : 
                                    dataRow.model == 'Relay' && dataRow.class == 'LWB' ? relayLwb : 
                                    dataRow.model == 'Dispatch' && dataRow.class == 'MWB' ? dispatchMwb : 
                                    dataRow.model == 'Movano' && dataRow.class.match('LWB') ? movanoLwb : 
                                    dataRow.model == 'Crafter' && dataRow.class == 'LWB' ? crafterLwb : 
                                    dataRow.model == 'Vito' && dataRow.class.match('MWB') ? vitoMwb : 
                                    dataRow.model == 'Fiorino' && dataRow.class == 'SWB' ? fiorinoSwb : 
                                    dataRow.model == 'Expert' && dataRow.class == 'MWB' ? expertMwb : 
                                    dataRow.model == 'Transit' && dataRow.class == 'SWB' ? transitSwb : 
                                    dataRow.model == 'Combo' ? comboSwb : 
                                    dataRow.model == 'Transporter' && dataRow.class == 'MWB' ? transporterMwb : 
                                    dataRow.model == 'Trafic' && dataRow.class.match('MWB') ? trafficMwb : 
                                    dataRow.model == 'Vivaro' && dataRow.class == 'MWB' ? vivaroMwb : 
                                    dataRow.model == "Sprinter" ? SprinterLwb : 
                                    dataRow.class.match('MWB') && dataRow.model == 'Transit' ? transitMwb : 
                                    dataRow.class.match('LWB') && dataRow.model == 'Transit' ? transitLwb : 
                                    dataRow.class == 'LWB'? vanLwb : dataRow.class == 'LWB-A' ? vanLwba :
                                    dataRow.class == 'MWB' ? vanMwb : dataRow.class == 'MWB-A' ? vanMwba : 
                                    dataRow.class == 'SWB' ? vanSwb : dataRow.class == 'XLWB' ? vanXlwb : ''}                                   
                                     alt="green iguana"
                                     sx={{className: 'pic'}}
                                    />
                                    <CardContent>
                                    <Typography gutterBottom variant="h4" component="div">
                                        {dataRow.reg}
                                    </Typography>
                                    <Typography variant="h5" color="text.secondary">
                                        Status: {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}{dataRow.status == 'Active' ? <img className="andi" src={active} /> : <img className="andi" src={inactive} />}  {dataRow.status}
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" onClick={e =>handleShow(dataRow)} >
                                        Show more
                                    </Button>
                                </CardActions>
                            </Card>             
                            )
                }
    });
}
useEffect(()=>{
    setActiveX('')
    setButtonLabel('Active only')
}, [findC.length])

return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button><button className='buttonM' onClick={onlyActive}>{buttonLabel}</button>
        {/*<h3 className='availableOffTitleC titleCorrection'>Vehicles Profiles</h3>*/}
    </div>
{ 
findC.length > 0 || searchInput ? 
    <div className='flexProfile'>{findC}</div>:
    activeX ? 
    <div className='flexProfile'>{activeX}</div> 
    :stDisplay.length > 0 ? <div className='flexProfile'>{stDisplay}</div> : 'nothing'  }

    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        //TransitionComponent={Transition}
        keepMounted
        //onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <div /*className={classes.root}*/>
        <Grid container>
            <Grid item xs={12}>
                {profile ? 
                
                <>
                    {profile} 
                    <div className='flex'>
                        <div className='centre' style={{paddingLeft: '10px', width: '480px'}}>
                            <h3 className='subttX' style={{width: '60%'}}>Vehicle Timeline</h3>
                                {timeLn ? timeLn
                                    /*<Timeline
                                        sx={{
                                            [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: 0.2,
                                            }, width: '50%'
                                        }}
                                    >
                                    {timeLn ? timeLn : <CircularProgress />}
                                    </Timeline>*/
                                    :<CircularProgress />}
                                   
                        </div> 
                        { pdf ? pdf : isMobile ? '':<CircularProgress sx={{width: '200px'}}/>}            
                    </div>
                </> 
                
                : <CircularProgress />}
            </Grid>
        </Grid>
        </div>                          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="buttonSS" onClick={handleShowClose}>Close</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    
</div>

)

};

export default SearchProfile;