import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
//import Test from './test';
//import useFetch from './fetch';
//import { getGridCellElement } from '@mui/x-data-grid/utils/domUtils';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
//import BasicTabs from './dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CodesBar = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let url_f = '/allocation';
let data_f = 'url_f)';
 let url_D = '/DVLA';
 let data_D = 'url_D)';
 let url_R = '/RTW';
 let data_R = 'url_R)';
 let url = '/applicants';
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;
 
 useEffect(()=>{
    if(!data_f || !data_D || !data_R || !data){
        setLoading(true)
    }else{
        setLoading(false)
    }
 },[data_f, data_D, data_R, data])

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'code',
      headerName: 'Code',
      headerClassName: 'table-header',
      width: 400,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Dercription',
      headerClassName: 'table-header',
      width: 1200,
      editable: true,
    },
   {
      field: 'level',
      headerName: 'Level',
      headerClassName: 'table-header',
      width: 400,
      editable: true,
    },
    /*{
       field: 'name',
       headerName: 'Name',
       headerClassName: 'table-header',
       width: 350,
       editable: false,
     },
     {
        field: 'reg',
        headerName: 'Reg',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'make',
        headerName: 'Make',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'model',
        headerName: 'Model',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      ,
     {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            console.log(params)
            UserService.updateNotes(params.value, params.row.id)
            //handleOC(params.value, params.row.id)
            return params.value
          }
      },
      {
            field: 'video_uplink',
            headerName: 'Video',
            headerClassName: 'table-header',
            width: 150,
            //editable: true,
            type: 'component',
            renderCell: (params) => (
                <div>{<Test params={params} /></div>
                
              ),
           
          }*/
         /* {
             field: 'action',
             headerName: 'Action',
             headerClassName: 'table-header',
             width: 150,
             editable: true,
             type: 'singleSelect',
             valueOptions: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'],
             valueGetter: (params) => {
              if (!params.value) {
                return params.value;
              }
              console.log(params)
              handleOC(params.value, params.row.id)
              return params.value
            },
           },
           {
              field: 'action_by',
              headerName: 'Action by',
              headerClassName: 'table-header',
              width: 150,
              editable: true,
              type: 'singleSelect',
              valueOptions: ['Filmer', 'Dan', 'Garth', 'Ewelina', 'Ventsi', 'Nick', 'Ben', 'Hannah', 'Maddison'],
              valueGetter: (params) => {
                if (!params.value) {
                  return params.value;
                }
            
                handleBy(params.value, params.row.id)
                return params.value
              },
            }*/
  ];
  /*function handleBy(value, id){
    let dataH = {};
    let dataCheck = dataH;
    dataH = {option: value, id: id}
    console.log(dataH)
    if(dataCheck == dataH){ 
      console.log('same dataH')
    }else{
      fetch('https://node.tambor.uk:9000/updateBy', {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin":"*",
          "Accept": "application/json",
          "Content-Type": "application/json"
          },
        body: JSON.stringify(dataH),
      }).then((response) => response.json())
        .then((dataH) => {
                console.log(dataH);
        }).catch(err =>console.log(err));
      }
      data?.map(dataRow => {
        if(dataRow.primary == id){
          dataRow.action_by = value;
        }  
       });
  }
  function handleOC(value, id){
    let dataH = {};
    let dataCheck = dataH;
    dataH = {option: value, id: id}
    console.log(dataH)
    if(dataCheck == dataH){ 
      console.log('same data')
    }else{
      fetch('https://node.tambor.uk:9000/updateVideo', {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin":"*",
          "Accept": "application/json",
          "Content-Type": "application/json"
          },
        body: JSON.stringify(dataH),
      }).then((response) => response.json())
        .then((dataH) => {
                console.log(dataH);
        }).catch(err =>console.log(err));
      }
     data?.map(dataRow => {
      if(dataRow.primary == id){
        dataRow.action = value;
      }  
     });
  }*/

let displaiC = [];
if(data){
data?.map(dataRow => {
  
        displaiC.push(
                {id: dataRow.id, code: dataRow.Code , description: dataRow.Description , level: dataRow.Level}
        ) 
        })  
}

const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {
        let reg = '';
        /*data_f?.map(row => {
            if(dataRow.Name == row.Customer_Name){
                reg = row.Reg
            }
        })*/
                if(dataRow.Code?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Description?.toUpperCase().match(searchInput.toUpperCase()) || dataRow.Level?.toUpperCase().match(searchInput.toUpperCase())){
                
                    
                    findC.push(
                        {id: dataRow.id, code: dataRow.Code , description: dataRow.Description , level: dataRow.Level}
                        )
                }
            
        
});

}
/*const [open, setOpen] = useState(false);
const handleClickOpen = () => {
    setOpen(true);
};
const handleClose = () => {
    setOpen(false);
}; 
const [prm, setPrm] = useState();
const [name, setName] = useState();
const [hire, setHire] = useState();
function handleRow(params){
    console.log(params)
    setPrm(params.row.id);
    handleClickOpen();
    setName(params.row.name);
   
    data?.map(dataRow => {
        if(dataRow.Name == name){
            setHire(dataRow.Vehicle_Hire)
        }
    })
}*/
const handleDelete = () =>{
  console.log(selected)
  UserService.deleteCodes(selected)
  setTimeout(()=>{
    window.location.reload(true)
  }, 500)
}
let dataH = {};
const handleCellEdit = (params) =>{
    console.log(params);
   dataH = {id: params.id, Code: params.code, Description: params.description, Level: params.level}
   console.log(dataH)
      UserService.updateCodes(dataH)
      setTimeout(()=>{
        window.location.reload(true)
      }, 200)
     return params;
  }
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = useState();
  const [description, setDescription] = useState();
  const [level, setLevel] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
   
    setOpen(false);
  };
 
  const handleCode = (e) =>{
    setCode(e.target.value);
  }
  const handleDescription = (e) =>{
    setDescription(e.target.value)
  }
  const handleLevel = (e) =>{
    setLevel(e.target.value)
  }
  let dataSend = {}
  const handleSubmit = () =>{
    dataSend = {Code: code, Description: description, Level: level}
    console.log(dataSend);
    UserService.insertCode(dataSend)
    .then(response => {
        this.setState({checkSubmit: true}) 
        console.log(response)
    }).catch(err => {
        this.setState({checkSubmit: false})
        console.log(err)
    })
    handleClose();
    setTimeout(()=>{
        window.location.reload(true)
    }, 200)
  }

return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
        <button type='button' className='buttonDel' onClick={handleDelete}
        >Delete selected</button>
        <button type='button' className='buttonDel' onClick={handleClickOpen}
        >+ New Entry</button><h3 className='availableOffTitle titleCorrectionR'>Codes</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      
    }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={findC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        sx={{fontSize: '16px', fontFamily: ''}}
        pageSizeOptions={[100]}
        //onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        loading={loading}
        {...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>: <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
        
        }}}>
        <DataGrid
        checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        processRowUpdate={handleCellEdit}
        />
    </Box>}
    <React.Fragment>
      
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"New Entry"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
                <div className="inlineLnI">
                    <label for='code' className="eCorrection">Code<span className="req">(Require)</span></label>
                    <input type="text" name='code' className="twoIn2" placeholder={'Enter new code'} onChange={e => handleCode(e)}  />
                </div>
                <div className="inlineLnI">
                    <label for='description' className="eCorrection">Description<span className="req">(Require)</span></label>
                    <input type="textarea" name='description' className="twoIn2"  placeholder={'Description'} onChange={e => handleDescription(e)}  />
                </div>
                <div className="inlineLnI">
                    <label for='level' className="eCorrection">Level<span className="req">(Require)</span></label>
                    <input type="textarea" name='level' className="twoIn2"  placeholder={'Level'} onChange={e => handleLevel(e)}  />
                </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>Submit</Button>
            <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
</div>
)

};

export default CodesBar;