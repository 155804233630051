import React from "react";
import { useState, useEffect } from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const TcForm = () =>{
    const [step, setStep] = useState(1)

    const query = new URLSearchParams(window.location.search);
    const skNo = query.get('sk_no')

    return(
        <div className="correctionT" style={{width: '80%', marginLeft:'10%'}}>
            <h3>Step {step} of 2</h3>
            <BorderLinearProgress variant="determinate" value={50} />
            <h2 className="subttT">Personal Details</h2>
            <div className="inlineLnT">
                    <label for='name' className="">Name <span className="req">(Require)</span></label>
                    <input type="text" name='name' className="twoIn4" /*onChange={e => handleVin(e)} defaultValue={this.state.qr}*/ />
            </div>
            <div className="inlineLnT">
                    <label for='address' className="">Address <span className="req">(Require)</span></label>
                    <input type="text" name='address' className="twoIn4" /*onChange={e => handleVin(e)} defaultValue={this.state.qr}*/ />
                    <h5>Name/Number and Street</h5>
            </div>
            <div className="inlineLnT">
                    <input type="text" name='address' className="twoIn4" /*onChange={e => handleVin(e)} defaultValue={this.state.qr}*/ />
                    <h5>Address Line 2</h5>
            </div>
            <div className="flexAssets">
                <div className="inlineLnI">
                    <input type="text" name='town' className="twoIn4 correctionFieldsMobile3" /*value={this.state.vehicleData[0]?.reg} onChange={e => handleRate(e)}*/  />
                    <h5>Town/City</h5>
                </div>
                <div className="inlineLnI">
                    <input type="text" name='county' className="twoIn4 correctionFieldsMobile3" /*value={this.state.vehicleData[0]?.make} onChange={e => handleDeposit(e)}*/  />
                    <h5>County</h5>
                </div>
            </div>
        </div>
    )
}
export default TcForm;