import React from "react";
import { useState, useEffect, useSearchParams } from "react";
import PDFViewerCamera from "./pdfViewCamera.component";

const PdfCameraFrame = () =>{
    const query = new URLSearchParams(window.location.search);
    const fileName = query.get('fileName')
    console.log(fileName)
    return(
        <div>
            <PDFViewerCamera file={fileName} />
        </div>
    )
}
export default PdfCameraFrame;