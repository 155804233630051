import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Box from '@mui/material/Box';
import { DataGrid, useGridApiEventHandler, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import columns from "./viewHeaders";
import SearchIcon from '@mui/icons-material/Search';


const VehicleMovementsView = () =>{
    const [loading, setLoading] = useState(false);
    const [ trips, setTrips ] = useState()
    const [ tripRows, setTripRows ] = useState([])
    const [ searchInput, setSearchInput ] = useState()

    useEffect(()=>{
        setLoading(true)
        UserService.getTrips().then(
            response =>{
                setLoading(false)
                setTrips(response.data)
            }
        )
    },[])

    useEffect(() =>{
        let data = []
        trips?.map(row =>{
            data.push({id: row.entry, reg: row.reg, driver: row.name, startTime: row.start_time?.split('T')[0]?.split('-')[2]+'/'+row.start_time?.split('T')[0]?.split('-')[1]+'/'+row.start_time?.split('T')[0]?.split('-')[0]+'  '+row.start_time?.split('T')[1]?.split(':')[0]+':'+row.start_time?.split('T')[1]?.split(':')[1], startCoordinates: row.start_coordinates, endTime: row.end_time ? row.end_time?.split('T')[0]?.split('-')[2]+'/'+row.end_time?.split('T')[0]?.split('-')[1]+'/'+row.end_time?.split('T')[0]?.split('-')[0]+'  '+row.end_time?.split('T')[1]?.split(':')[0]+':'+row.end_time?.split('T')[1]?.split(':')[1] : '', endCoordinates: row.end_coordinates, notes: row.notes})
        })
        setTripRows(data)
    }, [trips])

    const handleSearch = () =>{
        let data = []
        trips?.map(row =>{
            if(row.reg.toUpperCase().match(searchInput?.toUpperCase())){
                data.push({id: row.entry, reg: row.reg, driver: row.name, startTime: row.start_time?.split('T')[0]?.split('-')[2]+'/'+row.start_time?.split('T')[0]?.split('-')[1]+'/'+row.start_time?.split('T')[0]?.split('-')[0]+'  '+row.start_time?.split('T')[1]?.split(':')[0]+':'+row.start_time?.split('T')[1]?.split(':')[1], startCoordinates: row.start_coordinates, endTime: row.end_time ? row.end_time?.split('T')[0]?.split('-')[2]+'/'+row.end_time?.split('T')[0]?.split('-')[1]+'/'+row.end_time?.split('T')[0]?.split('-')[0]+'  '+row.end_time?.split('T')[1]?.split(':')[0]+':'+row.end_time?.split('T')[1]?.split(':')[1] : '', endCoordinates: row.end_coordinates, notes: row.notes})
            }
        }) 
        setTripRows(data)              
    }

    return(
        <div style={{marginTop: '100px', marginLeft: '30px'}}>
            <h3 style={{marginBottom: '-50px', color: 'gray'}}>Vehicle Movements</h3>
            <input
            type="search"
            placeholder="Search reg"
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
            style={{marginRight: '10px', height: '35px', marginTop:'70px', marginBottom:'10px'}}
            />
            <button type='button' className='button' style={{marginTop:'70px'}} onClick={handleSearch}
            ><SearchIcon/></button>
            
            
            <Box sx={{ height: 700, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
            bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
            }}}>
                <DataGrid
                
                //onRowSelectionModelChange={params => setSelected(params)}
                rows={tripRows}
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    },
                }}
                sx={{fontSize: '16px', fontFamily: ''}}
                pageSizeOptions={[100]}
                //onRowClick={params => handleRow(params)}
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                //{...data}
                //processRowUpdate={handleCellEdit}
                />
            </Box>
        </div>
    )
}
export default VehicleMovementsView;