import React, { Component, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import Select from 'react-select';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '10%', fontSize: 'x-large', marginLeft:'15px', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
  menu: base => ({
    ...base,
    zIndex: 100,
    width: '100%',
    paddingLeft:'1%',
    marginLeft:'15px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'white',
      color: 'rgb(97, 97, 97)',
      cursor: isDisabled ? 'not-allowed' : 'default',
     
      fontSize: 'x-large',
    };
  },
}
const colourStylesS = {
  control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
  menu: base => ({
    ...base,
    zIndex: 100,
    width: '100%',
    paddingLeft:'1%',
    marginLeft:'0px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'white',
      color: 'rgb(97, 97, 97)',
      cursor: isDisabled ? 'not-allowed' : 'default',
     
      fontSize: 'x-large',
    };
  },
  
}
const classOption= [
  {value: 'LWB', label: 'LWB'},
  {value: 'LWB-A', label: 'LWB-A'},
  {value: 'MWB', label:'MWB'},
  {value: 'MWB-A', label: 'MWB-A'},
  {value: 'SWB', label: 'SWB'},
  {value: 'XLWB', label: 'XLWB'}
]
const steps = [
  'Select a customer',
  'Select a van',
  'Select a date and time',
];
let optionsl = [];
let optionsCollection = [];
let validationOptions = [];
let optionsOther = [];
export default class CollectionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      uDetails:"",
      uAppointments: "",
      uAssets: "",
      steps: 0,
      value: new Date(),
      view: 'list',
      times: [],
      dateSelected: null,
      timeSelected: null,
      check: false,
      thank: false,
      optionStateClass: "",
      defaultValueClass: "",
      make: "",
      model: "",
      class: "",
      reg: "",
      tStation:"",
      validationApp: "",
      type: "",
      allAssets: "",
      phone: "",
      station: "",
      email: ""
    };
  }

  componentDidMount() {
    UserService.getApplicants().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getAssets().then(
      response => {
        this.setState({
          uAssets: response.data
        });
      },
      error => {
        this.setState({
          uAssets:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getAppointmentView().then(
      
      response => {
        console.log(response.data)
        this.setState({
          uAppointments: response.data
        });
      },
      error => {
        this.setState({
          uAppointments:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getValidationApp().then(response =>{
      this.setState({validationApp: response.data})
    })
     UserService.getAllAssets().then(response =>{
      this.setState({allAssets: response.data})
     })
  }
  render() {
    //this.setState({dateSelected: new Date()})
    console.log(this.state.content)
    optionsCollection = [];
    validationOptions = [];
    let replacer = this.state.content;
    console.log(this.state.allAssets)
    let alllen = this.state.allAssets.length;
    for(let i = 0; i < replacer.length; i++){
      let check = false
      for(let j = 0; j < alllen; j++){
        if(this.state.allAssets[j].assigned_to == replacer[i].name){
          check = true
        }
      }
      //if(check == false){
        optionsCollection.push({value: `${replacer[i].name}`, label: `${replacer[i].name}`})
      //}else{
        optionsOther.push({value: `${replacer[i].name}`, label: `${replacer[i].name}`})
      //}
    }
    for(let i = 0; i < this.state.validationApp.length; i++){
      validationOptions.push({value: this.state.validationApp[i], label: this.state.validationApp[i]})
    }
    //customer change handler
    const handleCustomerChange = (e) =>{
      console.log(e.value)
      this.setState({
        
      })
     
      for(let i = 0; i < replacer.length; i++){
        if(replacer[i].name == e.value && this.state.type == 'Collection'){
          this.setState({uDetails: {name: e.value ,phone: replacer[i].phone_no, email: replacer[i].email, station: replacer[i].assigned_location }})
          this.setState({steps: 1})
        }else if(replacer[i].name == e.value && this.state.type !== 'Collection'){
          for(let j = 0; j < alllen; j++){
            if(e.value == this.state.allAssets[j].assigned_to){
              this.setState({uDetails: {name: e.value ,phone: replacer[i].phone_no, email: replacer[i].email, station: replacer[i].assigned_location,  vClass: this.state.allAssets[j].class, vReg: this.state.allAssets[j].reg, vMake: this.state.allAssets[j].make, vModel: this.state.allAssets[j].model}})
            }
          }
        }
      }
     
    }

   // const urlSearchString = window.location.search;
    //const params = new URLSearchParams(urlSearchString);
      // this.setState({tStation: params.get('station')});
    
    //date change handler
   
    const dateChange = async (e) => {
      await this.setState({times: []})
      this.setState({check: false});
      const appointment = this.state.uAppointments;
      const elements = [<ToggleButton value="09:30" aria-label="list">
                        09:30-10:15
                      </ToggleButton>, 
                      <ToggleButton value="10:15" aria-label="list">
                        10:15-11:00
                      </ToggleButton>,
                      <ToggleButton value="11:00" aria-label="second">
                        11:00-11:45
                      </ToggleButton>,
                      <ToggleButton value="11:45" aria-label="second">
                        11:45-12:30
                      </ToggleButton>,
                      <ToggleButton value="12:30" aria-label="third">
                        12:30-13:15
                      </ToggleButton>,
                      <ToggleButton value="13:15" aria-label="list">
                        13:15-14:00
                      </ToggleButton>,
                      <ToggleButton value="14:00" aria-label="list">
                        14:00-14:45
                      </ToggleButton>,
                      <ToggleButton value="14:45" aria-label="list">
                        14:45-15:30
                      </ToggleButton>
                      ]
                      
      let date = e;                
      await this.setState({value: e})
      await this.setState({dateSelected: new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1)+'-'+new Date(e).getDate()})
      
  
      if(new Date(e).getTime() <= new Date().getTime()-86400000 || new Date(e).getDay() == 6 || new Date(e).getDay() == 0 ){
        this.setState({times: []})
      }else{
        for(let i = 0; i < elements.length; i++){
          let check = false;
          let count = 0;
          for(let j = 0; j < appointment.length; j++){
            
            if(new Date(e).getTime() == (new Date(appointment[j].Date.split('T')[0]).getTime()-3600*1000)){
              console.log(new Date(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1)+'-'+new Date(e).getDate())+'-------'+ new Date(new Date(appointment[j].Date.split('T')[0]).getTime()-60*60*1000))
              
              if(elements[i].props.value == appointment[j].Date.split('T')[1].split(':')[0]+':'+appointment[j].Date.split('T')[1].split(':')[1]){
            
                count += 1
                //check = true;
              }
              //console.log(count)
              if(count == 1){
                check = true
              }
            }            
          }
          if(check == false){
            this.setState(prevState =>({times:[...prevState.times, elements[i] ]}))
          }
        }
        
      }
    }
    
    
    //buttons handler
    const handleChange = async (event, nextView) => {
      this.setState({view: nextView});
      
      this.setState({steps: 3})
      this.setState({check: true})
      await this.setState({timeSelected: event.target.value})

    };

    //submit handler
    const handleSubmit = () => {

      let date = this.state.dateSelected+' '+this.state.timeSelected;
      UserService.insertCollection(date, this.state.station ? this.state.station : this.state.uDetails.station, this.state.uDetails.name, this.state.phone ? this.state.phone : this.state.uDetails.phone, this.state.email ? this.state.email : this.state.uDetails.email, this.state.type == 'Collection'? this.state.reg : this.state.uDetails.vReg, this.state.type == 'Collection'? this.state.make : this.state.uDetails.vMake, this.state.type == 'Collection'? this.state.model : this.state.uDetails.vModel, this.state.type).catch(err => console.log(err))
      let data = {reg: this.state.reg, assignment: 'Reserved', assigned_to: this.state.uDetails.name}
      UserService.updateAssetsApp(data).then(response => console.log(response)).catch(err => console.log(err))
      this.setState({thank: true})
    }
    
    //vehicle class change handler
    let optionClass = [];
    const hadnleClassChange = (e) =>{
      this.setState({defaultValueClass: {value: "Select...", label: "Select..."}})
      this.setState({class: e.value})
      this.setState({make: ""})
      this.setState({model: ""})
      optionClass = [];
      let replacer = this.state.uAppointments;
      let checkReg = false;
      //this.setState({optionStateClass: optionClass})
      let available = this.state.uAssets;
      console.log(available)
      for(let i = 0; i < available.length; i++){
        
        for(let j = 0; j < replacer.length; j++){
          if(replacer[j].Reg == available[i].reg){
            checkReg = true;
          }
        }
          if(available[i].class == e.value /*&& !checkReg*/){
            optionClass.push({value: `${available[i].reg}`, label: `${available[i].reg}`})
          }
          checkReg = false
      }
      this.setState({optionStateClass: optionClass})
      this.setState({steps: 2})
    }
    const handleRegChange = (e) =>{
      let available = this.state.uAssets;
      this.setState({defaultValueClass: {value: e.value, label: e.value}})
      for(let i = 0; i < available.length; i++){
        if(e.value == available[i].reg){
          this.setState({make: available[i].make})
          this.setState({model: available[i].model})
          this.setState({reg: e.value})
        }
      }
    }

    const handleType = (e) =>{
      this.setState({type: e.value})
      this.setState({uDetails: ''})
    }
    return (
      <div>
      { this.state.thank ? <h1 className="thankForm">Thank you for submitting the form!!</h1> :
      <div className="containerf containerfCorrection mobileContainerCorrection">
        <header className="jumbotronl">
        <h1 className="collectionTitle">Appointment Booking</h1>
        <Box sx={{ width: '100%', fontSize: 'x-large' }} className="hide">
          <Stepper activeStep={this.state.steps} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        </header>
        
          <div>
          <h2 className="subtt">Appointment Type</h2>
              <div className="inlineLnx">
                <h3 className="customerName correctionFieldsMobile">Type <span className="req">(Require)</span></h3>
                <Select 
                    options={validationOptions}
                    styles={colourStyles}
                    onChange={e => handleType(e)}
                  />
              </div>
            <h2 className="subtt">Customer</h2>
              <div className="inlineLnx">
                <h3 className="customerName correctionFieldsMobile">Customer Name <span className="req">(Require)</span></h3>
                <Select 
                    options={this.state.type == 'Collection' ? optionsCollection : optionsOther}
                    styles={colourStyles}
                    onChange={e => handleCustomerChange(e)}
                    defaultValue={this.state.uDetails.name}
                  />
              </div>
            <div className="flex inlineCorrection">
              <div className="inlineLnI">
                <label for='Phone' className="correctionFieldsMobile">Phone <span className="req ">(Require)</span></label>
                <input type="text" name='Phone' className="twoIn1 correctionFieldsMobile" defaultValue={this.state.uDetails.phone} onChange={e => this.setState({phone : e.target.value})}/>
              </div>
              <div className="inlineLnI">
                <label for='Email' className="eCorrection">Email <span className="req">(Require)</span></label>
                <input type="text" name='Email' className="twoIn2 correctionFieldsMobile2" defaultValue={this.state.uDetails.email} onChange={e => this.setState({email : e.target.value})}/>
              </div>
              
            </div>
            <div className="stationIn">
                <label for='Station' className="correctionFieldsMobile">Station <span className="req">(Require)</span></label>
                <input type="text" name='Station' id='station' className="inputStat correctionFieldsMobile" defaultValue={this.state.uDetails.station} onChange={e => this.setState({station: e.target.value})}/>
            </div>
            {this.state.type == 'Collection' ? 
              <>
                <h2 className="subttV">Vehicle</h2>   
                <h3 className="customerName correctionFieldsMobile">Van Class<span className="req">(Require)</span></h3>
                <Select 
                    options={classOption}
                    styles={colourStyles}
                    onChange={e => hadnleClassChange(e)}
                  /> 
                <div className="flex inlineCorrection">
                  <div className="inlineLnI">
                    <label for='Phone' className="correctionFieldsMobile">Vehicle Reg <span className="req">(Require)</span></label>
                    <Select 
                    options={this.state.optionStateClass}
                    styles={colourStylesS}
                    onChange={e => handleRegChange(e)}
                    value={this.state.defaultValueClass}
                    className="correctionFieldsMobile correctionReg"
                  /> 
                  </div>
                  <div className="inlineLnI">
                    <label for='Email' className="eCorrection">Make <span className="req">(Require)</span></label>
                    <input type="text" name='Email' className="twoIn2 correctionFieldsMobile2" defaultValue={this.state.make} />
                  </div>
                  
                </div>
                <div className="stationIn">
                    <label for='Station' className="correctionFieldsMobile">Model <span className="req">(Require)</span></label>
                    <input type="text" name='Station' className="inputStat correctionFieldsMobile" defaultValue={this.state.model} />
                </div>
              </> : this.state.type && this.state.type !== 'Collection'?
              <>
                <h2 className="subttV">Vehicle</h2>   
                  <div className="stationIn">
                    <label for='class' className="correctionFieldsMobile">Vehicle Class <span className="req">(Require)</span></label>
                    <input type="text" name='class' id='station' className="inputStat correctionFieldsMobile" defaultValue={this.state.uDetails.vClass} />
                  </div>
                  <div className="flex inlineCorrection">
                    <div className="inlineLnI">
                      <label for='Reg' className="correctionFieldsMobile">Vehicle Reg <span className="req ">(Require)</span></label>
                      <input type="text" name='Reg' className="twoIn1 correctionFieldsMobile" defaultValue={this.state.uDetails.vReg}/>
                    </div>
                    <div className="inlineLnI">
                      <label for='Make' className="eCorrection">Make <span className="req">(Require)</span></label>
                      <input type="text" name='Make' className="twoIn2 correctionFieldsMobile2" defaultValue={this.state.uDetails.vMake} />
                    </div>
                  </div>
                  <div className="stationIn">
                    <label for='model' className="correctionFieldsMobile">Model <span className="req">(Require)</span></label>
                    <input type="text" name='model' id='station' className="inputStat correctionFieldsMobile" defaultValue={this.state.uDetails.vModel} />
                  </div>
              </> : ''
            }
            <h2 className="subttV">Available Date and Time</h2>   
            <div className="flex inlineCorrection">
              <div className="calendar calendarCorrection">
                <Calendar  
                  onChange={e => dateChange(e)} 
                  value={this.state.value} 
                  validRange = {{
                    start: new Date(),
                    end: null
                  }}
                  className={'calendarX'}
                  />
              </div>
              <div className="timeDisplay">
                <ToggleButtonGroup
                  orientation="vertical"
                  value={this.state.view}
                  exclusive
                  onChange={event => handleChange(event)}
                  className="buttonsTog"
                >
                {this.state.times}
                </ToggleButtonGroup>
              </div>
             {this.state.timeSelected && this.state.dateSelected && this.state.check?
              <div className="summaryBox">
                <h2 className="summary">{this.state.type} Booking Summary</h2>
                <div className="flex ">
                  <table>
                    <tr><th className="summaryTh">Customer</th><td className="summaryTd">{this.state.uDetails.name}</td></tr>
                    <tr><th className="summaryTh">Phone Number</th><td className="summaryTd">{this.state.uDetails.phone}</td></tr>
                    <tr><th className="summaryTh">Email</th><td className="summaryTd">{this.state.uDetails.email}</td></tr>
                    <tr><th className="summaryTh">Station</th><td className="summaryTd">{this.state.uDetails.station}</td></tr>
                  </table>
                  <table className="summaryTable">
                    <tr><th className="summaryTh">Vehicle Class</th><td className="summaryTd">{this.state.type == 'Collection'? this.state.class : this.state.uDetails.vClass}</td></tr>
                    <tr><th className="summaryTh">Make</th><td className="summaryTd">{this.state.type == 'Collection' ? this.state.make: this.state.uDetails.vMake}</td></tr>
                    <tr><th className="summaryTh">Reg</th><td className="summaryTd">{this.state.type == 'Collection'? this.state.reg : this.state.uDetails.vReg}</td></tr>
                    <tr><th className="summaryTh">Model</th><td className="summaryTd">{this.state.type == 'Collection'? this.state.model : this.state.uDetails.vModel}</td></tr>
                   
                  </table>
                  
                </div>
                <div className="summaryFoot flex">
                  <div>
                    <h3 className="summaryF">Date: {this.state.dateSelected.split('-')[2]+'-'+this.state.dateSelected.split('-')[1]+'-'+this.state.dateSelected.split('-')[0]}</h3>
                    <h3 className="summaryF">Time: {this.state.timeSelected}</h3>
                  </div>
                  <div className="submitButton">
                    {this.state.type && this.state.uDetails.name && (this.state.reg || this.state.uDetails.vReg) && this.state.dateSelected && this.state.timeSelected ? <button type="button" className="submitButtonn" onClick={handleSubmit}>Submit</button> : <button type="button" className="submitButtonn" style={{backgroundColor: 'gray'}}>Submit</button>}
                  </div>
                </div>
                
              </div> : ''
              }
            </div>
        </div>
      </div>}
      </div>
    );
  }
}
