import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://node.tambor.uk:9000/api/test/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  getAppointmentView() {
    return axios.get(API_URL + 'appointment/view', { headers: authHeader() });
  }
  updateNotes(note, id){
    return axios.post(API_URL + 'appointment/updateNotes', {note: note, id: id}, { headers: authHeader()})
  }
  insertCollection(Date, Station, Name, Phone, Email, Reg, Make, Model, type){
    return axios.post(API_URL + 'appointment/insertCollection', {Date: Date, Station: Station, Name: Name, Phone: Phone, Email: Email, Reg: Reg, Make: Make, Model: Model, type: type}, { headers: authHeader()})
  }
  deleteRows(data){
    return axios.post(API_URL + 'appointment/deleteSelected', data, { headers: authHeader()})
  }
  getApplicants() {
    return axios.get(API_URL + 'appointment/applicants', { headers: authHeader() });
  }
  getAssets() {
    return axios.get(API_URL + 'appointment/assets', { headers: authHeader() });
  }
  getOffRoadAssets() {
    return axios.get(API_URL + 'allocations/offroadAssets', { headers: authHeader() });
  }
  getWaitingList() {
    return axios.get(API_URL + 'allocations/waitingList', { headers: authHeader() });
  }
  getSuppliers() {
    return axios.get(API_URL + 'vehicle/suppliers', { headers: authHeader() });
  }
  getVehicleModels() {
    return axios.get(API_URL + 'vehicle/models', { headers: authHeader() });
  }
  insertAsset(date, reg, model, Class, vin, service_date, service_mileage, tyre_size, rate, deposit, supplier, insurance, capacity, insuranceExp, ccAccount, dcAccount, clientName, pl, git){
    return axios.post(API_URL + 'vehicle/insertAsset', {date: date, reg: reg, model: model, class: Class, vin: vin, service_date: service_date, service_mileage: service_mileage, tyre_size: tyre_size, rate: rate, deposit: deposit, supplier: supplier, insurance: insurance, capacity: capacity, insuranceExp: insuranceExp, ccAccount: ccAccount, dcAccount: dcAccount, clientName: clientName, pl: pl, git: git}, { headers: authHeader()})
  }
  updateSupplier(data){
    return axios.post(API_URL + 'supplier/updateSupplier', data, {headers: authHeader()})
  }
  deleteSuppliers(entries){
    return axios.post(API_URL + 'delete/deleteSuppliers', {rows: entries}, { headers: authHeader()})
  }
  insertSuppliers(data){
    return axios.post(API_URL + 'contact/insertSuppliers', data, { headers: authHeader()})
  }
  getCodes() {
    return axios.get(API_URL + 'pcn/getCodes', { headers: authHeader() });
  }
  deleteCodes(entries){
    return axios.post(API_URL + 'delete/codes', {rows: entries}, { headers: authHeader()})
  }
  updateCodes(data){
    return axios.post(API_URL + 'pcn/updateCodes', data, {headers: authHeader()})
  }
  insertCode(data){
    return axios.post(API_URL + 'pcn/insertCode', data, { headers: authHeader()})
  }
  getIssuing() {
    return axios.get(API_URL + 'pcn/getIssuing', { headers: authHeader() });
  }
  updateIssuing(data){
    return axios.post(API_URL + 'pcn/updateIssuing', data, {headers: authHeader()})
  }
  insertIssuing(data){
    return axios.post(API_URL + 'pcn/insertIssuing', data, { headers: authHeader()})
  }
  getPcnData() {
    return axios.get(API_URL + 'pcn/pcnData', { headers: authHeader() });
  }
  getAllApplicants() {
    return axios.get(API_URL + 'pcn/allApplicants', { headers: authHeader() });
  }
  insertPCN(data){
    return axios.post(API_URL + 'pcn/insertPCN', data, { headers: authHeader()})
  }
  getCurrentAllocation() {
    return axios.get(API_URL + 'allocation/currentAllocations', { headers: authHeader() });
  }
  getVehicle(data){
    return axios.post(API_URL + 'vehi/getVehicleData', data, { headers: authHeader()})
  }
  getDrivers() {
    return axios.get(API_URL + 'vehi/drivers', { headers: authHeader() });
  }
  insertAllocationLog(data){
    return axios.post(API_URL + 'vehi/insertLog', data, {headers: authHeader()})
  }
  updateAssets(data){
    return axios.post(API_URL + 'vehi/updateAssets', data, {headers: authHeader()})
  }
  getEmails() {
    return axios.get(API_URL + 'vehi/drivers', { headers: authHeader() });
  }
  conditionAsset(data){
    return axios.post(API_URL + 'vehi/conditionAssets', data, {headers: authHeader()})
  }
  getLive() {
    return axios.get(API_URL + '/liveview', { headers: authHeader() });
  }
  getLive() {
    return axios.get(API_URL + '/getLive', { headers: authHeader() });
  }
  getPayData() {
    return axios.get(API_URL + 'pay/payData', { headers: authHeader() });
  }
  getFormat() {
    return axios.get(API_URL + '/format', { headers: authHeader() });
  }
  startLive(){
    return axios.get(API_URL + '/liveview', { headers: authHeader() });
  }
  insertConditionLog(data){
    return axios.post(API_URL + 'vehi/insertConditionLog', data, {headers: authHeader()})
  }
  getAccount(data){
    return axios.post(API_URL + 'vehi/getAccount', data, {headers: authHeader()})
  }
  getReturn(){
    return axios.get(API_URL + 'vehi/return', { headers: authHeader() });
  }
  postReturn(data){
    return axios.post(API_URL + 'vehi/subReturn', data, {headers: authHeader()})
  }
  postReturnLog(data){
    return axios.post(API_URL + 'vehi/subReturnLog', data, {headers: authHeader()})
  }
  postActivityReturn(data){
    return axios.post(API_URL + 'vehi/activityReturn', data, {headers: authHeader()})
  }
  postActivityNew(data){
    return axios.post(API_URL + 'vehi/activityNew', data, {headers: authHeader()})
  }
  getProfiles(){
    return axios.get(API_URL + 'vehi/profiles', { headers: authHeader() });
  }
  postTimeLog(data){
    return axios.post(API_URL + 'vehi/timeLog', data, {headers: authHeader()})
  }
  getContacts(){
    return axios.get(API_URL + 'vehi/contacts', { headers: authHeader() });
  }
  postOffhire(data){
    return axios.post(API_URL + 'vehi/offHire', data, {headers: authHeader()})
  }
  postOnHire(data){
    return axios.post(API_URL + 'vehi/onHire', data, {headers: authHeader()})
  }
  getActivities(){
    return axios.get(API_URL + 'vehi/activities', { headers: authHeader() });
  }
  startCameraInterval(){
    return axios.get(API_URL + '/interval', { headers: authHeader() });
  }
 clearInterval(){
  return axios.get(API_URL + '/clearInterval', { headers: authHeader() })
 }
 getWarning(){
  return axios.get(API_URL + 'vehi/warning', { headers: authHeader() });
  }
  getStatusOn(){
    return axios.get(API_URL + 'vehi/statusOn', { headers: authHeader() });
  }
  postPayment(data){
    return axios.post(API_URL + 'vehi/postPayment', data, {headers: authHeader()})
  }
  getUsers(){
    return axios.get(API_URL + 'access/users', { headers: authHeader() });
  }
  getValidationApp(){
    return axios.get(API_URL + 'appointment/validationApp', { headers: authHeader() });
  }
  getAllAssets(){
    return axios.get(API_URL + 'appointment/allAssets', { headers: authHeader() });
  }
  deleteUser(id){
    return axios.post(API_URL + 'access/deleteUser', {id: id}, { headers: authHeader()})
  }
  updateUser(data){
    return axios.post(API_URL + 'access/updateUser', data, {headers: authHeader()})
  }
  getClientDrop(){
    return axios.get(API_URL + 'drop/clientDrop', { headers: authHeader() });
  }
  getOffTrack(){
    return axios.get(API_URL + 'vehi/offTrack', { headers: authHeader() });
  }
  updateOffTrack(data){
    return axios.post(API_URL + 'vehi/updateOffTrack', data, {headers: authHeader()})
  }
  getAuditTrack(){
    return axios.get(API_URL + 'vehi/auditTrack', { headers: authHeader() });
  }
  updateWaiting(data){
    return axios.post(API_URL + 'allocation/updateWaiting', data, {headers: authHeader()})
  }
  updateUnavailable(data){
    return axios.post(API_URL + 'allocation/updateUnavailable', data, {headers: authHeader()})
  }
  updateAvailable(data){
    return axios.post(API_URL + 'allocation/updateUnavailable', data, {headers: authHeader()})
  }
  updateAppointments(data){
    return axios.post(API_URL + 'appointments/updateAppointments', data, {headers: authHeader()})
  }
  updateAuditTrack(data){
    return axios.post(API_URL + 'audit/updateTrack', data, {headers: authHeader()})
  }
  updateAssetsApp(data){
    return axios.post(API_URL + 'appointments/updateAssets', data, {headers: authHeader()})
  }
  updateAssetsCheck(data){
    return axios.post(API_URL + 'check/updateAssets', data, {headers: authHeader()})
  }
  deleteWaiting(data) {
    return axios.post(API_URL + 'allocation/deleteWaiting',data, { headers: authHeader() });
  }
  validateVin(data){
    return axios.post(API_URL + 'vehicles/validateVin', data, { headers: authHeader() })
  }
  startTrip(data){
    return axios.post(API_URL + 'vehicles/startTrip', data, { headers: authHeader() })
  }
  endTrip(data){
    return axios.post(API_URL + 'vehicles/endTrip', data, { headers: authHeader() })
  }
  getTrips(){
    return axios.get(API_URL + 'vehicles/getTrips', { headers: authHeader() });
  }
}
//postReturn

export default new UserService();
