import React, { Component } from "react";

import UserService from "../services/user.service";

export default class RateCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    const myP1 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:"'Calibri',sans-serif"
    } 
    const myP2 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"8.0pt",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:'"Calibri",sans-serif',
      textAlign:"center"
    }
    const myTable = {
      borderCollapse: "collapse",
      border:"none"
    }
    const myTd1 = {
      width: "162.8pt",
      border: "1pt solid windowtext",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    const myP3 = {
      marginTop:"0cm",
      marginRight:"0cm",
      marginBottom:"0cm",
      marginLeft:"0cm",
      fontSize:"11.0pt",
      fontFamily:'"Calibri",sans-serif',
      lineHeight:"normal"
    }
    const myTd2 = {
      width: "177.15pt",
      borderTop: "1pt solid windowtext",
      borderRight: "1pt solid windowtext",
      borderBottom: "1pt solid windowtext",
      borderImage: "initial",
      borderLeft: "none",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    const myTd3 = {
      width: "182.85pt",
      borderTop: "1pt solid windowtext",
      borderRight: "1pt solid windowtext",
      borderBottom: "1pt solid windowtext",
      borderImage: "initial",
      borderLeft: "none",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    const myTd4 = {
      width: "162.8pt",
      borderRight: "1pt solid windowtext",
      borderBottom: "1pt solid windowtext",
      borderLeft: "1pt solid windowtext",
      borderImage: "initial",
      borderTop: "none",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    const myTd5 = {
      width: "177.15pt",
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "1pt solid windowtext",
      borderRight: "1pt solid windowtext",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    const myTd6 = {
      width: "182.85pt",
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "1pt solid windowtext",
      borderRight: "1pt solid windowtext",
      padding: "0cm 5.4pt",
      verticalAlign: "top"
    }
    return (
      <div className="containerf">
        <p style={myP1}>&nbsp;</p>
        <p style={myP2}><strong>Rates</strong></p>
        <p style={myP1}>The rates below are correct at the time of publishing on 1st April 2024 and reviewed monthly. Where applicable rates are exclusive of VAT and per event</p>
        <p style={myP1}>&nbsp;</p>
        <table style={myTable}>
            <tbody>
                <tr>
                    <td style={myTd1}>
                        <p style={myP3}>Description</p>
                    </td>
                    <td style={myTd2}>
                        <p style={myP3}>Charge</p>
                    </td>
                    <td style={myTd3}>
                        <p style={myP3}>Notes</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Ad-Blue</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;2.25</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Per Litre <sup>(Minimum 5litres)</sup></p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Administration Fee</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;35.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Debt Recovery</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;295.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Collection or Delivery</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;50.00 + &pound;1 per mile</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Each Way <sup>(Calculated from Basildon SS13 1LP)</sup></p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Excess Mileage Charge</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>0.20p per mile</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>After 2250 miles per month</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Failure to Report Incident</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;500.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Incident must be reported within 48hrs to a member of Tambor Ltd</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Fuel</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>Price at pump per litre</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Insurance Excess</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;1500.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Loss of Use</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;25.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Per day</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Telematics Safe Driving Bonus</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>Reduction in Vehicle hire by &pound;10.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Per week</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Telematics Poor Driving Penalty</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>Increase in Vehicle hire by &pound;10.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Per week</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Valet Fee &ndash; Standard</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;65.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Valet Fee &ndash; Deep Clean</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;130.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Vehicle Recovery</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;250.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Windscreen Repair</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>Cost of Repair</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td style={myTd4}>
                        <p style={myP3}>Unauthorized rental extension</p>
                    </td>
                    <td style={myTd5}>
                        <p style={myP3}>&pound;40.00</p>
                    </td>
                    <td style={myTd6}>
                        <p style={myP3}>Per day</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style={myP1}>&nbsp;</p>

      </div>
    );
  }
}
