import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import ReactPDF from '@react-pdf/renderer';

const PDFViewer = (props) => {
   console.log(props)
   const [render, setRender] =useState();
   useEffect(()=>{
      axios.post("https://node.tambor.uk:9000/getPdfCondition" ,{file: props.file},{responseType: 'arraybuffer'}).then(response => {      
      let blob = new Blob([response.data], { type: "application/pdf" }); 
      console.log(response)
      setRender(blob) 
      const fileURL =
            window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = props.file+".pdf";
        alink.click();
      }).catch(err => console.log(err))
   }, [props.link])
   console.log(render)
   let url
   if(render){
      url = URL.createObjectURL(render)
   }
  
 return (
 <div className='pdfviewcamera'>
    <iframe src={url} className='pdfviewcamera' />
    {/*<ReactPDF
      file={{
         data: render
      }}
   />*/}
   
 </div>
 );
};
export default PDFViewer;