import  {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import Slide from '@mui/material/Slide';
import UserService from '../../services/user.service';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SearchPay = (props) => {
 const [searchInput, setSearchInput] = useState("");
 let url_f = '/allocation';
let data_f = 'url_f)';
 let url_D = '/DVLA';
 let data_D = 'url_D)';
 let url_R = '/RTW';
 let data_R = 'url_R)';
 let url = '/applicants';
 let data =  props.table_data;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
 //let loading = false;
 
 useEffect(()=>{
    if(!data_f || !data_D || !data_R || !data){
        setLoading(true)
    }else{
        setLoading(false)
    }
 },[data_f, data_D, data_R, data])

 const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 90 },
    {
      field: 'TimeStamp',
      headerName: 'Timestamp',
      headerClassName: 'table-header',
      width: 250,
      editable: false,
    },
    {
      field: 'Reference',
      headerName: 'Reference',
      headerClassName: 'table-header',
      width: 250,
      editable: false,
    },
   {
      field: 'DA_Name',
      headerName: 'DA Name',
      headerClassName: 'table-header',
      width: 250,
      editable: false,
    },
    {
       field: 'Reason',
       headerName: 'Reason',
       headerClassName: 'table-header',
       width: 250,
       editable: false,
     },
     {
        field: 'Amount',
        headerName: 'Amount',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'Due',
        headerName: 'Due Date',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'Amount_Paid',
        headerName: 'Amount Paid',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'Date_Paid',
        headerName: 'Date Paid',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
        
      },
      {
        field: 'Status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 150,
        editable: false,
      },
      {
        field: 'Entered_By',
        headerName: 'Entered By',
        headerClassName: 'table-header',
        width: 150,
        editable: false,

      },
  ];

let displaiC = [];
if(data){
data?.map(dataRow => {

      
        displaiC.push(
                {id: dataRow.Line, TimeStamp: dataRow.TimeStamp?.split('T')[0]?.split('-')[2]+'-'+dataRow.TimeStamp?.split('T')[0]?.split('-')[1]+'-'+dataRow.TimeStamp?.split('T')[0]?.split('-')[0]+' '+dataRow.TimeStamp?.split('T')[1]?.split(':')[0]+':'+dataRow.TimeStamp?.split('T')[1]?.split(':')[1], Reference: dataRow.Reference , DA_Name: dataRow.DA_Name, Reason: dataRow.Reason, Amount: dataRow.Amount, Due: dataRow.Due?.split('-')[2]+'-'+dataRow.Due?.split('-')[1]+'-'+dataRow.Due?.split('-')[0], Amount_Paid: dataRow.Amount_Paid, Date_Paid:dataRow.Date_Paid ? dataRow.Date_Paid?.split('-')[2]+'-'+dataRow.Date_Paid?.split('-')[1]+'-'+dataRow.Date_Paid?.split('-')[0] : '', Status: dataRow.Status, Entered_By: dataRow.Entered_By}
        ) 
   
  })
}
const handleChange = (e) => {
  e.preventDefault();
  setSearchInput(document.getElementById('searchInput').value);
};
let findC = [];
if (searchInput.length > 0) {
    
    data.filter(dataRow => {
                if(dataRow.DA_Name?.toUpperCase().match(searchInput.toUpperCase())){
                    findC.push(
                        {id: dataRow.Line, TimeStamp: dataRow.TimeStamp, Reference: dataRow.Reference , DA_Name: dataRow.DA_Name, Reason: dataRow.Reason, Amount: dataRow.Amount, Due: dataRow.Due, Amount_Paid: dataRow.Amount_Paid, Date_Paid: dataRow.Date_Paid, Status: dataRow.Status, Entered_By: dataRow.Entered_By}
                        )
                }
});
}

const handleDelete = () =>{
  console.log(selected)
  UserService.deleteRows(selected)
  setTimeout(()=>{
    window.location.reload(true)
  }, 500)
}

return( 
<div>
    <div className='flex'>
        <input
        type="search"
        placeholder="Search here"
        //onChange={handleChange}
        //value={searchInput}
        id='searchInput'
        className='searchInput' 
        />
        <button type='button' className='button' onClick={handleChange}
        ><SearchIcon/></button>
        <h3 className='availableOffTitleC titleCorrection'>Payments Data</h3>
    </div>
{findC.length > 0 || searchInput ? 
    <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '0%' ,'& .MuiDataGrid-cell--editable': {
      bgcolor: (theme) =>
        theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    }, 
    '& .cold': {
      backgroundColor: '#b9d5ff91',
      color: '#1a3e72',
    },
    '& .hot': {
      backgroundColor: '#ff943975',
      color: '#1a3e72',
    }}}>
        <DataGrid
            //checkboxSelection
            onRowSelectionModelChange={params => setSelected(params)}
            rows={findC}
            columns={columns}
            initialState={{
                pagination: {
                paginationModel: {
                    pageSize: 100,
                },
                },
            }}
            getCellClassName={(params) => {
              if (params.field === 'city' || params.value == null) {
                return '';
              }
              return params.value == 'Unpaid' ? 'hot' : params.value == 'Paid' ? 'cold' :'';
            }}
            sx={{fontSize: '16px', fontFamily: ''}}
            pageSizeOptions={[100]}
            //onRowClick={params => handleRow(params)}
            slots={{
                loadingOverlay: LinearProgress,
            }}
            loading={loading}
            {...data}
        />
    </Box>: <Box sx={{ height: 1500, width: 'fit-content', paddingLeft: '1%' ,'& .MuiDataGrid-cell--editable': {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        }, 
        '& .cold': {
          backgroundColor: '#b9d5ff91',
          color: '#1a3e72',
        },
        '& .hot': {
          backgroundColor: '#ff943975',
          color: '#1a3e72',
        }}}>
        <DataGrid
        //checkboxSelection
        onRowSelectionModelChange={params => setSelected(params)}
        rows={displaiC}
        columns={columns}
        initialState={{
            pagination: {
            paginationModel: {
                pageSize: 100,
            },
            },
        }}
        pageSizeOptions={[15]}
       // onRowClick={params => handleRow(params)}
        slots={{
            loadingOverlay: LinearProgress,
        }}
        getCellClassName={(params) => {
          if (params.field === 'city' || params.value == null) {
            return '';
          }
          return params.value == 'Unpaid' ? 'hot' : params.value == 'Paid' ? 'cold' :'';
        }}
        sx={{fontSize: '16px'}}
        loading={loading}
        {...data}
        //{...data}
        />
    </Box>}
    
</div>
)

};

export default SearchPay;