import React from "react";
import { useState, useEffect } from "react";
import Select from 'react-select';
import UserService from "../../services/user.service";

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
    }), 
}

const ReturnToSupplier = () =>{
    const [ assets, setAssets ] = useState();
    const [ reg, setReg ] = useState();
    const [ vin, setVin ] = useState();
    const [ make, setMake ] = useState();
    const [ model, setModel ] = useState();
    const [ classC, setClassC ] = useState();
    const [ assignment, setAssignment ] = useState();
    const [ submit, setSubmit ] = useState(false);
    const [ statuses, setStatuses ] = useState([]);
    const [ status, setStatus ] = useState();
    
    let regOption = []

    useEffect(() =>{
        UserService.getReturn().then(response => setAssets(response.data)).catch(err => console.log(err))
        UserService.getStatusOn().then(response =>{
            response.data?.map(element =>{
                setStatuses(oldStatuses => [...oldStatuses, {value: element.heading, label: element.heading}])
            })
        })
    }, [])
    useEffect(()=>{console.log(status)},[status])
    for(let i = 0; i < assets?.length; i++){
        if(assets[i].assignment !== 'Inactive'){
            regOption.push({value: assets[i].reg, label: assets[i].reg})
        }
    }

    const handleReg = (e) =>{
        for(let i = 0; i < assets?.length; i++){
            if(e.value == assets[i].reg){
                setReg(assets[i].reg)
                setVin(assets[i].vin)
                setMake(assets[i].make)
                setModel(assets[i].model)
                setClassC(assets[i].class)
                setAssignment(`Current ${assets[i].assignment}`)
            }
        }
    }

    const handleReturn = () =>{
        let data = {reg: reg, status: 'Inactive', heading: 'Status Change - Inactive'}
        UserService.postReturn(data).then(response =>console.log(response)).catch(err => console.log(err))
        UserService.postActivityReturn(data).then(response => {
            setSubmit(true)
        }).catch(err => console.log(err))
    }
    return(
        
        <div>
            {submit == false?
            <div className="containerf containerfCorrection mobileContainerCorrection">
                    <header className="jumbotronl">
                        <h1 className="collectionTitle">Vehicle Return</h1>
                    </header> 
                    <div>
                    <h2 className="subttV">Vehicle Details</h2> 
                        <div className="flexAssets">
                            <div className="inlineLnI cor">
                                <label for='reg' className="eCorrection">Vehicle Registration <span className="req">(Require)</span></label>
                                <Select 
                                    options={regOption}
                                    styles={colourStylesS}
                                    onChange={e => handleReg(e)}
                                    //value={this.state.defaultValueClass}
                                /> 
                            </div>
                            {/*<div className="inlineLnI">
                                <label for='vin' className="eCorrection">Vin number<span className="req">(Require)</span></label>
                                <input type="text" name='vin' className="twoIn2" /*onChange={e => handleReg(e)} value={this.state.reg.toUpperCase()} />
                            </div>*/}
                        </div>  
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='vin' className="eCorrection">Vin number<span className="req">(Require)</span></label>
                                <input type="text" name='vin' className="twoIn2 correctionFieldsMobile3" /*onChange={e => handleReg(e)}*/ value={vin} />
                            </div>
                            <div className="inlineLnI">
                                <label for='make' className="eCorrection">Make<span className="req">(Require)</span></label>
                                <input type="text" name='make' className="twoIn2 correctionFieldsMobile3" /*onChange={e => handleReg(e)}*/ value={make} />
                            </div>
                        </div>
                        <div className="flexAssets">
                            <div className="inlineLnI">
                                <label for='model' className="eCorrection">Model<span className="req">(Require)</span></label>
                                <input type="text" name='model' className="twoIn2 correctionFieldsMobile3" /*onChange={e => handleReg(e)}*/ value={model} />
                            </div>
                            <div className="inlineLnI">
                                <label for='class' className="eCorrection">Class<span className="req">(Require)</span></label>
                                <input type="text" name='class' className="twoIn2 correctionFieldsMobile3" /*onChange={e => handleReg(e)}*/ value={classC} />
                            </div>
                        </div>
                        
                    </div>
                    <div className="allign1 correctionFieldsMobile">
                        <button className="buttonY" onClick={handleReturn}>Return to supplier</button>
                    </div>
            </div> :
            <h3 className="assetsOutcome">{reg} status updated to Returned to Supplier!</h3> 
            }       
        </div>
                        
    )
}

export default ReturnToSupplier;