import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import ReactPDF from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';

const LiveViewer = (props) => {
   const [render, setRender] = useState();
   const [count, setCount ] = useState()
   let interval;
   console.log(props)
   async function intervalCam (){
      await axios.post("https://node.tambor.uk:9000/getLiveFile"+props.camera ,{link: props.link},{responseType: 'arraybuffer'}).then(response => {
            
         let blob = new Blob([response.data], { type: "application/jpg" }); 
         console.log(response.status+'--------------------'+props.camera)
         console.log(response)
         setRender(blob) 
         }).catch(err => console.log(err))
   }
   useEffect(()=>{
      setInterval( intervalCam, 5000)
   }, [])
   let url
   if(render){
      url = URL.createObjectURL(render)
   }
   /* useEffect(()=>{
        clearInterval(interval)
    },[])*/
 return (
      <>
      {url ?  <img src={url} style={{minWidth: '500px', minHeight: '530px', marginLeft: '20px', paddingBottom: '0px'}} alt={<CircularProgress />} ></img> : 
      <div className='circular'>
         <CircularProgress color='success'/>
      </div>  
      } 
      </>
 
 );
};
export default LiveViewer;